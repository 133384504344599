import React, { useState, useEffect } from "react"

import { Link, withRouter } from "react-router-dom"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import Button from "@material-ui/core/Button"

import firebase, { auth } from "../firebase"
import { fieldsToForm, submitButton } from "../App/Layouts/FormRoute"
function SignIn(props) {
  const { classes } = props
  const [open, setOpen] = React.useState(false)

  const [email, setEmail] = useState("")
  const [email2, setEmail2] = useState("")
  const [password, setPassword] = useState("")
  const fields = [
    {
      title: "Email Address",
      value: email,
      id: "email",
      onChange: e => setEmail(e.target.value)
    },
    {
      title: "Password",
      value: password,
      id: "password",
      onChange: e => setPassword(e.target.value),
      type: "password"
    }
  ]
  let loginButton = { onClick: login, text: "Sign in" }
  let registerButton = {
    text: "Register",
    color: "secondary"
  }
  let sendPasswordLink = () => {
    auth
      .sendPasswordResetEmail(email2)
      .then(function() {
        setOpen(false)
        // Email sent.
      })
      .catch(function(error) {
        alert(error)
        // An error happened.
      })
  }
  useEffect(() => {
    if (auth.currentUser) {
      props.history.replace("/dashboard")
    }
  }, [auth.currentUser])

  return (
    <div style={{ width: "100%" }}>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Enter your email"}</DialogTitle>
        <div
          style={{
            padding: 20
          }}
        >
          Provide the email address associated with your account and we'll send
          you an email with instructions to reset your password.
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form
              className={classes.form}
              onSubmit={e => e.preventDefault() && false}
              style={{ width: "100%" }}
            >
              {fieldsToForm([
                {
                  title: "Email Address",
                  value: email2,
                  id: "email",
                  onChange: e => setEmail2(e.target.value)
                }
              ])}

              {submitButton({ onClick: sendPasswordLink, text: "Send" })}
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <form
        className={classes.form}
        onSubmit={e => e.preventDefault() && false}
        style={{ width: "100%" }}
      >
        {fieldsToForm(fields)}
        <a
          href="#"
          onClick={() => {
            setOpen(true)
          }}
        >
          Forgot password?
        </a>
        {submitButton(loginButton)}
      </form>

      <Link to="/dashboard/register"> {submitButton(registerButton)} </Link>
    </div>
  )

  async function login() {
    try {
      await firebase.login(email, password)
      props.history.replace("/dashboard")
    } catch (error) {
      alert(error.message)
    }
  }
}

export default withRouter(SignIn)

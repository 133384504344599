import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"
import { Card, CardActions, CardMedia, CardHeader } from "@material-ui/core"
import { Link, withRouter } from "react-router-dom"
import React from "react"
import { db } from "../firebase"
export default withRouter(props => {
  const deleteNetwork = async id => {
    await db
      .collection("networks")
      .doc(id)
      .delete()
  }

  return (
    <Card className={props.classes.card} style={{ width: 300, height: 300 }}>
      <CardHeader
        title={props.network.data().name}
        subheader={props.network.id}
        style={{
          position: "absolute",
          width: "100%",
          background: "rgba(1,1,1,0.6)",
          color: "white"
        }}
        subheaderTypographyProps={{
          style: {
            color: "rgba(235,235,235,1)"
          }
        }}
      />
      <CardMedia
        style={{ height: 250 }}
        title={props.network.data().name}
        image={
          props.network.data().hasImage
            ? `https://images.${process.env.REACT_APP_SERVER}/${props.network.id}-network.jpg`
            : `https://${process.env.REACT_APP_IMAGE_URL}/Podcast-Logo.jpg`
        }
      />

      <CardActions
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          height: "50px"
        }}
      >
        <div style={{ borderRadius: 5 }}>
          <Link
            to={`/dashboard/network/${props.network.id}/edit`}
            style={{
              color: "white",
              background: "#2196F3",
              padding: "10px"
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>

          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`https://network.${process.env.REACT_APP_SERVER}/${props.network.id}`}
            style={{
              color: "white",
              background: "#673AB7",
              padding: "10px"
            }}
          >
            <FontAwesomeIcon icon={faGlobe} />
          </a>

          <span
            onClick={() => deleteNetwork(props.network.id)}
            style={{
              color: "white",
              background: "#F44336",
              padding: "10px"
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </span>
        </div>
      </CardActions>
    </Card>
  )
})

import React, { useState, useEffect } from "react"
import { Button, Paper, Typography, Modal } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { StripeProvider, Elements } from "react-stripe-elements"

import firebase, { db, auth } from "../firebase"
import AddCard from "../Shared/AddCard"
import * as qs from "query-string"
import { fieldsToForm, submitButton } from "../App/Layouts/FormRoute"
import { useDocument } from "react-firebase-hooks/firestore"

const styles = {
  paper: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    padding: "20px",
    maxWidth: "800px"
  }
}
function Profile(props) {
  useEffect(() => {
    let mqu = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    if (mqu.state === auth.currentUser.uid && mqu.code)
      db.doc(`users/${auth.currentUser.uid}`).set(
        { stripeConnectToken: mqu.code },
        { merge: true }
      )

    db.doc(`users/${auth.currentUser.uid}`)
      .get()
      .then(userData => {
        setName(userData.data().displayName)
        setLoaded(true)
      })
  }, [])

  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [currentPassword, setCurrentPassword] = useState("")
  const [currentPasswordDelete, setCurrentPasswordDelete] = useState("")
  const [name, setName] = useState("")
  const [modalOpen, setmodalOpen] = useState(false)
  const [agreedToPayment, setAgreedToPayment] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const fields = [
    {
      title: "current Password",
      value: currentPassword,
      id: "currentpass",
      onChange: e => setCurrentPassword(e.target.value),
      type: "password"
    },
    {
      title: "change Password",
      value: password1,
      id: "password1",
      onChange: e => setPassword1(e.target.value),
      type: "password"
    },
    {
      title: "Retype",
      value: password2,
      id: "password2",
      type: "password",
      onChange: e => {
        setPassword2(e.target.value)
      }
    }
  ]

  const fieldsUpdate = [
    {
      title: "name",
      value: name,
      id: "displayName",
      onChange: e => setName(e.target.value)
    }
  ]

  const submitPassword = {
    onClick: async () => {
      if (password1 === password2) {
        try {
          await auth.signInWithEmailAndPassword(
            auth.currentUser.email,
            currentPassword
          )
          await auth.currentUser.updatePassword(password1)
          alert("Password changed")
        } catch (e) {
          alert(e.message)
        }
      } else {
        alert("passwords not the same!")
      }
    },
    text: "Change Password"
  }

  const updateUserInfo = {
    onClick: async () => {
      await firebase.userRef().update({ displayName: name })
      alert("Updated Info")
    },
    text: "Update",
    disabled: !loaded
  }

  const fieldsDelete = [
    {
      title: "current Password",
      value: currentPasswordDelete,
      id: "currentpassdelete",
      onChange: e => setCurrentPasswordDelete(e.target.value),
      type: "password"
    }
  ]

  const submitPasswordDelete = {
    onClick: async () => {
      try {
        await auth.signInWithEmailAndPassword(
          auth.currentUser.email,
          currentPasswordDelete
        )
        await auth.currentUser.delete()
        alert("Account Deleted")
        props.history.push("/")
      } catch (e) {
        alert(e.message)
      }
    },
    text: "Delete",
    disabled: !loaded
  }

  const { value: userData } = useDocument(firebase.userRef())

  const disconnectStripeConnect = () => {
    firebase.userRef().set({ stripeConnectToken: 0 }, { merge: true })
  }

  return (
    <div>
      <Paper style={styles.paper}>
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          Change Password
        </Typography>
        <form
          onSubmit={e => e.preventDefault() && false}
          style={{
            overflow: "hidden",
            flexGrow: 1
          }}
        >
          {fieldsToForm(fields)}
          {submitButton(submitPassword)}
        </form>
      </Paper>
      <Paper style={styles.paper} maxWidth="sm">
        <Typography
          variant="h6"
          component="h6"
          maxWidth="sm"
          style={{ textAlign: "center" }}
        >
          User Info
        </Typography>
        <form
          onSubmit={e => e.preventDefault() && false}
          style={{
            paddingLeft: "30px",
            paddingBottom: "80px",
            paddingRight: "30px",
            overflow: "hidden",
            flexGrow: 1
          }}
        >
          {fieldsToForm(fieldsUpdate)}
          {submitButton(updateUserInfo)}
        </form>
      </Paper>

      <div
        style={{
          backgroundColor: "#d9edf7",
          borderColor: "#bce8f1",
          color: "#31708f",
          margin: "auto",
          padding: "15px",
          marginBottom: "15px",
          border: "1px solid transparent",
          borderRadius: "4px",
          marginTop: "15px",
          maxWidth: "800px"
        }}
        class="alert alert-dismissible alert-info"
      >
        <strong>Stripe Security: </strong>
        Credit card details are stored securely by our payment processor,
        Stripe.
        <a
          rel="noopener noreferrer"
          href="https://stripe.com/docs/security/stripe"
          target="_blank"
        >
          Read about security at Stripe...
        </a>
      </div>

      <Paper style={styles.paper}>
        <Typography
          variant="h6"
          component="h6"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          Update Card Info
        </Typography>
        <StripeProvider
          apiKey={
            process.env.REACT_APP_SERVER == "audiostaqdev.xyz" ||
            process.env.REACT_APP_SERVER == "audiostaq.com"
              ? "pk_live_oUoKu6VQRECyOTPXb9qskNfb"
              : "pk_test_AtTLV8fkKIW8WnmS13G0aHTx"
          }
        >
          <Elements style={{ textAlign: "center", padding: "30px" }}>
            <AddCard
              noSubmit={true}
              classes={props.classes}
              onFinish={paymentMethod => {
                db.doc(`users/${auth.currentUser.uid}`)
                  .update({ stripeCardToken: paymentMethod.id })
                  .then(() => {
                    alert("Card Updated")
                  })
              }}
            >
              <div style={{ textAlign: "center", width: "100%" }}>
                <Button type="submit" variant="contained" color="primary">
                  Confirm
                </Button>
                <hr style={{ margin: "30px" }} />

                <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={modalOpen}
                  onClose={() => setmodalOpen(false)}
                >
                  <Paper
                    className={props.classes.card}
                    style={{
                      top: "35%",
                      left: "50%",
                      transform: "translate(-50%, -35%)",
                      position: "absolute",
                      padding: "30px",
                      height: "auto",
                      maxHeight: "90%",
                      overflow: "auto",
                      width: "700px",
                      outline: "none"
                    }}
                  >
                    <Typography variant="h6" component="h6">
                      Revenue Payout Terms and conditions
                    </Typography>
                    <div>
                      In order to receive earnings from AudioStaq, Inc. for all
                      of your advertising revenue, you must connect a Stripe
                      account. This can be an existing Stripe account, or a new
                      account. This is different than the payment information
                      may have entered above. As a receiver of earnings, you may
                      be subject to tax liabilities. Please consult a tax
                      professional to learn more. Once your account is setup in
                      Stripe, earnings transferred to it will be sent to your
                      bank account. AudioStaq has no record of your bank
                      information. All of your information is securely handled
                      by Stripe. Stripe Connect is a powerful tool that allows
                      merchants to transfer funds. As a receiver of advertising
                      revenue, you are considered a merchant. When you click the
                      button below, you will be taken to the Stripe website to
                      create your merchant account. This account will allow you
                      to receive funds from AudioStaq, Inc. and then transfer
                      the funds to your bank account or debit card. This
                      provides security and protection for you and for
                      AudioStaq, Inc., as your sensitive financial information
                      is secure in the Stripe system. After you have created
                      your Stripe account, you will be redirected back to this
                      page. AudioStaq, Inc. will payout all of your outstanding
                      revenue on the first of every month. As long as you have
                      earned at least $.01, you will have those funds
                      transferred to your Stripe account. AudioStaq, Inc. will
                      provide you with a full accounting for your potential tax
                      liabilities every year by January 31, for the previous tax
                      year. e.g. By January 31, 2019 you will receive a full
                      accounting for all revenue earnings for the calendar year
                      2018. You will also have full records available to you in
                      your Stripe account. All of the information about Stripe
                      Connect can be found at
                      <a href="https://stripe.com/docs/connect">
                        https://stripe.com/docs/connect
                      </a>
                      .
                    </div>

                    {!agreedToPayment ? (
                      <div style={{ paddingTop: 15 }}>
                        {" "}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setmodalOpen(false)
                            setAgreedToPayment(true)
                          }}
                        >
                          I Agree
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setmodalOpen(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Paper>
                </Modal>

                <Typography
                  variant="h6"
                  component="h6"
                  style={{ textAlign: "center", paddingBottom: 20 }}
                >
                  Connect AudioStaq with your Stripe Account
                </Typography>

                {userData &&
                userData.data().stripeConnectToken &&
                userData.data().stripeConnectToken !== 0 ? (
                  <p>
                    <Typography
                      variant="body1"
                      component="h6"
                      style={{ textAlign: "center", padding: "20px" }}
                    >
                      {" "}
                      Strip Connected!
                    </Typography>
                    <Button
                      variant="contained"
                      variant="contained"
                      color="secondary"
                      onClick={() => disconnectStripeConnect()}
                    >
                      Disconnect Account
                    </Button>
                  </p>
                ) : agreedToPayment ? (
                  <div>
                    <div style={{ paddingBottom: 10 }}>
                      Click the button below to be redirected to the Stripe
                      website to setup and connect your account.
                    </div>
                    <div style={{ paddingBottom: 10 }}>
                      <a
                        //    https://connect.stripe.com/express/oauth/authorize?client_id=ca_COlexbVkncFIqlnZuT6Tfs3GF0k3dpwg&redirect_uri=https%3A%2F%2Faudiostaq.com%2Fstripeconnect.html
                        href={`https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_DNIbh5Zw3Rr1KmiZAhvxhMRAYz4bDAxH&scope=read_write&state=${auth.currentUser.uid}&redirect_uri=https://${process.env.REACT_APP_DOMAIN}/dashboard/payments`}
                      >
                        <img
                          alt=""
                          src={`https://${process.env.REACT_APP_DOMAIN}/connectWithStripe.png`}
                          height="30px"
                        />
                      </a>
                    </div>
                    <div style={{ paddingBottom: 10 }}>
                      <Button
                        onClick={() => setmodalOpen(true)}
                        variant="contained"
                        color="primary"
                      >
                        View Terms and Services
                      </Button>
                    </div>
                    <div>
                      After you have completed the Stripe account setup and
                      connected it to AudioStaq, be sure to login to
                      <a href="https://stripe.com/">Stripe</a> and complete any
                      verification and setup steps for your new account.
                    </div>
                  </div>
                ) : (
                  <Button
                    onClick={() => setmodalOpen(true)}
                    variant="contained"
                    color="primary"
                    style={{ padding: 10 }}
                  >
                    Sign up For Revenue Payments
                  </Button>
                )}
              </div>
            </AddCard>
          </Elements>
        </StripeProvider>
      </Paper>

      <Paper style={styles.paper}>
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          Delete Account
        </Typography>
        <form
          onSubmit={e => e.preventDefault() && false}
          style={{
            overflow: "hidden",
            flexGrow: 1
          }}
        >
          {fieldsToForm(fieldsDelete)}
          {submitButton(submitPasswordDelete)}
        </form>
      </Paper>
    </div>
  )
}
export default withRouter(Profile)

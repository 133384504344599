import React, { useEffect } from "react"
import moment from "moment"
import { Button, Select, MenuItem, Typography, Paper } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"

import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import AudioPlayer from "../Components/audioPlayer"
function sec2time(timeInSeconds) {
  var pad = function(num, size) {
      return ("000" + num).slice(size * -1)
    },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60),
    milliseconds = time.slice(-3)

  return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2)
}
let getSortValue = slot => {
  if (slot.preRoll) return -1
  if (slot.midRoll) return slot.midRoll
  if (slot.postRoll) return Infinity
}

export default props => {
  const [addValue, setAddValue] = React.useState("preRoll")
  const [midRollSeconds, setmidRollSeconds] = React.useState(0)
  const [slots, setSlots] = React.useState([])
  const [previewFile, setPreviewFile] = React.useState()
  useEffect(() => {
    if (props.value) setSlots(props.value)
  }, [props.value])
  useEffect(() => {
    if (props.previewFile) setPreviewFile(props.previewFile)
  }, [props.previewFile])

  return (
    <div style={{ marginTop: 10 }}>
      <style>{".duration-input { height:100% !important}"}</style>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          component="h1"
          variant="h5"
          style={{
            textAlign: "center"
          }}
        >
          Ad Slots
        </Typography>
        <div>
          <Select
            value={addValue}
            onChange={event => setAddValue(event.target.value)}
            inputProps={{
              name: "rollType",
              id: "roll-type"
            }}
          >
            <MenuItem value={"preRoll"}>preRoll</MenuItem>
            {props.midRoll ? (
              <MenuItem value={"midRoll"}>midRoll</MenuItem>
            ) : (
              ""
            )}

            <MenuItem value={"postRoll"}>postRoll</MenuItem>
          </Select>
          {addValue != "midRoll" ? (
            <Button
              style={{ display: "inline" }}
              onClick={() => {
                let newslots = [
                  ...slots,
                  { [addValue]: addValue == "midRoll" ? midRollSeconds : true }
                ].sort((a, b) => {
                  if (getSortValue(a) > getSortValue(b)) return 1
                  else if (getSortValue(a) == getSortValue(b)) return 0
                  else return -1
                })
                setSlots(newslots)
                if (props.onChange) props.onChange(newslots)
              }}
            >
              Add
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
      {addValue == "midRoll" && (
        <Paper style={{ padding: 5, marginTop: 8 }}>
          <AudioPlayer
            src={previewFile}
            durationField
            onListen={time => {
              setmidRollSeconds(time)
            }}
            // other props here
          />
          <Button
            style={{ display: "inline" }}
            onClick={() => {
              let newslots = [
                ...slots,
                { [addValue]: addValue == "midRoll" ? midRollSeconds : true }
              ].sort((a, b) => {
                if (getSortValue(a) > getSortValue(b)) return 1
                else if (getSortValue(a) == getSortValue(b)) return 0
                else return -1
              })
              setSlots(newslots)
              if (props.onChange) props.onChange(newslots)
            }}
          >
            Add
          </Button>
        </Paper>
      )}

      <Table aria-label="simple table">
        <TableBody>
          {slots &&
            slots
              .sort((a, b) => {
                if (getSortValue(a) > getSortValue(b)) return 1
                else if (getSortValue(a) == getSortValue(b)) return 0
                else return -1
              })
              .map((slot, index) => (
                <TableRow key={Object.keys(slot)[0] + "_" + index}>
                  <TableCell component="th" scope="row">
                    {Object.keys(slot)[0]}
                    {Object.keys(slot)[0] == "midRoll" ? (
                      <span style={{ marginLeft: "7px" }}>
                        <i>{sec2time(slot.midRoll)}</i>
                      </span>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      style={{
                        marginBottom: 2
                      }}
                      onClick={() => {
                        let newSlots = [...slots]
                        /*    newSlots.sort((a, b) => {
                          if (getSortValue(a) > getSortValue(b)) return 1
                          else if (getSortValue(a) == getSortValue(b)) return 0
                          else return -1
                        })[index]*/
                        setSlots(newSlots)
                        if (props.onChange) props.onChange(newSlots)
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </div>
  )
}

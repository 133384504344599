import React, { useState, useEffect } from "react"
import firebase from "../../firebase"
import withStyles from "@material-ui/core/styles/withStyles"

import { Toolbar, Typography } from "@material-ui/core"

import { Route, Redirect } from "react-router-dom"
const dashboardRouteStyles = theme => ({
  main: {
    paddingLeft: 40,
    paddingTop: 10,
    paddingRight: 40
  },
  card: {
    minWidth: 150,
    height: 200
  },
  topBar: {
    width: "100%",
    padding: "20",
    backgroundColor: "#ffffff"
  },
  paper: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  formPaper: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  }
})

const isPrivate = props => !props.public && !firebase.getCurrentUsername()

export default withStyles(dashboardRouteStyles)(
  ({ component: Component, ...rest }) => {
    const [title, setTitle] = useState(rest.title)
    useEffect(() => setTitle(rest.title), [rest.title])
    if (isPrivate(rest)) return <Redirect to="/dashboard/login" />
    return (
      <div>
        <Toolbar className={rest.classes.topBar}>
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
        </Toolbar>
        <main className={rest.classes.main}>
          <Route
            {...rest}
            render={props => (
              <Component setTitle={setTitle} {...rest} {...props} />
            )}
          />
        </main>
      </div>
    )
  }
)

import React, { useState } from "react"

import { withRouter } from "react-router-dom"
import firebase from "../firebase"
import { fieldsToForm } from "../App/Layouts/FormRoute"
import { StripeProvider, Elements } from "react-stripe-elements"

import AddCard from "../Shared/AddCard"
function Register(props) {
  const { classes } = props
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const fields = [
    {
      title: "name",
      value: name,
      id: "name",
      onChange: e => setName(e.target.value)
    },
    {
      title: "Email Address",
      value: email,
      id: "email",
      onChange: e => setEmail(e.target.value)
    },
    {
      title: "Password",
      value: password,
      id: "password",
      onChange: e => setPassword(e.target.value),
      type: "password"
    }
  ]

  return (
    <StripeProvider
      apiKey={
        process.env.REACT_APP_SERVER == "audiostaqdev.xyz" ||
        process.env.REACT_APP_SERVER == "audiostaq.com"
          ? "pk_live_oUoKu6VQRECyOTPXb9qskNfb"
          : "pk_test_AtTLV8fkKIW8WnmS13G0aHTx"
      }
    >
      <Elements>
        <AddCard
          onFinish={paymentMethod => {
            onRegister(paymentMethod)
          }}
          classes={classes}
        >
          {fieldsToForm(fields)}
        </AddCard>
      </Elements>
    </StripeProvider>
  )

  async function onRegister(paymentMethod) {
    try {
      await firebase.register(name, email, password, paymentMethod)
      props.history.replace("/dashboard")
    } catch (error) {
      alert(error.message)
    }
  }
}

export default withRouter(Register)

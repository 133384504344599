import React from "react"

import { Typography, Paper, Grid } from "@material-ui/core"

const paperStyle = {
  marginTop: 4 * 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: `${4 * 2}px ${4 * 3}px ${4 * 3}px`
}
export default props => (
  <Grid item sm={props.fullWidth ? 12 : 6} xs={12}>
    <Paper style={paperStyle}>
      <Typography component="h1" variant="h5">
        {props.title}
      </Typography>
      <br />
      {props.children}
    </Paper>
  </Grid>
)

import React from "react"
import moment from "moment"
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow
} from "@material-ui/core"
import Widget from "../Components/widget"
export default props => (
  <Widget title={props.title} classes={props.classes} fullWidth={true}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">Publish Date</TableCell>
          <TableCell align="left">Episode Name</TableCell>
          <TableCell align="right">Downloads</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.topEpisodes.map((episode, index) => (
          <TableRow key={"tableI" + index}>
            <TableCell component="th" scope="row" width="9%">
              {moment(episode.pubDate).format("MM/DD/YYYY")}
            </TableCell>
            <TableCell align="left">{episode.name}</TableCell>
            <TableCell align="right">{episode.uniquePageviews}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Widget>
)

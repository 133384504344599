import {
  Paper,
  Button,
  CircularProgress,
  Modal,
  Typography
} from "@material-ui/core"
import React from "react"
export default props => {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.open}
      onClose={props.onClose}
    >
      <Paper
        style={{
          top: "35%",
          left: "50%",
          transform: "translate(-50%, -35%)",
          position: "absolute",
          padding: "40px",
          display: "fixed",
          height: "100",
          outline:"none"
        }}
      >
        <Typography
          variant="h6"
          component="h6"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          Are you sure you want to Delete?
        </Typography>
        <div
          style={{
            padding: 10,
            marginTop: 20,
            display: "flex",
            justifyContent: "space-around"
          }}
        >
          {!props.isDeleting ? (
            <React.Fragment>
              <Button
                variant="contained"
                color="secondary"
                onClick={props.delete}
              >
                Yes
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={props.onClose}
              >
                Cancel
              </Button>
            </React.Fragment>
          ) : (
            <CircularProgress />
          )}
        </div>
      </Paper>
    </Modal>
  )
}

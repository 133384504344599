import {
  Typography,
  Button,
  Paper,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import {
  faHeadphones,
  faCloudDownloadAlt,
  faRss,
  faUsers
} from "@fortawesome/free-solid-svg-icons"
import { functions } from "../firebase"
import { withRouter } from "react-router-dom"
import Widget from "../Components/widget"
import MiniWidget from "../Components/miniWidget"
import ListenersTableWidget from "../Components/ListenersTableWidget"
import ReactChartkick, {
  ColumnChart,
  PieChart,
  AreaChart
} from "react-chartkick"
import Chart from "chart.js"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { DateRangePicker } from "react-date-range"
import moment from "moment"
import { useCollection } from "react-firebase-hooks/firestore"
import { auth, db } from "../firebase"
ReactChartkick.addAdapter(Chart)
function Stats(props) {
  const { classes } = props
  const [analyticsData, setAnalyticsData] = useState()
  const [show, setShow] = useState("")
  const [episode, setEpisode] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [startDate, setStartDate] = useState(
    moment()
      .subtract("6", "days")
      .toDate()
  )
  const [endDate, setEndDate] = useState(new Date())
  const [episodes, setEpisodes] = useState()
  const { value: shows } = useCollection(
    db.collection("shows").where(`roles.${auth.currentUser.uid}`, "==", "owner")
  )

  const getAnalytics = functions.httpsCallable("getStats")
  let refresh = () => {
    setIsLoading(true)
    //  let anlc = JSON.parse(localStorage.getItem("defaultStats"))

    //if (anlc) {
    //  setAnalyticsData(anlc)
    //}

    getAnalytics({
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      episode,
      show
    }).then(data => {
      //  localStorage.setItem("defaultStats", JSON.stringify(data))
      setAnalyticsData(data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    props.setTitle(`Stats`)
    refresh()
  }, [])

  if (!analyticsData)
    return (
      <div id="loader2">
        <CircularProgress />
      </div>
    )

  return (
    <div>
      {isLoading ? (
        <div id="loader3">
          <CircularProgress />
        </div>
      ) : (
        ""
      )}
      <Grid container spacing={5} style={{ paddingTop: 10 }}>
        <Grid sm={12} md={8} style={{ padding: 16 }}>
          <DateRangePicker
            ranges={[
              {
                startDate: startDate,
                endDate: endDate,
                key: "selection"
              }
            ]}
            onChange={event => {
              if (event.selection.startDate)
                setStartDate(event.selection.startDate)
              if (event.selection.endDate) setEndDate(event.selection.endDate)
            }}
          />
        </Grid>
        <Grid
          sm={12}
          md={4}
          style={{
            padding: 16
          }}
        >
          <Paper style={{}}>
            <Grid
              container
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                paddingTop: 16
              }}
            >
              <Grid xs={6}>
                <Typography component="h3" variant="h5">
                  Show
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography component="h3" variant="h5">
                  Episode
                </Typography>
              </Grid>
              <Grid xs={6}>
                <FormControl className={classes.formControl}>
                  <Select
                    value={show}
                    onChange={event => {
                      setShow(event.target.value)
                      if (event.target.value) {
                        db.collection(`shows/${event.target.value}/episodes`)
                          .get()
                          .then(docs => setEpisodes(docs))
                      } else {
                        setEpisodes("")
                        setEpisode("")
                      }
                    }}
                    name="show"
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {shows &&
                      shows.docs.map(doc => (
                        <MenuItem value={doc.id} key={doc.id}>
                          {doc.data().name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl className={classes.formControl}>
                  <Select
                    value={episode}
                    onChange={event => setEpisode(event.target.value)}
                    name="episode"
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {episodes &&
                      episodes.docs.map(doc => (
                        <MenuItem value={doc.id}>{doc.data().name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} style={{ padding: 16 }}>
                <Button variant="contained" color="secondary" onClick={refresh}>
                  Refresh
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid
          container
          spacing={5}
          style={{
            filter: !analyticsData.data.downloads ? "blur(5px)" : ""
          }}
        >
          <MiniWidget
            gridSize={4}
            title={"Downloads"}
            icon={faCloudDownloadAlt}
            data={analyticsData.data.downloads}
          />
          <MiniWidget
            gridSize={4}
            title={"Listeners"}
            icon={faUsers}
            data={analyticsData.data.listeners}
          />
          <MiniWidget
            gridSize={4}
            title={"Embed Plays"}
            icon={faHeadphones}
            data={analyticsData.data.playerPlays}
          />
          <Widget title="Weekly Recap">
            <AreaChart
              data={analyticsData.data.listenersOverTime}
              dataset={{ label: "Downloads" }}
            />
          </Widget>
          <Widget
            title={
              "Monthly Recap" +
              (analyticsData.data && analyticsData.data.yearlyListeners
                ? " - " + Object.values(analyticsData.data.yearlyListeners)[11]
                : "")
            }
          >
            <ColumnChart
              data={analyticsData.data.yearlyListeners}
              dataset={{ label: "Downloads" }}
            />
          </Widget>
          <Widget class={classes} title="Top Countries">
            <PieChart
              data={(analyticsData.data.countries || []).map(item => [
                item.country,
                item.uniquePageviews
              ])}
            />
          </Widget>
          <Widget class={classes} title="Top Operating Systems">
            <PieChart
              data={(analyticsData.data.operatingSystems || []).map(item => [
                item.operatingSystem,
                item.uniquePageviews
              ])}
            />
          </Widget>
          <Widget class={classes} title="Top Cities">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">City</TableCell>
                  <TableCell align="right">Downloads</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(analyticsData.data.cities || [])
                  .sort((a, b) => b.uniquePageviews - a.uniquePageviews)
                  .slice(0, 10)
                  .map((city, index) => (
                    <TableRow key={"tableI" + index}>
                      <TableCell component="th" scope="row">
                        {city.city}
                      </TableCell>
                      <TableCell align="right">
                        {city.uniquePageviews}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Widget>
          <Widget class={classes} title="Top Apps">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Browser</TableCell>
                  <TableCell align="right">Downloads</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(analyticsData.data.browsers || [])
                  .sort((a, b) => b.uniquePageviews - a.uniquePageviews)
                  .slice(0, 10)
                  .map((browser, index) => (
                    <TableRow key={"tableI" + index}>
                      <TableCell component="th" scope="row">
                        {browser.browser}
                      </TableCell>
                      <TableCell align="right">
                        {browser.uniquePageviews}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Widget>
          <ListenersTableWidget
            title="Top Episodes"
            topEpisodes={analyticsData.data.topEpisodes || []}
          />{" "}
        </Grid>
      </Grid>
      {!analyticsData.data.downloads ? (
        <h1
          style={{
            position: "absolute",
            top: "590px",
            left: "50%",
            background: "rgba(255,255,255,0.9)",
            padding: "20px",
            borderRadius: "5px",
            transform: "translate(-50%, -50%)"
          }}
        >
          No Data Found
        </h1>
      ) : (
        ""
      )}
    </div>
  )
}

export default withRouter(Stats)

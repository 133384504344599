import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGlobe,
  faEdit,
  faRss,
  faTrash
} from "@fortawesome/free-solid-svg-icons"
import {
  Paper,
  Button,
  Card,
  CircularProgress,
  CardActions,
  CardMedia,
  Modal,
  Typography
} from "@material-ui/core"
import DeleteModal from "./deleteModal"
import { Link, withRouter } from "react-router-dom"
import React, { useState } from "react"

import { db } from "../firebase"

export default withRouter(props => {
  const deleteShow = async id => {
    setIsDeleting(true)
    let eps = await db
      .collection("shows")
      .doc(id)
      .collection("episodes")
      .get()
    for (let doc of eps.docs) await doc.ref.delete()

    await db
      .collection("shows")
      .doc(id)
      .delete()

    setIsDeleting(false)
    setDeleteModalOpen(false)
  }
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  return (
    <Card className={props.classes.card} style={{ width: 300, height: 300 }}>
      <Link to={`/dashboard/show/${props.show.id}`}>
        <CardMedia
          style={{ height: 250 }}
          title={props.show.data().name}
          image={
            props.show.data().hasImage
              ? `https://${process.env.REACT_APP_IMAGE_URL}/${props.show.id}.jpg`
              : `https://${process.env.REACT_APP_IMAGE_URL}/Podcast-Logo.jpg`
          }
        />
      </Link>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          height: "50px"
        }}
      >
        <div style={{ borderRadius: 5 }}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`https://feed.${process.env.REACT_APP_SERVER}/${props.show.id}`}
            style={{
              color: "white",
              background: "#673AB7",
              padding: "10px"
            }}
          >
            <FontAwesomeIcon icon={faRss} />
          </a>

          <span
            onClick={() => {
              setModalOpen(true)
            }}
            style={{
              color: "white",
              background: "#FF9800",
              padding: "10px"
            }}
          >
            <FontAwesomeIcon icon={faGlobe} />
          </span>

          <Link
            to={`/dashboard/show/${props.show.id}/edit`}
            style={{
              color: "white",
              background: "#2196F3",
              padding: "10px"
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          <span
            onClick={() => setDeleteModalOpen(true)}
            style={{
              color: "white",
              background: "#F44336",
              padding: "10px"
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </span>
        </div>
      </CardActions>
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        delete={() => deleteShow(props.show.id)}
        isDeleting={isDeleting}
      />

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          className={props.classes.card}
          style={{
            left: "25%",
            top: "25%",
            position: "fixed",
            padding: "40px",
            display: "fixed",
            height: "400px"
          }}
        >
          <Typography
            variant="h6"
            component="h6"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            Are you sure you want to Delete?
          </Typography>
          <div
            style={{
              padding: 10,
              marginTop: 20,
              display: "flex",
              justifyContent: "space-around"
            }}
          >
            {!isDeleting ? (
              <React.Fragment>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => deleteShow(props.show.id)}
                >
                  Yes
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setDeleteModalOpen(false)}
                >
                  Cancel
                </Button>
              </React.Fragment>
            ) : (
              <CircularProgress />
            )}
          </div>
        </Paper>
      </Modal>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Paper
          className={props.classes.card}
          style={{
            top: "35%",
            left: "50%",
            transform: "translate(-50%, -35%)",
            position: "absolute",
            padding: "40px",
            display: "fixed",
            height: "400px",
            outline: "none"
          }}
        >
          <Typography
            variant="h3"
            component="h3"
            style={{ textAlign: "center" }}
          >
            Info
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            Show Alias: <b>{props.show.id}</b>
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            iTunes RSS feed link: <br />
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://feed.${process.env.REACT_APP_SERVER}/${props.show.id}`}
            >
              {`https://feed.${process.env.REACT_APP_SERVER}/${props.show.id}`}
            </a>
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            AudioStaq Show Page Link: <br />
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://show.${process.env.REACT_APP_SERVER}/${props.show.id}`}
            >
              {`https://show.${process.env.REACT_APP_SERVER}/${props.show.id}`}
            </a>
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpen(false)}
            >
              Ok
            </Button>
          </Typography>
        </Paper>
      </Modal>
    </Card>
  )
})

import { db } from "../firebase"
import React, { useState } from "react"
import {
  Typography,
  Paper,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tabs,
  Tab,
  CircularProgress
} from "@material-ui/core"
import { MoreHoriz } from "@material-ui/icons/"
import { withRouter } from "react-router-dom"
import { CopyToClipboard } from "react-copy-to-clipboard"
import DeleteModal from "./deleteModal"
const options = ["Preview", "Embed", "Edit", "Delete"]

export default withRouter(props => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [value, setValue] = useState(0)
  const [modalOpenPreview, setModalOpenPreview] = useState(false)
  const [modalOpenEmbed, setModalOpenEmbed] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  function handleChange(event, newValue) {
    setValue(newValue)
  }
  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose(item) {
    if (item === "Edit") {
      props.history.push(
        `/dashboard/show/${props.show}/episode/${props.episode.id}/edit`
      )
    } else if (item === "Delete") {
      setDeleteModalOpen(true)
    } else if (item === "Preview") {
      setModalOpenPreview(true)
    } else if (item === "Embed") {
      setModalOpenEmbed(true)
    }

    setAnchorEl(null)
  }

  const deleteEpisode = async id => {
    setIsDeleting(true)
    await db.doc(`shows/${props.show}/episodes/${id}`).delete()
    setIsDeleting(false)
    setDeleteModalOpen(false)
  }
  const copyText = tab => {
    if (tab === 0)
      return `<iframe frameborder="no" preload="none" scrolling="no" width="100%" height="200px" src="https://player.${process.env.REACT_APP_SERVER}/${props.show}/${props.episode.id}"></iframe>`

    if (tab === 1)
      return `<iframe frameborder="no" preload="none" scrolling="no" width="100%" height="50px" src="https://player.${process.env.REACT_APP_SERVER}/${props.show}/${props.episode.id}"></iframe>`
    if (tab === 2) {
      return `<iframe frameBorder="no" preload="none" scrolling="no" width="100%" height="500px" src="https://playlist.${process.env.REACT_APP_SERVER}/${props.show}"></iframe>`
    }
  }
  return (
    <Paper
      className={props.classes.paper}
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row"
      }}
    >
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalOpenPreview}
        onClose={() => setModalOpenPreview(false)}
      >
        <Paper
          className={props.classes.card}
          style={{
            top: "35%",
            left: "50%",
            transform: "translate(-50%, -35%)",
            position: "absolute",
            padding: "40px",
            display: "fixed",
            height: "250px",
            outline: "none"
          }}
        >
          <Typography
            variant="h6"
            component="h6"
            style={{
              maxWidth: "600px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center",
              marginTop: "20px"
            }}
          >
            {props.episode.data().name}
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            <audio
              controls="controls"
              preload="none"
              id="episodePreview"
              src={`https://podcast.${process.env.REACT_APP_SERVER}/${props.show}/${props.episode.id}`}
            />
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpenPreview(false)}
            >
              Ok
            </Button>
          </Typography>
        </Paper>
      </Modal>
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        delete={() => deleteEpisode(props.episode.id)}
        isDeleting={isDeleting}
      />

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalOpenEmbed}
        onClose={() => setModalOpenEmbed(false)}
      >
        <Paper
          className={props.classes.card}
          style={{
            top: "35%",
            left: "50%",
            transform: "translate(-50%, -35%)",
            position: "absolute",
            padding: "30px",
            height: "auto",
            maxHeight: "90%",
            overflow: "auto",
            width: "700px",
            outline: "none"
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            centered
            style={{
              justifyContent: "space-evenly",
              textAlign: "center",

              backgroundColor: "rgb(250, 250, 250)"
            }}
          >
            <Tab label="Standard" />
            <Tab label="Slim" />
            <Tab label="Playlist" />
          </Tabs>
          <div
            style={{
              backgroundColor: "rgb(244, 244, 244)",
              padding: 30
            }}
          >
            {value === 0 || value === 1 ? (
              <iframe
                title="iframe1"
                frameBorder="no"
                preload="none"
                scrolling="no"
                width="100%"
                height={value === 0 ? "200" : "50"}
                src={`https://player.${process.env.REACT_APP_SERVER}/${props.show}/${props.episode.id}`}
              />
            ) : (
              <iframe
                title="iframe2"
                frameBorder="no"
                preload="none"
                scrolling="no"
                width="100%"
                height={"300"}
                src={`https://playlist.${process.env.REACT_APP_SERVER}/${props.show}`}
              />
            )}
          </div>
          <CopyToClipboard
            text={copyText(value)}
            onCopy={() => alert("copied!")}
          >
            <div
              style={{
                backgroundColor: "#f4f4f4",
                outline: "2px dashed #757575",
                outlineOffset: "-10px",
                padding: "40px",
                display: "flex",
                flexDirection: "row"
              }}
            >
              <Typography
                variant="body1"
                component="div"
                style={{ wordBreak: "break-word" }}
              >
                {copyText(value)}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{
                  padding: "40px",
                  marginLeft: "10px",
                  width: 40,
                  backgroundColor: "rgb(103, 58, 183)"
                }}
              >
                Copy
              </Button>
            </div>
          </CopyToClipboard>
        </Paper>
      </Modal>

      <div
        key={props.episode.id}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row"
        }}
      >
        {props.episode.data().uploadedFileUrl ? (
          <img
            alt=""
            src={
              !props.episode.data().hasImage && !props.showData.hasImage
                ? `https://${process.env.REACT_APP_IMAGE_URL}/Podcast-Logo.jpg`
                : !props.episode.data().hasImage
                ? `https://${process.env.REACT_APP_IMAGE_URL}/${props.show}.jpg`
                : `https://${process.env.REACT_APP_IMAGE_URL}/${props.show}/${props.episode.id}.jpg`
            }
            height="176"
            width="176"
            className="imga"
          />
        ) : (
          <div
            style={{
              width: 176,
              height: 176,

              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CircularProgress />
          </div>
        )}

        <div style={{ paddingLeft: 20 }}>
          <Typography
            variant="h6"
            component="h6"
            style={{
              maxWidth: "600px",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              MsWordBreak: "break-all",
              wordBreak: "break-word",
              MsHyphens: "auto",
              MozHyphens: "auto",
              WebkitHyphens: "auto",
              hyphens: "auto",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {props.episode.data().name} <br />
            <i> {!props.episode.data().uploadedFileUrl ? "processing" : ""} </i>
          </Typography>
          <div>
            <Typography
              variant="body1"
              component="p"
              style={{
                overflowWrap: "break-word",
                wordWrap: "break-word",
                MsWordBreak: "break-all",
                wordBreak: "break-word",
                MsHyphens: "auto",
                MozHyphens: "auto",
                WebkitHyphens: "auto",
                maxWidth: "600px",
                maxHeight: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {props.episode.data().description}
            </Typography>
          </div>
        </div>
      </div>

      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 400
          }
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={() => handleClose(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Paper>
  )
})

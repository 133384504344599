import React, { useEffect, useState } from "react"
import { auth } from "../firebase"
export default () => {
  let [isTop, setIsTop] = useState(true)
  let [menuOpen, setMenuOpen] = useState(false)
  let [images, setImages] = useState([
    "channelimage1",
    "channelimage2",
    "channelimage3",
    "channelimage4",
    "channelimage5"
  ])

  useEffect(() => {
    setImages(
      images.sort(() => {
        return 0.5 - Math.random()
      })
    )
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > 50) {
        setIsTop(false)
      } else {
        setIsTop(true)
      }
    })
  }, [])
  return (
    <div>
      {/* start Mixpanel */}
      {/* end Mixpanel */}
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content={"IE=edge"} />
      <meta name="viewport" content={"width=device-width, initial-scale=1"} />
      <meta name="description" content={true} />
      <meta name="author" content={true} />
      <meta
        property="og:url"
        content={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }`}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="AudioStaq - A Modern Podcast Platform"
      />
      <meta
        property="og:description"
        content="A modern podcasting platform that allows podcasters to host, distribute, and monetize content."
      />
      <meta
        property="og:image"
        content={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/images/demo-stats.jpg`}
      />
      <title>AudioStaq | Podcast Hosting Price </title>
      <link rel="icon" type="image/x-icon" href="images/microphone.ico" />
      {/* Bootstrap Core CSS */}
      <link
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/vendor/bootstrap/css/bootstrap.min.css`}
        rel="stylesheet"
      />
      {/* Custom Fonts */}
      <link
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}:${
          process.env.NODE_ENV == "development" ? "" : "s"
        }`} //fonts.googleapis.com/css?family=Lato"
        rel="stylesheet"
      />
      <link
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}:${
          process.env.NODE_ENV == "development" ? "" : "s"
        }`} //fonts.googleapis.com/css?family=Catamaran:100,200,300,400,500,600,700,800,900"
        rel="stylesheet"
      />
      <link
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}:${
          process.env.NODE_ENV == "development" ? "" : "s"
        }`} //fonts.googleapis.com/css?family=Muli"
        rel="stylesheet"
      />
      {/* Plugin CSS */}
      <link
        rel="stylesheet"
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/vendor/font-awesome/css/font-awesome.min.css?r=2`}
      />
      <link
        rel="stylesheet"
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/vendor/simple-line-icons/css/simple-line-icons.css?r=2`}
      />
      <link
        rel="stylesheet"
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/vendor/device-mockups/device-mockups.min.css?r=2`}
      />
      {/* Theme CSS */}
      <link
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/css/new-age.css`}
        rel="stylesheet"
      />
      <link
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/css/pricing.css`}
        rel="stylesheet"
      />
      {/* HTML5 Shim and Respond.js IE8 support of HTML5 elements and media queries */}
      {/* WARNING: Respond.js doesn't work if you view the page via file:// */}
      {/*[if lt IE 9]>


    <![endif]*/}
      {/* Facebook Pixel Code */}
      <noscript>
        &lt;img height="1" width="1" style="display:none" src="http$
        {process.env.NODE_ENV == "development" ? "" : "s"}
        ://www.facebook.com/tr?id=1289805254481523&amp;ev=PageView&amp;noscript=1"
        /&gt;
      </noscript>
      {/* DO NOT MODIFY */}
      {/* End Facebook Pixel Code */}
      {/* Google Recaptch */}
      <nav
        id="mainNav"
        className="navbar navbar-default navbar-fixed-top"
        style={{
          backgroundColor: isTop ? "rgb(102, 51, 153)" : "white",
          borderWidth: "initial"
        }}
      >
        {" "}
        <div className="container" id="pageTop">
          {/* Brand and toggle get grouped for better mobile display */}

          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <span className="sr-only">Toggle navigation</span> Menu{" "}
              <i className="fa fa-bars" />
            </button>

            <a href="/" className="logo">
              <img
                alt=""
                id="logo"
                src="images/logo.png"
                width="150px"
                style={{ marginTop: "10px" }}
              />
            </a>
          </div>
          {/* Collect the nav links, forms, and other content for toggling */}
          <div
            className={`navbar-collapse ${menuOpen ? "" : "collapse"} `}
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a
                  className="page-scroll"
                  href="/#download"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  Podcasters
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href="/#features"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  Advertisers
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href="/pricing"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  Pricing
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href={`${
                    process.env.NODE_ENV == "development" ? "" : "s"
                  }://blog.audiostaq.com/`}
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  News
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href="/contact"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  Contact
                </a>
              </li>
              <li className="noSelect">
                <a
                  className="noSelect"
                  href="/dashboard/login"
                  data-toggle="modal"
                  data-target="#at-login"
                  id="loginMenu"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  {auth && auth.currentUser ? "Dashboard" : "Login"}
                </a>
              </li>
            </ul>
          </div>
          {/* /.navbar-collapse */}
        </div>
        {/* /.container-fluid */}
      </nav>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="header-content">
                <div className="header-content-inner">
                  <h1 className="pricingH1">Start Podcasting Today.</h1>
                  <h3>
                    Get the tools you need to share your podcast and grow your
                    audience.
                  </h3>
                  {/*<span id="request-invite" class="btn btn-outline btn-xl page-scroll" data-toggle="modal" data-target="#at-request-invite"/>Request Invite</span>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section id="featured-shows" className="features">
        <div className="row">
          <div className="container-fluid">
            <div className="row text-center">
              <div className="col-md-3">
                <a
                  id="example1href"
                  href={`http${
                    process.env.NODE_ENV == "development" ? "" : "s"
                  }://${process.env.REACT_APP_DOMAIN}`}
                  target="_new"
                >
                  <img
                    alt=""
                    id="example1Img"
                    src={
                      !images[0]
                        ? `http${
                            process.env.NODE_ENV == "development" ? "" : "s"
                          }//${
                            process.env.REACT_APP_DOMAIN
                          }/images/channelimage.jpg`
                        : `http${
                            process.env.NODE_ENV == "development" ? "" : "s"
                          }://${process.env.REACT_APP_DOMAIN}/images/` +
                          images[0] +
                          ".jpg"
                    }
                    width="100%"
                    height="100%"
                  />
                </a>
              </div>
              <div className="col-md-3">
                <a
                  id="example2href"
                  href={`http${
                    process.env.NODE_ENV == "development" ? "" : "s"
                  }://${process.env.REACT_APP_DOMAIN}`}
                  target="_new"
                >
                  <img
                    alt=""
                    id="example2Img"
                    src={
                      !images[1]
                        ? `http${
                            process.env.NODE_ENV == "development" ? "" : "s"
                          }//${
                            process.env.REACT_APP_DOMAIN
                          }/images/channelimage.jpg`
                        : `http${
                            process.env.NODE_ENV == "development" ? "" : "s"
                          }://${process.env.REACT_APP_DOMAIN}/images/` +
                          images[1] +
                          ".jpg"
                    }
                    width="100%"
                    height="100%"
                  />
                </a>
              </div>
              <div className="col-md-3">
                <a
                  id="example3href"
                  href={`http${
                    process.env.NODE_ENV == "development" ? "" : "s"
                  }://${process.env.REACT_APP_DOMAIN}`}
                  target="_new"
                >
                  <img
                    alt=""
                    src={
                      !images[2]
                        ? `http${
                            process.env.NODE_ENV == "development" ? "" : "s"
                          }//${
                            process.env.REACT_APP_DOMAIN
                          }/images/channelimage.jpg`
                        : `http${
                            process.env.NODE_ENV == "development" ? "" : "s"
                          }://${process.env.REACT_APP_DOMAIN}/images/` +
                          images[2] +
                          ".jpg"
                    }
                    width="100%"
                    height="100%"
                  />
                </a>
              </div>
              <div className="col-md-3">
                <a
                  id="example4href"
                  href={`http${
                    process.env.NODE_ENV == "development" ? "" : "s"
                  }://${process.env.REACT_APP_DOMAIN}`}
                  target="_new"
                >
                  <img
                    alt=""
                    id="example4Img"
                    src={
                      !images[3]
                        ? `http${
                            process.env.NODE_ENV == "development" ? "" : "s"
                          }://${
                            process.env.REACT_APP_DOMAIN
                          }/channels/channelimage.jpg`
                        : `http${
                            process.env.NODE_ENV == "development" ? "" : "s"
                          }://${process.env.REACT_APP_DOMAIN}/images/` +
                          images[3] +
                          ".jpg"
                    }
                    width="100%"
                    height="100%"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="features"
        className="features"
        style={{ backgroundColor: "white" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-heading">
                <h2>Unlimited podcast storage and bandwidth</h2>
                <h3 style={{ color: "#fdcc52" }}> Start with a 7 Day trial.</h3>
                <p className="text-muted">
                  Pro plans are built for creators to never worry about
                  overages.
                </p>
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{ marginTop: "50px" }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xs-12 col-md-5 col-md-offset-1 col-lg-4 col-lg-offset-2">
                    <article className="pro-account">
                      <div className="feature-item">
                        <h3>Podcast Pro</h3>
                        <h4>
                          $20 <span>/ Month</span>
                        </h4>
                      </div>
                      <ul className="list-features-pro">
                        <li>Unlimited storage</li>
                        <li>Unlimited bandwidth</li>
                        <li>Import existing podcasts</li>
                        <li>Effortless Publishing and Scheduling</li>
                        <li>Dedicated show page</li>
                        <li>Distribution, your podcasts, everywhere</li>
                        <li>Advanced analytics</li>
                        <li>Embeddable Players</li>
                        <li>Custom Domain Support</li>
                        <li>
                          3rd Party Tracking Support
                        </li>
                      </ul>
                      <a
                        id="signup-pro"
                        href="/dashboard/register"
                        className="btn btn-block btn-outline btn-xl"
                        data-toggle="modal"
                        data-target="#at-signup-filling"
                      >
                        Get Pro
                      </a>
                    </article>
                  </div>
                  <div className="col-xs-12 col-md-5 col-lg-4">
                    <article className="network-account">
                      <div className="feature-item">
                        <h3>Podcast Network</h3>
                        <h4>Contact Us</h4>
                      </div>
                      <ul className="list-features-network">
                        <li>All-in-one-dashboard</li>
                        <li>Manage unlimited collaborators</li>
                        <li>Import existing podcasts</li>
                        <li>Access to Audiostaq sales team</li>
                        <li>Premium support</li>
                        <li>Network pages</li>
                        <li>Third party integrations</li>
                        <li>
                          Manage campaigns and track sales within our campaign
                          manager
                        </li>
                        <li>Programmatic ad platform access</li>
                        <li>
                          Dynamic Ad Insertion tool to optimize your revenue
                        </li>
                      </ul>
                      <a
                        href="/contact"
                        id="signup-network"
                        className="btn btn-block btn-warning btn-xl"
                      >
                        Get Demo &amp; Quote
                      </a>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="/contact"
        className="contact bg-primary"
        style={{ padding: 0 }}
      >
        <section id="contact" style={{ padding: 60 }}>
          <div className="container">
            <div className="row">
              <div
                className="about_our_company"
                style={{ marginBottom: "20px" }}
              >
                <h1 style={{ color: "#fff" }}>Contact Sales</h1>
                <div className="titleline-icon" />
                <p style={{ color: "#fff" }}>
                  Our team is waiting to work with you!{" "}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p style={{ color: "#fff" }}>
                  <strong>
                    <i className="fa fa-map-marker" /> Location
                  </strong>
                  <br />
                  Baltimore, MD
                </p>
              </div>
              <div className="col-md-4">
                <p style={{ color: "#fff" }}>
                  <strong>
                    <i className="fa fa-phone" /> Phone Number
                  </strong>
                  <br />
                  443.961.2453
                </p>
              </div>
              <div className="col-md-4">
                <p style={{ color: "#fff" }}>
                  <strong>
                    <i className="fa fa-envelope" /> Email
                  </strong>
                  <br />
                  <a href="/contact">Contact Us</a>
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div
                className="about_our_company"
                style={{ marginBottom: "20px" }}
              >
                <a href="/tos">Terms of Service</a>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="at-login-form">
        {/* MODAL LOGIN */}
        <div
          className="modal fade"
          id="at-login"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <input
                      type="username"
                      className="form-control-form "
                      id="uname"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control-form "
                      id="pwd"
                      placeholder="Password"
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-4" style={{ display: "none" }}>
                      <div className="checkbox">
                        <label>
                          <input type="checkbox" /> Remember me
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <p
                        className="frgt-pswd"
                        id="forgot-pwd"
                        style={{ cursor: "pointer" }}
                      >
                        Forgot Password
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <span id="loginReturnMsg">&nbsp;</span>
                    </div>
                  </div>
                  <input
                    type="button"
                    className="btn-lgin"
                    id="loginGo"
                    callback="dMain.loginReturn"
                    postfunc="login"
                    defaultValue="Login"
                  />
                  <div id="forgotDiv" style={{ display: "none" }}>
                    <div className="modal-body">
                      <p>
                        {" "}
                        Enter the email address associated with your account,
                        and we’ll email you a link to reset your password.{" "}
                      </p>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control-form "
                          id="resetEmail"
                          placeholder="Email"
                        />
                      </div>
                      <div id="message" />
                    </div>
                    <div />
                    <div className="modal-footer">
                      <input
                        type="button"
                        id="forgot-pwd-btn"
                        className="btn btn-primary btn-icon"
                        defaultValue="Reset"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL LOGIN ENDS */}
        {/* MODAL SIGNUP */}
        <div
          className="modal fade"
          id="at-signup"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <button
                  type="submit"
                  className="btn-lgin"
                  data-toggle="modal"
                  data-dismiss="modal"
                  data-target="#at-signup-filling"
                >
                  Signup with Email
                </button>{" "}
                <hr />
                <p>
                  By signing up, I agree to AudioStaq's
                  <a href="/tos" target="_blank">
                    Terms of Service
                  </a>
                  .{" "}
                </p>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col-md-6">
                    <p className="ta-l">Already a Member? </p>
                  </div>
                  <div className="col-md-4 col-md-offset-2">
                    <button
                      className="btn-gst"
                      data-toggle="modal"
                      data-dismiss="modal"
                      data-target="#at-login"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL SIGNUP FORM FILLING */}
        <div
          className="modal fade"
          id="at-signup-filling"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <br />
                  <div className="row">
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control-form "
                        id="signupName"
                        placeholder="First Name"
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control-form "
                        id="signupLName"
                        placeholder="Last Name"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-sm-12">
                      <input
                        type="email"
                        className="form-control-form "
                        id="signupEmail"
                        placeholder="Email"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-sm-12">
                      <input
                        type="password"
                        className="form-control-form "
                        id="signupPwd"
                        placeholder="Password"
                        autoComplete="off"
                      />
                      <br />
                      <span id="signupPwdMsg">&nbsp;</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control-form card-number"
                        id="signupCC"
                        placeholder="Card number"
                        autoComplete="off"
                      />
                      <input
                        type="text"
                        className="form-control-form card-exp-date"
                        id="signupCCExp"
                        placeholder="MM/YYYY"
                        autoComplete="off"
                      />
                      <input
                        type="text"
                        className="form-control-form card-cvv"
                        id="signupCCCVV"
                        placeholder="CVV"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        By signing up, I agree to Audiostaq’s{" "}
                        <a href="tos.html" target="_blank">
                          Terms of Service
                        </a>
                      </p>
                    </div>
                  </div>
                  <div id="signupMessage" />
                  <button
                    type="button"
                    className="btn-lgin"
                    id="signupPro"
                    callback="dMain.checkNameReturn"
                    postfunc="checkname"
                  >
                    Signup
                  </button>
                  <input
                    type="hidden"
                    id="signupCreate"
                    postfunc="saveUser"
                    callback="dMain.newUserReturn"
                  />
                  <input
                    type="hidden"
                    id="saveUserPayment"
                    postfunc="cu"
                    callback="dMain.saveUserPaymentReturn"
                  />
                </form>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col-md-6">
                    <p className="ta-l">Already a Member? </p>
                  </div>
                  <div className="col-md-4 col-md-offset-2">
                    <button
                      className="btn-gst"
                      data-toggle="modal"
                      data-dismiss="modal"
                      data-target="#at-login"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
              <div
                id="signupProcessing"
                style={{
                  display: "none",
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  margin: "0 auto",
                  top: "0px",
                  textAlign: "center",
                  left: 0,
                  backgroundColor: "gray"
                }}
              >
                <div
                  style={{
                    position: "fixed",
                    top: "30%",
                    textAlign: "center",
                    width: "100%"
                  }}
                >
                  <div>Creating user login...</div>
                  <br />
                  <br />
                  <br />
                  <img
                    alt=""
                    src="images/loading.gif"
                    style={{ height: "100px", width: "100px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL SIGNUP FORM FILLING */}
        {/* MODAL FORGOT PASSWORD */}
        <div
          className="modal fade"
          id="at-reset-pswd"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <p>
                    {" "}
                    Enter the email address associated with your account, and
                    we’ll email you a link to reset your password.{" "}
                  </p>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control-form "
                      id="resetEmail"
                      placeholder="Email"
                    />
                  </div>
                  <div id="message" />
                </form>
              </div>
              <div />
              <div className="modal-footer">
                <button
                  id="forgot-pwd-btn"
                  className="btn btn-primary btn-icon"
                  data-dismiss="modal"
                  aria-label="Submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL HELP */}
        <div
          className="modal fade"
          id="at-helping"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control-form "
                      id="exampleInputEmaillog"
                      placeholder="Enter Your Searches "
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <button
                    className="btn-gst"
                    data-toggle="modal"
                    data-dismiss="modal"
                  >
                    Send a reset Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="at-request-invite"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">x</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control-form"
                    id="requestEmail"
                    placeholder="Email"
                    postfunc="checkname"
                    callback="dMain.inviteCheckNameReturn"
                  />
                  <div id="requestInviteMsg">&nbsp;</div>
                  <br />
                  <fieldset id="group2">
                    <input
                      type="radio"
                      defaultValue="requestAPIMonetizationPlan"
                      name="group2"
                    />
                    &nbsp;&nbsp;
                    <label htmlFor="requestAPIMonetizationPlan">
                      API Monetization Plan
                    </label>
                    <div>
                      You have your own network, station, or large podcast, and
                      you want to take advantage of our dynamic advertising
                      technology. We charge a small fee to use our tech.
                    </div>
                    <br />
                    <input
                      type="radio"
                      id="requestHostedPartnerPlan"
                      defaultValue
                      name="group2"
                    />
                    &nbsp;&nbsp;
                    <label htmlFor="requestHostedPartnerPlan">
                      Hosted Partner Plan
                    </label>
                    <div>
                      You join AudioStaq and bring over all content. This means
                      we host you (you leave your current host), we provide
                      opportunities to monetize(via ads or sponsorships) and
                      market your show, you get to use our sales team,
                      publishing tools, and see your statistics. You remain in
                      full control of your content.
                    </div>
                    <br />
                    <input
                      type="radio"
                      defaultValue="requestPaidStandardHosting"
                      name="group2"
                    />
                    &nbsp;&nbsp;
                    <label htmlFor="requestPaidStandardHosting">
                      Paid Standard Hosting
                    </label>
                    <div>
                      You have your own network, station, or large podcast. We
                      charge a small fee and you get unlimited bandwidth and
                      hosting.
                    </div>
                    <br />
                    <input
                      type="radio"
                      defaultValue
                      name="requestNonhostedPartnerMonetizationPlan"
                    />
                    &nbsp;&nbsp;
                    <label htmlFor="requestNonhostedPartnerMonetizationPlan">
                      Non-hosted Partner Monetization Plan
                    </label>
                    <div>
                      We convert your RSS feed to ours while you stay with your
                      current host provider, we’ll make your show searchable and
                      listenable on our platform. We provide opportunities to
                      monetize(via ads or sponsorships) and market your show,
                      you get to use our sales team, publishing tools, and see
                      your statistics. You remain in full control of your
                      content.
                    </div>
                  </fieldset>
                </div>
                <div className="modal-footer">
                  <div className="row">
                    <button
                      className="btn-gst"
                      id="requestInvite"
                      postfunc="createinvite"
                      callback="dMain.createInviteReturn"
                    >
                      Request Invite
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="container">
          <p>© 2017 AudioStaq. All Rights Reserved.</p>
          <ul className="list-inline" />
        </div>
      </footer>
      {/* jQuery */}
      {/* Bootstrap Core JavaScript */}
      {/* Plugin JavaScript */}
      {/* Theme JavaScript */}
      <input
        type="hidden"
        callback="dMain.tokenReturn"
        postfunc="checkToken"
        id="checkToken"
      />
      <input
        type="hidden"
        callback="dMain.createReturn"
        postfunc="saveUser"
        id="createUser"
      />
      <input
        type="hidden"
        id="pwdReset"
        callback="dMain.pwdResetReturn"
        postfunc="pwdReset"
      />
      <input
        type="hidden"
        id="getShows"
        callback="dMain.getShowsReturn"
        postfunc="generic"
      />
      {/* Start of HubSpot Embed Code */}
      {/* End of HubSpot Embed Code */}
    </div>
  )
}

import React, { useState, useEffect, useRef } from "react"
import {
  MenuItem,
  Grid,
  Paper,
  Select,
  Input,
  Fab,
  Hidden,
  TextField,
  List,
  ListItemText,
  ListItemIcon,
  ListItem,
  Checkbox
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"

import { withRouter } from "react-router-dom"
import { fieldValidatorCore } from "react-validation-framework"
import clean from "../Shared/clean.js"
import { useCollection } from "react-firebase-hooks/firestore"
import moment from "moment"

import { auth, db, adUploads } from "../firebase"
import FileUploader from "../Components/uploader.js"
import { fieldsToForm, submitButton } from "../App/Layouts/FormRoute"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers"

import AudioPlayerDOM from "../Components/audioPlayer.js"

function CreateCampaign(props) {
  const theme = { spacing: { unit: 4 } }
  const classes = {
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: `40px`,
      paddingRight: `40px`,
      paddingTop: `10px`,
      paddingBottom: `10px`
    },
    avatar: {
      margin: theme.spacing.unit
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing.unit
    },
    submit: {
      marginTop: theme.spacing.unit * 3
    }
  }
  const [budget, setBudget] = useState("")
  const [impressionGoal, setImpressionGoal] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [name, setName] = useState("")

  const [adminShows, setAdminShows] = useState()
  const [ads, setAds] = useState([])

  const [createDisabled, setCreateDisabled] = useState(true)

  const [campaignId, setCampaignId] = useState(db.collection("_").doc().id)
  const [selectedShows, setSelectedShows] = useState([])
  const { value: shows } = useCollection(
    db.collection("shows").where(`roles.${auth.currentUser.uid}`, "==", "owner")
  )

  async function fetchData() {
    let campaignRef = db.doc(`campaigns/${props.match.params.campaignId}`)
    let campaignDoc = await campaignRef.get()
    let campaignData = campaignDoc.data()
    setAds(campaignData.ads)
    setName(campaignData.name)
    setBudget(campaignData.budget)
    setStartDate(campaignData.startDate.toDate())
    setEndDate(campaignData.endDate.toDate())
    setImpressionGoal(campaignData.impressionGoal)
    setCampaignId(props.match.params.campaignId)

    setSelectedShows(campaignData.shows)
    setCreateDisabled(false)
  }

  useEffect(() => {
    if (props.match.params.campaignId) {
      fetchData()
    }
    db.doc(`users/${auth.currentUser.uid}`)
      .get()
      .then(dbs => {
        if (dbs.data().admin) {
          db.collection("shows")
            .get()
            .then(qs => {
              setAdminShows(qs)
            })
        }
      })
  }, [])

  const fields = [
    {
      validators: [
        {
          validator: val => !val === false,
          errorPropValue: true,
          errorMessage: "Please enter a value"
        }
      ],
      required: true,
      title: "Name",
      value: name,
      onChange: e => setName(e.target.value),
      id: "name"
    },
    {
      title: "Budget",
      placeholder: "$500",
      value: budget,
      onChange: e => {
        setBudget(e.target.value)

        setImpressionGoal(e.target.value / (25 / 1000))
      },
      id: "budget"
    },
    {
      type: "number",
      title: "CPM",
      value: 25,
      disabled: true,
      //  onChange: e => setCpm(e.target.value),
      id: "cpm"
    },
    {
      value: impressionGoal,
      type: "number",
      title: "impression goal",
      placeholder: "20,000",
      onChange: e => setImpressionGoal(e.target.value),
      id: "impression",
      validators: [
        {
          validator: val => (val ? val >= 1 : true),
          errorPropValue: true,
          errorMessage: "has to be more then 1"
        }
      ]
    }
  ]
  const submitButtonParams = {
    onClick: addEpisode,
    disabled: createDisabled,
    text: props.match.params.campaignId ? "Update" : "Create"
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form
        className={classes.form}
        onSubmit={e => e.preventDefault() && false}
        style={{
          paddingLeft: "30px",
          paddingBottom: "80px",
          paddingRight: "30px",
          overflow: "hidden",
          flexGrow: 1
        }}
      >
        <Grid
          container
          spacing={5}
          style={{ paddingTop: 10, justify: "space-between" }}
        >
          <Grid item sm={6} xs={12}>
            <Paper style={classes.paper}>
              {fieldsToForm(fields)}
              <hr />
              <br />

              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                margin="normal"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                required
                id="startdatetime-local"
                label="Start Time"
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                value={startDate}
                onChange={e => {
                  setStartDate(e)
                }}
              />
              <br />
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                margin="normal"
                fullWidth
                label="End Time"
                InputLabelProps={{
                  shrink: true
                }}
                required
                value={endDate}
                onChange={e => {
                  setEndDate(e)
                }}
              />
              {[...Array(ads.map(ad => ad.file).filter(i => i).length + 1)].map(
                (x, i) => (
                  <div style={{ padding: 20, width: "100%" }}>
                    <FileUploader
                      style={{ paddingTop: 30, paddingBottom: 10 }}
                      title="Ad Audio"
                      accept="audio/*"
                      filename={campaignId + "___" + i + ".mp3"}
                      //show={show}
                      storageRef={adUploads.ref()}
                      onUploadStart={(file, task, filenameToUse) => {}}
                      onUploadSuccess={() => {
                        if (i == 0) {
                          setCreateDisabled(false)
                        }
                        let newAds = [...ads]

                        if (!newAds[i]) newAds[i] = { file: {} }
                        if (!newAds[i].slot) newAds[i].slot = { preRoll: true }

                        if (!newAds[i].updates) newAds[i].updates = 1
                        else newAds[i].updates++
                        newAds[i].file = campaignId + "___" + i + ".mp3"
                        setAds(newAds)
                      }}
                      onUploadError={p => alert(JSON.stringify(p))}
                    />

                    {ads[i] && ads[i].file ? (
                      <AudioPlayerDOM
                        updates={ads[i].updates}
                        src={
                          "https://storage.googleapis.com/audiostaq_transcoded_ads" +
                          (process.env.REACT_APP_SERVER == "audiostaqdev2.xyz"
                            ? "2"
                            : "") +
                          "/" +
                          ads[i].file +
                          "?" +
                          ads[i].updates
                        }
                        style={{ paddingTop: 10, width: "100%" }}
                      />
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        padding: 5
                      }}
                    >
                      <Select
                        value={
                          ads[i] && ads[i].slot
                            ? Object.keys(ads[i].slot)[0]
                            : "preRoll"
                        }
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginBottom: 15
                        }}
                        onChange={event => {
                          let newAds = [...ads]
                          if (!newAds[i]) newAds[i] = { slot: {} }
                          newAds[i].slot = { [event.target.value]: true }

                          setAds(newAds)
                        }}
                        inputProps={{
                          name: "rollType",
                          id: "roll-type"
                        }}
                      >
                        <MenuItem value={"preRoll"}>preRoll</MenuItem>
                        <MenuItem value={"midRoll"}>midRoll</MenuItem>
                        <MenuItem value={"postRoll"}>postRoll</MenuItem>
                      </Select>
                      {ads[i] && ads[i].file ? (
                        <Fab
                          color="secondary"
                          aria-label="add"
                          onClick={() => {
                            let newAds = [...ads]
                            newAds.splice(i, 1)
                            setAds(newAds)
                          }}
                        >
                          <DeleteIcon />
                        </Fab>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )
              )}

              <Hidden smUp>
                <Select
                  multiple
                  renderValue={() => "..."}
                  value={selectedShows}
                  onChange={event => setSelectedShows(event.target.value)}
                  input={<Input id="select-multiple" />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 500,
                        width: 250
                      }
                    }
                  }}
                >
                  {(adminShows || shows) &&
                    (adminShows || shows).docs.map(show => (
                      <MenuItem key={show.id} value={show.id}>
                        {show.data().name}
                      </MenuItem>
                    ))}
                </Select>
              </Hidden>
              {submitButton(submitButtonParams)}
            </Paper>
          </Grid>
          <Grid item sm={6} xs={12} style={{ width: "100%", marginTop: 4 * 8 }}>
            <Hidden xsDown>
              <Paper>
                <List dense component="div" role="list">
                  {(adminShows || shows) &&
                    (adminShows || shows).docs.map(value => {
                      const labelId = `transfer-list-item-${value}-label`
                      const handleToggle = value => () => {
                        const currentIndex = selectedShows.indexOf(value)
                        const newChecked = [...selectedShows]

                        if (currentIndex === -1) {
                          newChecked.push(value)
                        } else {
                          newChecked.splice(currentIndex, 1)
                        }

                        setSelectedShows(newChecked)
                      }
                      return (
                        <ListItem
                          key={value.id}
                          role="listitem"
                          button
                          onClick={handleToggle(value.id)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={selectedShows.indexOf(value.id) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={value.id}
                            primary={value.data().name}
                          />
                        </ListItem>
                      )
                    })}
                  <ListItem />
                </List>
              </Paper>
            </Hidden>
          </Grid>
        </Grid>
      </form>
    </MuiPickersUtilsProvider>
  )

  async function addEpisode() {
    try {
      let checkFieldTestResult = fieldValidatorCore.checkGroup("formGroup")

      if (!checkFieldTestResult.isValid) {
        window.scrollTo(
          0,
          document.getElementById(
            checkFieldTestResult.inValidComponents[0].props.id.offsetTop
          )
        )
        return
      }
      if (!ads[0] || !ads[0].file) {
        alert("Need atleast one ad")
        return
      }
      if (!startDate) {
        alert("No Start Date")
        return
      }
      if (!endDate) {
        alert("No End Date")
        return
      }

      if (moment(startDate) > moment(endDate)) {
        alert("Start Time after End Time")
        return
      }
      if (moment(endDate) < moment()) {
        alert("End Date before Today ")
        return
      }
      if (selectedShows.length < 1) {
        alert("No Shows Selected")
        return
      }
      await db.doc(`campaigns/${campaignId}`).set(
        clean({
          name,
          budget,
          impressionGoal: parseInt(impressionGoal),
          cpm: 25,
          startDate: startDate,
          endDate: endDate,
          enabled: true,
          shows: selectedShows,
          roles: { [auth.currentUser.uid]: "owner" },
          ads: ads
        }),
        { merge: true }
      )

      props.history.push(`/dashboard/campaigns`)
    } catch (error) {
      alert(error.message)
    }
  }
}

export default withRouter(CreateCampaign)

import React, { useState, useEffect, useRef } from "react"
import PrettoSlider from "./PrettoSlider.js"
import formatDur from "format-duration"
import TimeField from "react-simple-timefield"
import { TextField } from "@material-ui/core"
function sec2time(timeInSeconds) {
  var pad = function(num, size) {
      return ("000" + num).slice(size * -1)
    },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60),
    milliseconds = time.slice(-3)

  return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2)
}
export default props => {
  const audio = useRef(null)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [updates, setUpdates] = useState(0)
  const [isPlaying, setIsPlaying] = useState(props.isPlaying ? true : false)
  const [midRollSeconds, setmidRollSeconds] = React.useState(0)
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    audio.current.addEventListener("loadedmetadata", () => {
      let aduration = audio.current.duration
      setDuration(isFinite(aduration) ? aduration : 0)
    })

    audio.current.addEventListener("canplaythrough", function() {
      setLoaded(true)
    })

    audio.current.addEventListener("play", function() {
      setIsPlaying(!audio.current.paused)
    })

    audio.current.addEventListener("pause", function() {
      setIsPlaying(!audio.current.paused)
    })
    let tick = () => {
      if (!audio.current) return
      if (!audio.current.disableSlider) {
        setCurrentTime(audio.current.currentTime)
        if (props.onListen) props.onListen(audio.current.currentTime)
      }

      window.requestAnimationFrame(tick)
    }
    window.requestAnimationFrame(tick)
  }, [])
  useEffect(() => {
    if (audio.current) {
      audio.current.src = ""
      audio.current.src = props.src + "?" + updates
      audio.current.pause()
      audio.current.load()
      audio.current.pause()
      setUpdates(updates + 1)
    }
  }, [props.src])

  return (
    <div style={{ display: "flex", marginTop: 5, marginBottom: 5 }}>
      <div
        style={{ width: 50, marginRight: 10 }}
        onClick={() => {
          if (!isPlaying) {
            setIsPlaying(true)
            audio.current.play()
          } else {
            setIsPlaying(false)
            audio.current.pause()
          }
        }}
      >
        {!isPlaying ? (
          <svg
            viewBox="0 0 52 51"
            preserveAspectRatio="xMidYMin slice"
            width="100%"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isPlaying && !loaded ? (
              ""
            ) : (
              <circle
                cx="25.5709"
                cy="25.5"
                r="25.5"
                fill="url(#paint0_linear)"
              />
            )}
            <path
              d="M39.4138 25.0144L17.921 37.4233L17.921 12.6055L39.4138 25.0144Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="4.17844"
                y1="50.9999"
                x2="38.2456"
                y2="50.9999"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F67A34" />
                <stop offset="1" stopColor="#EB3E67" />
              </linearGradient>
            </defs>
          </svg>
        ) : (
          <svg
            viewBox="0 0 52 51"
            preserveAspectRatio="xMidYMin slice"
            width="100%"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isPlaying && !loaded ? (
              ""
            ) : (
              <circle
                cx="25.5"
                cy="25.5"
                r="23.5"
                stroke="url(#paint91_linear)"
                strokeWidth="4"
              />
            )}
            <path d="M19 14H24V37H19V14Z" fill="url(#paint1_linear)" />
            <path d="M27 14H32V37H27V14Z" fill="url(#paint2_linear)" />
            <defs>
              <linearGradient
                id="paint91_linear"
                x1="4.17844"
                y1="50.9999"
                x2="38.2456"
                y2="50.9999"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F67A34" />
                <stop offset="1" stopColor="#EB3E67" />
              </linearGradient>
              <linearGradient
                id="paint0_linear"
                x1="4.10752"
                y1="50.9999"
                x2="38.1747"
                y2="50.9999"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F67A34" />
                <stop offset="1" stopColor="#EB3E67" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="20.047"
                y1="37"
                x2="28.7308"
                y2="37"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F67A34" />
                <stop offset="1" stopColor="#EB3E67" />
              </linearGradient>
              <linearGradient
                id="paint2_linear"
                x1="20.047"
                y1="37"
                x2="28.7308"
                y2="37"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F67A34" />
                <stop offset="1" stopColor="#EB3E67" />
              </linearGradient>
            </defs>
          </svg>
        )}
      </div>
      <PrettoSlider
        valueLabelFormat={value => formatDur(value * 1000)}
        valueLabelDisplay="auto"
        aria-label="Pretto slider"
        max={duration}
        value={currentTime}
        style={{ marginTop: props.durationField ? 0 : 3, marginLeft: 3 }}
        step={0.1}
        onPointerDown={event => {
          event.target.setPointerCapture(event.pointerId)
        }}
        onChangeCommitted={(e, v) => {
          audio.current.currentTime = v
          audio.current.disableSlider = false
          setCurrentTime(v)
          if (props.onListen) props.onListen(v)
        }}
        onChange={(e, v) => {
          setCurrentTime(v)
          audio.current.disableSlider = true
          if (props.onListen) props.onListen(v)
        }}
      />
      {props.durationField ? (
        <TimeField
          showSeconds
          value={sec2time(currentTime)}
          onFocus={() => {
            audio.current.disableSlider = true
          }}
          onBlur={() => {
            audio.current.disableSlider = false
          }}
          onChange={(event, seconds) => {
            /*  setmidRollSeconds(

          )*/

            let ms = seconds.split(":").reduce((acc, time) => 60 * acc + +time)
            audio.current.currentTime = ms
            setCurrentTime(ms)
            if (props.onListen) props.onListen(ms)
            audio.current.disableSlider = false
          }}
          style={{
            width: 100,
            display: "inline-block",
            marginTop: 1,
            marginLeft: 10
          }}
          input={
            <TextField
              value={sec2time(currentTime * 1000, { leading: true })}
            />
          }
        />
      ) : (
        ""
      )}
      <audio
        ref={audio}
        style={{ width: "100%", marginTop: 10, outline: 0 }}
        src={props.src}
      ></audio>
    </div>
  )
}

import React, { useState, useEffect } from "react"
import "./styles.css"
import FrontPage from "../FrontPage"
import Login from "../Login"
import Register from "../Register"

import Dashboard from "../Dashboard"
import Shows from "../Shows"
import ShowForm from "../Shows/form"
import Pricing from "../Pricing"
import Contact from "../Contact"
import ShowImport from "../Shows/import"
import Show from "../Shows/show"
import EpisodeForm from "../Episodes/form"
import Episode from "../Episodes/episode"
import Stats from "../Stats"
import Distribute from "../Distribute"
import Profile from "../Profile"
import Network from "../Networks/network"
import NetworkForm from "../Networks/form"
import Networks from "../Networks"
import Campaigns from "../Campaigns"
import CampaignForm from "../Campaigns/form"
import CampaignStats from "../Campaigns/stats"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Your initialization

import Drift from "react-driftjs"
import {
  faTachometerAlt,
  faMicrophone,
  faChartPie,
  faProjectDiagram,
  faExchangeAlt,
  faBullhorn,
  faBell
} from "@fortawesome/free-solid-svg-icons"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import {
  CssBaseline,
  CircularProgress,
  IconButton,
  Drawer,
  Divider,
  ListItem,
  ListItemText,
  AppBar,
  ClickAwayListener,
  Toolbar,
  ListItemIcon,
  Popover,
  Button,
  Menu,
  MenuItem
} from "@material-ui/core"
import { Menu as MenuIcon } from "@material-ui/icons"
import { Router, Switch, Route, Link, useHistory } from "react-router-dom"

import firebase from "../firebase"
import AccountCircle from "@material-ui/icons/AccountCircle"
import FormRoute from "./Layouts/FormRoute"
import DashboardRoute from "./Layouts/DashboardRoute"
import { auth, db } from "../firebase"
import { SpringGrid } from "react-stonecutter"
let createHistory = require("history").createBrowserHistory

const history = createHistory()

history.listen(location => {
  window.gtag("config", "UA-136588130-1", {
    page_title: document.title,
    page_location: window.location.href,
    page_path: location.pathname
  })
})

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  }
})

export default function App(props) {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false)
  const [loggedIn, setIsLoggedIn] = useState(false)
  const [sideBarOpen, setsideBarOpen] = useState(false)
  const [hasCampaigns, setHasCampaigns] = useState(false)

  useEffect(() => {
    var config = {
      selector: "#hw-anchor", // CSS selector where to inject the badge
      account: "xYXkoy"
    }
    window.Headway.init(config)

    auth.onAuthStateChanged(user => {
      setIsLoggedIn(Boolean(user))
    })

    firebase.isInitialized().then(async val => {
      setFirebaseInitialized(val)

      if (auth.currentUser) {
        db.collection("shows")
          .where(`roles.${auth.currentUser.uid}`, "==", "owner")
          .onSnapshot(snap => {
            setShows(snap)
          })
        let dbs = await db.doc(`users/${auth.currentUser.uid}`).get()

        if (!dbs.exists) {
          setAnchorElAccount(null)
          window.localStorage.clear()
          auth.signOut()
        }
        setHasCampaigns(dbs.data().campaigns)
      }
    })
  }, [firebaseInitialized, loggedIn])

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElAccount, setAnchorElAccount] = React.useState(null)
  const [shows, setShows] = React.useState(null)

  const open = Boolean(anchorEl)
  const accountOpen = Boolean(anchorElAccount)
  const id = open ? "simple-popover" : undefined

  return firebaseInitialized !== false ||
    window.location.pathname === "/" ||
    window.location.pathname === "/pricing" ||
    window.location.pathname === "/contact" ? (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <div>
          <AppBar
            position="static"
            style={{
              visibility:
                window.location.pathname === "/" ||
                window.location.pathname === "/pricing" ||
                window.location.pathname === "/contact"
                  ? "hidden"
                  : ""
            }}
          >
            <Toolbar
              style={{
                backgroundColor: "#673AB7"
              }}
            >
              <IconButton
                color="inherit"
                aria-label="Menu"
                onClick={() => setsideBarOpen(true)}
                style={{ display: !loggedIn ? "none" : "flex" }}
              >
                <MenuIcon />
              </IconButton>
              <div
                style={{
                  flexGrow: 1
                }}
              >
                <img
                  alt=""
                  src={`https://${process.env.REACT_APP_DOMAIN}/logowhite.png`}
                  height={40}
                  style={{ marginLeft: 10 }}
                />
              </div>
              {shows && shows.docs && shows.docs.length > 0 && (
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={e => setAnchorEl(e.currentTarget)}
                  style={{
                    display: !auth.currentUser ? "none" : "flex",
                    backgroundColor: "rgba(255,255,255,.2)",
                    color: "white"
                  }}
                >
                  Upload
                </Button>
              )}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
              >
                <div style={{ padding: 20 }}>
                  <SpringGrid
                    component="ul"
                    columns={3}
                    columnWidth={150}
                    gutterWidth={5}
                    gutterHeight={5}
                    itemHeight={140}
                    springConfig={{ stiffness: 170, damping: 26 }}
                  >
                    {shows &&
                      shows.docs.map(doc => (
                        <li key={doc.id}>
                          <Link
                            to={`/dashboard/show/${doc.id}/episode/create`}
                            onClick={() => setAnchorEl(false)}
                          >
                            <img
                              alt=""
                              src={
                                doc.data().hasImage
                                  ? `https://${process.env.REACT_APP_IMAGE_URL}/${doc.id}.jpg`
                                  : `https://${process.env.REACT_APP_IMAGE_URL}/Podcast-Logo.jpg`
                              }
                              height={140}
                            />
                          </Link>
                        </li>
                      ))}
                  </SpringGrid>
                </div>
              </Popover>

              {loggedIn && (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      position: "relative",

                      marginLeft: "20px"
                    }}
                    id="hw-anchor"
                  >
                    <div style={{ position: "absolute" }}>
                      <FontAwesomeIcon
                        icon={faBell}
                        style={{
                          width: "20px",
                          height: "27px",
                          top: "10",
                          marginTop: "11px"
                        }}
                      />
                    </div>
                  </div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={e => setAnchorElAccount(e.currentTarget)}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElAccount}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={accountOpen}
                    onClose={() => setAnchorElAccount(null)}
                  >
                    <Link to={"/dashboard/profile"}>
                      <MenuItem onClick={() => setAnchorElAccount(null)}>
                        Profile
                      </MenuItem>
                    </Link>
                    <Link to={"/dashboard/login"}>
                      <MenuItem
                        onClick={() => {
                          setAnchorElAccount(null)
                          window.localStorage.clear()
                          auth.signOut()
                        }}
                      >
                        logout
                      </MenuItem>
                    </Link>
                  </Menu>
                </div>
              )}
            </Toolbar>
          </AppBar>

          <Drawer open={sideBarOpen} onClose={() => setsideBarOpen(false)}>
            <Toolbar>
              <img
                alt=""
                src={`http://${process.env.REACT_APP_DOMAIN}/logo.png`}
                width={150}
              />
            </Toolbar>
            <Divider />
            {[
              {
                name: "Home",
                to: "/dashboard/",
                icon: faTachometerAlt
              },
              {
                name: "Shows",
                to: "/dashboard/shows",
                icon: faMicrophone
              },
              {
                name: "Stats",
                to: "/dashboard/stats",
                icon: faChartPie
              },
              {
                name: "Networks",
                to: "/dashboard/networks",
                icon: faProjectDiagram
              },
              {
                name: "Distribute",
                to: "/dashboard/Distribute",
                icon: faExchangeAlt
              },
              hasCampaigns
                ? {
                    name: "Campaigns",
                    to: "/dashboard/Campaigns",
                    icon: faExchangeAlt
                  }
                : null
            ].map(
              (item, index) =>
                item && (
                  <Link
                    onClick={() => setsideBarOpen(false)}
                    to={item.to}
                    key={index}
                    style={{
                      color: "rgba(0, 0, 0, 0.54)"
                    }}
                  >
                    <ListItem button style={{ width: "100%" }}>
                      <ListItemIcon style={{ width: "3px", minWidth: "33px" }}>
                        <FontAwesomeIcon icon={item.icon} />
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItem>
                  </Link>
                )
            )}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://goo.gl/forms/SNNKBQDkmWXMeI6E3"
              style={{
                color: "rgba(0, 0, 0, 0.54)"
              }}
            >
              <ListItem button style={{ width: "100%" }}>
                <ListItemIcon style={{ width: "8px", minWidth: "33px" }}>
                  <FontAwesomeIcon icon={faBullhorn} />
                </ListItemIcon>
                <ListItemText primary={"Feedback"} />
              </ListItem>
            </a>
          </Drawer>

          <Switch>
            <Route exact path="/" component={FrontPage} key={"frontpage"} />
            <Route exact path="/pricing" component={Pricing} key={"pricing"} />
            <Route exact path="/contact" component={Contact} key={"contact"} />

            <FormRoute
              exact
              path="/dashboard/login"
              public
              component={Login}
              title="Sign In"
            />
            <DashboardRoute
              exact
              path="/dashboard/profile"
              component={Profile}
              title="Profile"
            />

            <FormRoute
              exact
              path="/dashboard/register"
              public
              component={Register}
              title="Register"
            />

            <DashboardRoute
              exact
              path="/dashboard"
              component={Dashboard}
              key={"dashboard"}
              title={"Dashboard"}
            />

            <DashboardRoute
              exact
              path="/dashboard/show/create"
              component={ShowForm}
              title={"New Show"}
            />
            <DashboardRoute
              exact
              path="/dashboard/campaign/create"
              component={CampaignForm}
              title={"New Campaign"}
            />

            <DashboardRoute
              exact
              path="/dashboard/show/:showName/edit"
              component={ShowForm}
              title={"Edit Show"}
            />
            <DashboardRoute
              exact
              path="/dashboard/distribute"
              component={Distribute}
              title={"Distribute"}
            />

            <DashboardRoute
              exact
              path="/dashboard/show/import"
              component={ShowImport}
              title={"Import Show"}
            />

            <DashboardRoute
              path="/dashboard/networks/create"
              component={NetworkForm}
              title={"Create Network"}
            />

            <DashboardRoute
              exact
              path="/dashboard/networks"
              component={Networks}
              title={"Networks"}
            />
            <DashboardRoute
              path="/dashboard/show/:showName/episode/create"
              component={EpisodeForm}
              title={"Create Episode"}
            />
            <DashboardRoute
              path="/dashboard/show/:showName/episode/:episodeName/edit"
              component={EpisodeForm}
              title={"Edit Episode"}
            />

            <DashboardRoute
              path="/dashboard/show/:showName/episode/:episode"
              component={Episode}
              title={"Episode"}
            />
            <DashboardRoute
              path="/dashboard/show/:name"
              component={Show}
              title={"Show"}
            />
            <DashboardRoute
              path="/dashboard/shows"
              component={Shows}
              title={"Shows"}
            />

            <DashboardRoute
              path="/dashboard/campaigns"
              component={Campaigns}
              title={"Campaigns"}
            />
            <DashboardRoute
              path="/dashboard/campaign/:campaignId/edit"
              component={CampaignForm}
              title={"Edit Campaign"}
            />
            <DashboardRoute
              exact
              path="/dashboard/campaign/:campaignId/stats"
              component={CampaignStats}
              title={"Campaign Stats"}
            />
            <DashboardRoute
              path="/dashboard/network/:networkName/edit"
              component={NetworkForm}
              title={"Edit Network"}
            />
            <DashboardRoute
              path="/dashboard/network/:name"
              component={Network}
              title={"Network"}
            />

            <DashboardRoute
              path="/dashboard/stats"
              component={Stats}
              title={"Stats"}
            />
            <DashboardRoute
              title="404"
              component={() => <div>We couldn’t find this page.</div>}
            />
          </Switch>
        </div>
      </Router>
      {<Drift appId="5fzdtzr2a68a" />}
    </MuiThemeProvider>
  ) : (
    <div id="loader">
      <CircularProgress />
    </div>
  )
}

import { fieldValue } from "../firebase.js"
const clean = e =>
  e instanceof Object
    ? Object.entries(e).reduce(
        (o, [k, v]) => {
          if (typeof v === "boolean" || v) o[k] = v
          else o[k] = fieldValue.delete()
          return o
        },
        e instanceof Array ? [] : {}
      )
    : e

export default clean

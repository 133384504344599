import React, { useState, useEffect, useRef } from "react"
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormControlLabel,
  Button,
  List,
  ListItemSecondaryAction,
  ListItem,
  Paper,
  Typography,
  Grid,
  Hidden,
  Dialog
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"

import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import PersonIcon from "@material-ui/icons/Person"
import Avatar from "@material-ui/core/Avatar"
import { fieldValidatorCore } from "react-validation-framework"
import validator from "validator"

import firebase, { db } from "../firebase"
import { useCollection } from "react-firebase-hooks/firestore"
import { withRouter } from "react-router-dom"
import { auth } from "../firebase"
import encodeurl from "encodeurl"
import { fieldsToForm, submitButton } from "../App/Layouts/FormRoute"
import podcastCategories from "podcast-categories"
import ImageUploader from "../Components/imageUploader.js"
import AdSlotEditor from "../Components/adSlotEditor"
import clean from "../Shared/clean.js"

function CreateShow(props) {
  const { classes } = props
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [showUrl, setShowUrl] = useState("")
  const [keywords, setKeywords] = useState("")
  const [explicit, setExplicit] = useState(false)
  const [categories, setCategories] = useState([])
  const [networks, setNetworks] = useState([])
  const [hasImage, setHasImage] = useState(false)
  const [showType, setShowType] = useState("episodic")
  const [email, setEmail] = useState("")
  const [author, setAuthor] = useState("")
  const [addEmail, setAddEmail] = useState("")
  const [featured, setFeatured] = useState(false)
  const [users, setUsers] = useState([])
  const [showPage, setShowPage] = useState("")
  const [trackingUrl, setTrackingUrl] = useState("")
  const [limitRss, setLimitRss] = useState(0)
  const [advancedSettings, setAdvancedSettings] = useState(false)
  const [croppedCanvas, setCroppedCanvas] = useState(false)
  const [googlePixel, setGooglePixel] = useState("")
  const [facebookPixel, setFacebookPixel] = useState("")
  const [defaultToCover, setDefaultToCover] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [copyright, setCopyright] = useState("")
  const imageUploaderRef = useRef(null)
  const [slots, setSlots] = useState([])
  const [open, setOpen] = React.useState(false)
  const [emailsOrignal, setUsersOringal] = useState([])
  const [redirect, setRedirect] = useState("")

  const handleClose = () => {
    setOpen(false)
  }
  const styles = {
    paper: {
      marginTop: 4 * 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: `40px`,
      paddingRight: `40px`,
      paddingTop: `10px`,
      paddingBottom: `10px`
    }
  }
  async function fetchData() {
    let showRef = db.doc(`shows/${props.match.params.showName}`)
    let showDoc = await showRef.get()
    let showData = showDoc.data()
    if (showData.description && showData.description.includes("b64_")) {
      showData.description = atob(showData.description.split("b64_")[1])
    }
    setIsEditing(true)
    setName(showData.name)
    setDescription(showData.description)
    setShowUrl(props.match.params.showName)
    setKeywords(showData.keywords ? showData.keywords.join(",") : [])
    setExplicit(showData.explicit)
    setNetworks(showData.networks || [])
    setUsers(showData.emails || [])

    setSlots(showData.slots)
    setFeatured(showData.featured)
    setEmail(showData.email)
    setRedirect(showData.redirect)
    setUsersOringal(showData.emails)
    setShowPage(showData.showPage)
    setTrackingUrl(showData.trackingUrl)
    setGooglePixel(showData.googlePixel)
    setFacebookPixel(showData.facebookPixel)
    setLimitRss(showData.limitRss)
    setAuthor(showData.author)
    setDefaultToCover(showData.defaultToCover)
    setCopyright(showData.copyright)

    if (showData.hasImage)
      setCroppedCanvas({
        toDataURL: () =>
          `https://${process.env.REACT_APP_IMAGE_URL}/` +
          props.match.params.showName +
          ".jpg",
        cantEdit: true
      })

    let categories = []
    for (let [name, item] of Object.entries(showData.categories)) {
      if (item.length === 0) categories.push(name)
      categories.push(
        ...Object.keys(item).map(subItem =>
          name !== subItem ? name + "/" + subItem : name
        )
      )
    }
    setCategories(categories)
  }
  useEffect(() => {
    if (props.match.params.showName) {
      fetchData()
    } else {
      db.collection("networks")
        .where("users", "array-contains", firebase.userRef().id)
        .get()
        .then(networkDocs => {
          setNetworks(networkDocs.docs.map(doc => doc.id))
          ;(async () => {
            for (var network of networkDocs.docs) {
              var doc = await db.doc(`networks/${network.id}`).get()
              setUsers([...new Set([...users, ...doc.data().emails])])
            }
          })()
          //console.log()
        })
    }
  }, [])

  const deleteEmail = email => {
    setUsers(users.filter(item => item !== email))
  }
  const addEmailButton = () => {
    setUsers([...users, addEmail])
    setAddEmail("")
  }
  const fields = [
    {
      title: "Name",
      value: name,
      id: "name",
      onChange: e => {
        setName(e.target.value)
        if (!props.match.params.showName)
          setShowUrl(
            encodeurl(e.target.value)
              .toLowerCase()
              .replace(/%20/g, "_")
              .replace(/[^a-z0-9_]/g, "")
              .substr(0, 15)
          )
      },
      validators: [
        {
          validator: val => !val === false,
          errorPropValue: true,
          errorMessage: "Please enter a value"
        }
      ],
      required: true
    },
    {
      title: "show ID",
      validators: [
        {
          validator: val => !val === false,
          errorPropValue: true,
          disabled: isEditing,
          errorMessage: "Please enter a value"
        },
        {
          validator: val => new RegExp(/^[a-z0-9_]*$/g).test(val),
          errorMessage: "only lowercase letters and underscores"
        },

        {
          validator: val => val.length > 5 || isEditing,
          errorPropValue: true,
          errorMessage: "Must be over 5 characters"
        },
        {
          validator: val => val.length < 30 || isEditing,
          errorPropValue: true,
          errorMessage: "Must be under 30 characters"
        }
      ],
      disabled: !isEditing,
      value: showUrl,
      id: "showUrl",
      onChange: e => setShowUrl(e.target.value),
      required: true
    },
    {
      title: "Description",
      value: description,
      id: "description",
      onChange: e => setDescription(e.target.value),
      multiline: true
    },
    {
      validators: [
        {
          validator: val => (val ? validator.isURL(val) : true),
          errorPropValue: true,
          errorMessage: "Not Valid Url"
        }
      ],
      title: "Show Page",
      value: showPage,
      id: "showPage",
      onChange: e => setShowPage(e.target.value)
    }
  ]

  const advancedFields = [
    {
      validators: [
        {
          validator: val => (val ? validator.isURL(val) : true),
          errorPropValue: true,
          errorMessage: "Not Valid Url"
        }
      ],
      title: "Tracking Url",
      value: trackingUrl,
      id: "trackingUrl",
      onChange: e => setTrackingUrl(e.target.value)
    },
    {
      title: "Google Pixel",
      value: googlePixel,
      id: "googlePixel",
      onChange: e => setGooglePixel(e.target.value)
    },
    {
      title: "Facebook Pixel",
      value: facebookPixel,
      id: "facebookPixel",
      onChange: e => setFacebookPixel(e.target.value)
    },

    {
      title: "Copyright",
      value: copyright,
      id: "setcopyright",
      onChange: e => setCopyright(e.target.value)
    },
    {
      title: "Redirect",
      value: redirect,
      id: "setredirect",
      onChange: e => setRedirect(e.target.value)
    },

    {
      validators: [
        {
          validator: val => (val ? val >= 0 : true),
          errorPropValue: true,
          errorMessage: "Can't be below 0"
        }
      ],
      title: "Limit RSS Feed",
      value: limitRss,
      type: "number",
      id: "Limit Rss",
      onChange: e => setLimitRss(e.target.value)
    }
  ]

  const categoryFields = [
    {
      title: "Keywords",
      value: keywords,
      id: "keywords",
      onChange: e => setKeywords(e.target.value)
    }
  ]

  const ownerFields = [
    {
      title: "Show Author",
      value: author,
      id: "author",
      onChange: e => setAuthor(e.target.value)
    },
    {
      validators: [
        {
          validator: val => !val || validator.isEmail(val),
          errorPropValue: true,
          errorMessage: "Must be Email"
        }
      ],
      title: "Show Email",
      value: email,
      id: "email",
      onChange: e => setEmail(e.target.value)
    },

    {
      validators: [
        {
          validator: val => !val || validator.isEmail(val),
          errorPropValue: true,
          errorMessage: "Must be Email"
        }
      ],
      title: "Add Email To Permissions",
      value: addEmail,
      id: "addemail",
      onChange: e => setAddEmail(e.target.value)
    }
  ]

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }

  const { value: networksCol } = useCollection(
    db
      .collection("networks")
      .where("users", "array-contains", firebase.userRef().id)
  )

  return (
    <form
      onSubmit={e => e.preventDefault() && false}
      style={{
        paddingLeft: "30px",
        paddingBottom: "80px",
        paddingRight: "30px",
        overflow: "hidden",
        flexGrow: 1
      }}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"send emails"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            These emails will be sent information on how to join your show :
            {users
              .filter(n => !(emailsOrignal || []).includes(n))
              .map(user => (
                <ListItem key={user}>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>

                  <ListItemText style={{ marginLeft: 10 }} primary={user} />
                </ListItem>
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => addShow()} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        container
        spacing={5}
        style={{ paddingTop: 10, justify: "space-between" }}
      >
        <Grid item sm={6} xs={12}>
          <Paper style={styles.paper}>
            {fieldsToForm(fields)}
            <Hidden smUp>
              <FormControl
                margin="normal"
                style={{
                  width: "100%",
                  paddingTop: "10px",
                  paddingBottom: "10px"
                }}
                key={"featured"}
              >
                <ImageUploader
                  ref={imageUploaderRef}
                  title="Show Image"
                  style={{ width: "100%" }}
                  hasUpload={hasImg => setHasImage(hasImg)}
                  uploadFinished={() => props.history.push(`/dashboard/shows/`)}
                  onSave={setCroppedCanvas}
                  croppedCanvas={croppedCanvas}
                  onDelete={() => setCroppedCanvas(null)}
                />
              </FormControl>
            </Hidden>

            <div
              style={{
                position: !advancedSettings ? "absolute" : "relative",

                top: advancedSettings ? "0" : "-9999px",
                left: advancedSettings ? 0 : "-9999px",
                width: "100%"
              }}
            >
              {fieldsToForm(advancedFields)}

              <FormControl
                margin="normal"
                style={{ width: "100%" }}
                key={"Featured"}
              >
                <FormControlLabel
                  value="Featured"
                  label="Featured"
                  control={
                    <Checkbox
                      checked={featured}
                      onChange={event => setFeatured(event.target.checked)}
                    />
                  }
                />
              </FormControl>

              <FormControl
                margin="normal"
                style={{ width: "100%" }}
                key={"defaultToCover"}
              >
                <FormControlLabel
                  value="Always Default To Cover"
                  label="Always Default To Cover"
                  control={
                    <Checkbox
                      checked={defaultToCover}
                      onChange={event =>
                        setDefaultToCover(event.target.checked)
                      }
                    />
                  }
                />
              </FormControl>
            </div>
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                setAdvancedSettings(!advancedSettings)
              }}
            >
              {!advancedSettings ? "Show" : "Hide"} advanced Fields
            </Button>
          </Paper>

          <Paper style={styles.paper}>
            <Typography component="h1" variant="h5">
              Ad Slots
            </Typography>
            <AdSlotEditor value={slots} onChange={value => setSlots(value)} />
          </Paper>
          <Paper style={styles.paper}>
            <Typography component="h1" variant="h5">
              Category Settings
            </Typography>
            <FormControl
              margin="normal"
              style={{ width: "100%" }}
              key={"explicit"}
            >
              <FormControlLabel
                value="Explicit"
                label="Explicit"
                control={
                  <Checkbox
                    checked={explicit}
                    onChange={event => setExplicit(event.target.checked)}
                  />
                }
              />
            </FormControl>
            {fieldsToForm(categoryFields)}

            <FormControl
              margin="normal"
              fullWidth
              key={"show Type"}
              variant="outlined"
            >
              <Select
                variant="outlined"
                onChange={event => {
                  setShowType(event.target.value)
                }}
                value={showType}
                input={
                  <OutlinedInput name="show Type" id="outlined-show-type" />
                }
              >
                <MenuItem value={"episodic"}>Episodic</MenuItem>
                <MenuItem value={"serial"}>Serial</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              margin="normal"
              fullWidth
              key={"cats"}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-categories">Categories</InputLabel>
              <Select
                multiple
                value={categories}
                onChange={event => {
                  setCategories(event.target.value)
                }}
                renderValue={selected => selected.join(", ")}
                MenuProps={MenuProps}
                input={
                  <OutlinedInput
                    labelWidth={76}
                    name="categories"
                    id="outlined-categories"
                  />
                }
              >
                {Object.entries(podcastCategories).reduce(
                  (allobjects, [key, category]) =>
                    allobjects.concat(
                      <MenuItem key={key} value={key}>
                        <Checkbox checked={categories.indexOf(key) > -1} />
                        <ListItemText primary={key} />
                      </MenuItem>,
                      ...Object.keys(category).map(name => (
                        <MenuItem key={name} value={key + "/" + name}>
                          <Checkbox
                            checked={categories.indexOf(key + "/" + name) > -1}
                          />
                          <ListItemText secondary={name} />
                        </MenuItem>
                      ))
                    ),
                  []
                )}
              </Select>
            </FormControl>
          </Paper>

          <Paper style={styles.paper}>
            <Typography component="h1" variant="h5">
              Owner Settings
            </Typography>
            {networksCol && (
              <FormControl
                margin="normal"
                fullWidth
                key={"network"}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="select-multiple-checkbox"
                  variant="outlined"
                >
                  Networks
                </InputLabel>

                <Select
                  input={
                    <OutlinedInput
                      name="show Type"
                      labelWidth={71}
                      id="outlined-network"
                    />
                  }
                  multiple
                  value={networks}
                  onChange={async event => {
                    setNetworks(event.target.value)
                    for (var network of event.target.value) {
                      var doc = await db.doc(`networks/${network}`).get()
                      setUsers([...new Set([...users, ...doc.data().emails])])
                    }
                  }}
                  renderValue={() =>
                    networksCol.docs
                      .map(network => network.data().name)
                      .join(",")
                  }
                  MenuProps={MenuProps}
                >
                  {networksCol &&
                    networksCol.docs.map(network => (
                      <MenuItem key={network.id} value={network.id}>
                        <Checkbox checked={networks.indexOf(network.id) > -1} />
                        <ListItemText primary={network.data().name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {fieldsToForm(ownerFields)}

            <List>
              {users.map(user => (
                <ListItem key={user}>
                  <ListItemText primary={user} />
                  <ListItemSecondaryAction>
                    <IconButton aria-label="Delete">
                      <DeleteIcon onClick={() => deleteEmail(user)} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={addEmailButton}
            >
              Add Email
            </Button>
          </Paper>
          <Paper style={styles.paper}>
            {submitButton({
              onClick: () => {
                users.filter(n => !(emailsOrignal || []).includes(n)).length > 0
                  ? setOpen(true)
                  : addShow()
              },
              text: props.match.params.showName ? "Update" : "Create"
            })}
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} style={{ width: "100%", marginTop: 4 * 8 }}>
          <Hidden xsDown>
            <ImageUploader
              ref={imageUploaderRef}
              hasUpload={hasImg => setHasImage(hasImg)}
              uploadFinished={() => props.history.push(`/dashboard/shows/`)}
              title="Show Image"
              onSave={setCroppedCanvas}
              croppedCanvas={croppedCanvas}
              onDelete={() => setCroppedCanvas(null)}
            />
          </Hidden>
        </Grid>
      </Grid>
    </form>
  )

  async function addShow() {
    try {
      if (!props.match.params.showName) {
      }

      let checkFieldTestResult = fieldValidatorCore.checkGroup("formGroup")

      if (!checkFieldTestResult.isValid) {
        window.scrollTo(
          0,
          document.getElementById(
            checkFieldTestResult.inValidComponents[0].props.id.offsetTop
          )
        )
        return
        //  window.scrollTo(0, domc.offsetTop)
      }
      var newItems = {
        name,
        hasImage: Boolean(croppedCanvas),
        showType,
        email,
        author,
        featured,
        googlePixel,
        facebookPixel,
        copyright,
        redirect,
        emails: users,
        roles: { [auth.currentUser.uid]: "owner" },
        categories: categories.reduce((sum, item) => {
          let itemSplit = item.split("/")
          if (!sum[itemSplit[0]]) sum[itemSplit[0]] = {}
          if (itemSplit[1]) sum[itemSplit[0]][itemSplit[1]] = true
          if (!itemSplit[1]) sum[itemSplit[0]][itemSplit[0]] = true

          return sum
        }, {}),
        description,
        keywords: keywords.length > 0 ? keywords.split(",") : null,
        explicit,
        networks,
        slots,
        limitRss,
        trackingUrl,
        showPage,
        defaultToCover,
        creator: auth.currentUser.uid
      }
      if (!props.match.params.showName) {
        newItems["uid"] = db.collection("_").doc().id
      }

      await db
        .doc(`shows/${props.match.params.showName || showUrl}`)
        .set(clean(newItems), { merge: true })

      if (hasImage)
        imageUploaderRef.current.startUpload(
          props.match.params.showName || showUrl
        )
      else props.history.push(`/dashboard/shows/`)
    } catch (error) {
      alert(error.message)
    }
  }
}

export default withRouter(CreateShow)

import { Button } from "@material-ui/core"

import React, { useEffect, useState } from "react"

import { withRouter, Link } from "react-router-dom"
import { auth, db } from "../firebase"

import { useCollection } from "react-firebase-hooks/firestore"
import { CSSGrid, makeResponsive, measureItems } from "react-stonecutter"
import ShowCard from "../Components/showCard.js"

const Grid = makeResponsive(measureItems(CSSGrid), {
  maxWidth: 1920,
  minPadding: 100
})

function Shows(props) {
  const { classes } = props
  const [createEnabled, setCreateEnabled] = useState(false)

  const { value: shows } = useCollection(
    db.collection("shows").where(`roles.${auth.currentUser.uid}`, "==", "owner")
  )
  useEffect(() => {
    db.doc(`users/${auth.currentUser.uid}`)
      .get()
      .then(userData => {
        setCreateEnabled(
          (userData.data().usertype || 1) > (userData.data().showCount || 0)
        )
      })
  }, [])
  return (
    <div>
      <div>
        {createEnabled && (
          <div
            style={{
              marginTop: 20,
              textAlign: "center"
            }}
          >
            <Link to={"/dashboard/show/create"}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "rgb(103, 58, 183)",
                  height: 50,
                  width: 350,
                  fontSize: 20,
                  margin: "0 10px 30px 0"
                }}
              >
                {" "}
                Create Show{" "}
              </Button>{" "}
            </Link>
            <Link to={"/dashboard/show/import"}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "rgb(103, 58, 183)",
                  height: 50,
                  width: 350,
                  fontSize: 20,
                  margin: "0 10px 30px 0"
                }}
              >
                {" "}
                Import Show{" "}
              </Button>{" "}
            </Link>
          </div>
        )}
        {shows && (
          <Grid
            component="ul"
            columns={5}
            columnWidth={300}
            gutterWidth={20}
            gutterHeight={20}
            itemHeight={300}
            springConfig={{ stiffness: 170, damping: 26 }}
            style={{ margin: "0 auto" }}
          >
            {shows.docs.map(doc => (
              <li key={doc.id}>
                <ShowCard
                  show={doc}
                  classes={classes}
                  history={props.history}
                />
              </li>
            ))}
          </Grid>
        )}
      </div>
    </div>
  )
}

export default withRouter(Shows)

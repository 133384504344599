import React, { useEffect, useState } from "react"
import { auth } from "../firebase"
export default () => {
  let [isTop, setIsTop] = useState(true)
  let [menuOpen, setMenuOpen] = useState(false)
  let [images, setImages] = useState([
    "jmeINhCeTgzXpuLev07v",
    "5YsIFDPuu4AEj21ngvhe",
    "6C5qX1bN7vaD740z4vfW",
    "BvCYMlaPaHlmz1l0Ajts",
    "JpDHCUkiyqh6QOriMuih"
  ])

  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "http" +
      (process.env.NODE_ENV == "development" ? "" : "s") +
      "://js.hsforms.net/forms/v2.js"
    document.body.appendChild(script)

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "3843959",
          formId: "52303878-bbb8-4739-a46c-9f08e70e4565",
          target: "#hubspotForm"
        })
      }
    })

    setImages(
      images.sort(() => {
        return 0.5 - Math.random()
      })
    )
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > 50) {
        setIsTop(false)
      } else {
        setIsTop(true)
      }
    })
  }, [])
  return (
    <div>
      {/* start Mixpanel */}
      {/* end Mixpanel */}
      <meta charSet={"utf-8"} />
      <meta httpEquiv="X-UA-Compatible" content={"IE=edge"} />
      <meta name="viewport" content={"width=device-width, initial-scale=1"} />
      <meta name="description" content={true} />
      <meta name="author" content={true} />
      <meta
        property="og:url"
        content={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }`}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="AudioStaq - A Modern Podcast Platform"
      />
      <meta
        property="og:description"
        content="A modern podcasting platform that allows podcasters to host, distribute, and monetize content."
      />
      <meta
        property="og:image"
        content={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/images/demo-stats.jpg`}
      />
      <title>AudioStaq | Podcast Hosting Price </title>
      <link rel="icon" type="image/x-icon" href="images/microphone.ico" />
      {/* Bootstrap Core CSS */}
      <link
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/vendor/bootstrap/css/bootstrap.min.css`}
        rel="stylesheet"
      />
      {/* Custom Fonts */}
      <link
        href={`http:${
          process.env.NODE_ENV == "development" ? "" : "s"
        }//fonts.googleapis.com/css?family=Lato`}
        rel="stylesheet"
      />
      <link
        href={`http:${
          process.env.NODE_ENV == "development" ? "" : "s"
        }//fonts.googleapis.com/css?family=Catamaran:100,200,300,400,500,600,700,800,900`}
        rel="stylesheet"
      />
      <link
        href={`http:${
          process.env.NODE_ENV == "development" ? "" : "s"
        }//fonts.googleapis.com/css?family=Muli`}
        rel="stylesheet"
      />
      {/* Plugin CSS */}
      <link
        rel="stylesheet"
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/vendor/font-awesome/css/font-awesome.min.css?r=2`}
      />
      <link
        rel="stylesheet"
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/vendor/simple-line-icons/css/simple-line-icons.css?r=2`}
      />
      <link
        rel="stylesheet"
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/vendor/device-mockups/device-mockups.min.css?r=2`}
      />
      {/* Theme CSS */}
      <link
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/css/new-age.css`}
        rel="stylesheet"
      />
      <link
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/css/pricing.css`}
        rel="stylesheet"
      />
      {/* HTML5 Shim and Respond.js IE8 support of HTML5 elements and media queries */}
      {/* WARNING: Respond.js doesn't work if you view the page via file:// */}
      {/*[if lt IE 9]>


    <![endif]*/}
      {/* Facebook Pixel Code */}
      <noscript>
        &lt;img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=1289805254481523&amp;ev=PageView&amp;noscript=1"
        /&gt;
      </noscript>
      {/* DO NOT MODIFY */}
      {/* End Facebook Pixel Code */}
      {/* Google Recaptch */}
      <nav
        id="mainNav"
        className="navbar navbar-default navbar-fixed-top"
        style={{
          backgroundColor: isTop ? "rgb(102, 51, 153)" : "white",
          borderWidth: "initial"
        }}
      >
        {" "}
        <div className="container">
          {/* Brand and toggle get grouped for better mobile display */}

          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <span className="sr-only">Toggle navigation</span> Menu{" "}
              <i className="fa fa-bars" />
            </button>
            <a href="/" className="logo">
              <img
                alt=""
                id="logo"
                src="images/logo.png"
                width="150px"
                style={{ marginTop: "10px" }}
              />
            </a>
          </div>
          {/* Collect the nav links, forms, and other content for toggling */}
          <div
            className={`navbar-collapse ${menuOpen ? "" : "collapse"} `}
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a
                  className="page-scroll"
                  href="/#download"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  Podcasters
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href="/#features"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  Advertisers
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href="/pricing"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  Pricing
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href={`http${
                    process.env.NODE_ENV == "development" ? "" : "s"
                  }://blog.audiostaq.com/`}
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  News
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href="/contact"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  Contact
                </a>
              </li>
              <li className="noSelect">
                <a
                  className="noSelect"
                  href="/dashboard/login"
                  data-toggle="modal"
                  data-target="#at-login"
                  id="loginMenu"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  {auth && auth.currentUser ? "Dashboard" : "Login"}
                </a>
              </li>
            </ul>
          </div>
          {/* /.navbar-collapse */}
        </div>
        {/* /.container-fluid */}
      </nav>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="header-content">
                <div className="header-content-inner">
                  <h1 className="contactH1">Contact Us</h1>
                  <h3>
                    We'd love to hear from you. Leave us a note and we'll be in
                    touch.
                  </h3>
                  {/*<span id="request-invite" class="btn btn-outline btn-xl page-scroll" data-toggle="modal" data-target="#at-request-invite"/>Request Invite</span>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section id="features" className="features">
        <div className="container">
          <div className="row">
            <div className="col-md-12" style={{ marginTop: "50px" }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-10 col-sm-offset-1 hid">
                    <div className="row">
                      <div className="col-sm-8 col-sm-offset-3 text-center">
                        <div id="hubspotForm" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="/contact"
        className="contact bg-primary"
        style={{ padding: 0 }}
      >
        <section id="contact" style={{ padding: 60 }}>
          <div className="container">
            <div className="row">
              <div
                className="about_our_company"
                style={{ marginBottom: "20px" }}
              >
                <h1 style={{ color: "#fff" }}>Contact Sales</h1>
                <div className="titleline-icon" />
                <p style={{ color: "#fff" }}>
                  Our team is waiting to work with you!{" "}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p style={{ color: "#fff" }}>
                  <strong>
                    <i className="fa fa-map-marker" /> Location
                  </strong>
                  <br />
                  Baltimore, MD
                </p>
              </div>
              <div className="col-md-4">
                <p style={{ color: "#fff" }}>
                  <strong>
                    <i className="fa fa-phone" /> Phone Number
                  </strong>
                  <br />
                  443.961.2453
                </p>
              </div>
              <div className="col-md-4">
                <p style={{ color: "#fff" }}>
                  <strong>
                    <i className="fa fa-envelope" /> Email
                  </strong>
                  <br />
                  <a href="contact.html">Contact Us</a>
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div
                className="about_our_company"
                style={{ marginBottom: "20px" }}
              >
                <a href="tos.html">Terms of Service</a>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="at-login-form">
        {/* MODAL LOGIN */}
        <div
          className="modal fade"
          id="at-login"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <input
                      type="username"
                      className="form-control-form "
                      id="uname"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control-form "
                      id="pwd"
                      placeholder="Password"
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-4" style={{ display: "none" }}>
                      <div className="checkbox">
                        <label>
                          <input type="checkbox" /> Remember me
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <p
                        className="frgt-pswd"
                        id="forgot-pwd"
                        style={{ cursor: "pointer" }}
                      >
                        Forgot Password
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <span id="loginReturnMsg">&nbsp;</span>
                    </div>
                  </div>
                  <input
                    type="button"
                    className="btn-lgin"
                    id="loginGo"
                    callback="dMain.loginReturn"
                    postfunc="login"
                    defaultValue="Login"
                  />
                  <div id="forgotDiv" style={{ display: "none" }}>
                    <div className="modal-body">
                      <p>
                        {" "}
                        Enter the email address associated with your account,
                        and we’ll email you a link to reset your password.{" "}
                      </p>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control-form "
                          id="resetEmail"
                          placeholder="Email"
                        />
                      </div>
                      <div id="message" />
                    </div>
                    <div />
                    <div className="modal-footer">
                      <input
                        type="button"
                        id="forgot-pwd-btn"
                        className="btn btn-primary btn-icon"
                        defaultValue="Reset"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL LOGIN ENDS */}
        {/* MODAL SIGNUP */}
        <div
          className="modal fade"
          id="at-signup"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <button
                  type="submit"
                  className="btn-lgin"
                  data-toggle="modal"
                  data-dismiss="modal"
                  data-target="#at-signup-filling"
                >
                  Signup with Email
                </button>{" "}
                <hr />
                <p>
                  By signing up, I agree to AudioStaq's
                  <a href="tos.html" target="_blank">
                    {" "}
                    Terms of Service
                  </a>
                  .{" "}
                </p>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col-md-6">
                    <p className="ta-l">Already a Member? </p>
                  </div>
                  <div className="col-md-4 col-md-offset-2">
                    <button
                      className="btn-gst"
                      data-toggle="modal"
                      data-dismiss="modal"
                      data-target="#at-login"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL SIGNUP FORM FILLING */}
        <div
          className="modal fade"
          id="at-signup-filling"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              type="text"
                              className="form-control-form "
                              id="signupName"
                              placeholder="First Name"
                              autoComplete="off"
                            />
                            <br />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control-form "
                              id="signupLName"
                              placeholder="Last Name"
                              autoComplete="off"
                            />
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              type="password"
                              className="form-control-form "
                              id="signupPwd"
                              placeholder="Password"
                              autoComplete="off"
                            />
                            <br />
                            <span id="signupPwdMsg">&nbsp;</span>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control-form"
                              id="signupCC"
                              placeholder="Credit Card #"
                              autoComplete="off"
                            />
                            <br />
                            <span>&nbsp;</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              type="email"
                              className="form-control-form "
                              id="signupEmail"
                              placeholder="Email"
                              autoComplete="off"
                            />
                            <br />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control-form"
                              id="signupCCCVV"
                              placeholder="CVV"
                              autoComplete="off"
                            />
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td>
                            <input
                              type="text"
                              className="form-control-form"
                              id="signupCCExp"
                              placeholder="Exp. Date (MM/YYYY)"
                              autoComplete="off"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        By signing up, I agree to Audiostaq’s{" "}
                        <a href="tos.html" target="_blank">
                          {" "}
                          Terms of Service
                        </a>
                      </p>
                    </div>
                  </div>
                  <div id="signupMessage" />
                  <button
                    type="button"
                    className="btn-lgin"
                    id="signupPro"
                    callback="dMain.checkNameReturn"
                    postfunc="checkname"
                  >
                    Signup
                  </button>
                  <input
                    type="hidden"
                    id="signupCreate"
                    postfunc="saveUser"
                    callback="dMain.newUserReturn"
                  />
                  <input
                    type="hidden"
                    id="saveUserPayment"
                    postfunc="cu"
                    callback="dMain.saveUserPaymentReturn"
                  />
                </form>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col-md-6">
                    <p className="ta-l">Already a Member? </p>
                  </div>
                  <div className="col-md-4 col-md-offset-2">
                    <button
                      className="btn-gst"
                      data-toggle="modal"
                      data-dismiss="modal"
                      data-target="#at-login"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
              <div
                id="signupProcessing"
                style={{
                  display: "none",
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  margin: "0 auto",
                  top: "0px",
                  textAlign: "center",
                  left: 0,
                  backgroundColor: "gray"
                }}
              >
                <div
                  style={{
                    position: "fixed",
                    top: "30%",
                    textAlign: "center",
                    width: "100%"
                  }}
                >
                  <div>Creating user login...</div>
                  <br />
                  <br />
                  <br />
                  <img
                    alt=""
                    src="images/loading.gif"
                    style={{ height: "100px", width: "100px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL SIGNUP FORM FILLING */}
        {/* MODAL FORGOT PASSWORD */}
        <div
          className="modal fade"
          id="at-reset-pswd"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <p>
                    {" "}
                    Enter the email address associated with your account, and
                    we’ll email you a link to reset your password.{" "}
                  </p>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control-form "
                      id="resetEmail"
                      placeholder="Email"
                    />
                  </div>
                  <div id="message" />
                </form>
              </div>
              <div />
              <div className="modal-footer">
                <button
                  id="forgot-pwd-btn"
                  className="btn btn-primary btn-icon"
                  data-dismiss="modal"
                  aria-label="Submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL HELP */}
        <div
          className="modal fade"
          id="at-helping"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control-form "
                      id="exampleInputEmaillog"
                      placeholder="Enter Your Searches "
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <button
                    className="btn-gst"
                    data-toggle="modal"
                    data-dismiss="modal"
                  >
                    Send a reset Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="container">
          <p>© 2019 AudioStaq. All Rights Reserved.</p>
          <ul className="list-inline" />
        </div>
      </footer>
      {/* jQuery */}
      {/* Bootstrap Core JavaScript */}
      {/* Plugin JavaScript */}
      {/* Theme JavaScript */}
      <input
        type="hidden"
        callback="dMain.tokenReturn"
        postfunc="checkToken"
        id="checkToken"
      />
      <input
        type="hidden"
        callback="dMain.createReturn"
        postfunc="saveUser"
        id="createUser"
      />
      <input
        type="hidden"
        id="pwdReset"
        callback="dMain.pwdResetReturn"
        postfunc="pwdReset"
      />
      <input
        type="hidden"
        id="getShows"
        callback="dMain.getShowsReturn"
        postfunc="generic"
      />

      {/* Start of HubSpot Embed Code */}

      {/* End of HubSpot Embed Code */}
    </div>
  )
}

import { Button } from "@material-ui/core"
import React, { useEffect, useState } from "react"

import { withRouter, Link } from "react-router-dom"
import { auth, db, functions } from "../firebase"
import { forwardRef } from "react"
import Switch from "@material-ui/core/Switch"

import { useCollection } from "react-firebase-hooks/firestore"
import { CSSGrid, makeResponsive, measureItems } from "react-stonecutter"
import MaterialTable from "material-table"
import { Paper } from "@material-ui/core"
import AddBox from "@material-ui/icons/AddBox"
import ArrowUpward from "@material-ui/icons/ArrowUpward"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import TrendingUp from "@material-ui/icons/TrendingUp"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Remove from "@material-ui/icons/Remove"
import SaveAlt from "@material-ui/icons/SaveAlt"
import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"
import moment from "moment"

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}
const Grid = makeResponsive(measureItems(CSSGrid), {
  maxWidth: 1920,
  minPadding: 100
})
let gotImpressions = false
function Campaigns(props) {
  const getAdImpressions = functions.httpsCallable("getAdImpressions")
  let fetchData = async () => {
    let data = await getAdImpressions()
    console.log(data.data[0].data)

    let ma = data.data[0].data.reduce((sum, item) => {
      sum[item.eventLabel] = item.totalEvents
      return sum
    }, {})
    setAdImpressions(ma)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [adImpressions, setAdImpressions] = useState(null)
  const [tableData, setTableData] = useState(null)
  const [files, setFiles] = useState(null)

  const { value: campaigns } = useCollection(
    db
      .collection("campaigns")
      .where(`roles.${auth.currentUser.uid}`, "==", "owner")
  )

  const { value: totalFiles } = useCollection(
    db
      .collection("campaigns")
      .where(`roles.${auth.currentUser.uid}`, "==", "owner")
  )
  return (
    <div
      style={{
        marginTop: 20,
        textAlign: "center",
        maxWidth: "100%"
      }}
    >
      <Paper>
        <MaterialTable
          icons={tableIcons}
          columns={[
            { title: "id", field: "id" },
            { title: "Status", field: "status" },
            { title: "Name", field: "name" },
            { title: "Budget", field: "budget" },
            { title: "Spend", field: "spend" },
            { title: "Impressions", field: "impressions" },
            { title: "Start Date", field: "startdate" },
            { title: "End Date", field: "enddate" }
          ]}
          actions={[
            {
              icon: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
              tooltip: "Edit",
              onClick: (event, rowData) =>
                props.history.push(`/dashboard/campaign/${rowData.id}/edit`)
            },
            {
              icon: forwardRef((props, ref) => (
                <TrendingUp {...props} ref={ref} />
              )),
              tooltip: "Stats",
              onClick: (event, rowData) =>
                props.history.push(`/dashboard/campaign/${rowData.id}/stats`)
            },
            {
              icon: forwardRef((props, ref) => (
                <DeleteOutline {...props} ref={ref} />
              )),
              tooltip: "Delete",
              onClick: (event, rowData) => {
                var didc = window.confirm(
                  "You want to delete " + rowData.name + "?"
                )
                if (didc) db.doc(`campaigns/${rowData.id}`).delete()
              }
            }
          ]}
          data={
            campaigns &&
            campaigns.docs.map((doc, index) => {
              return {
                status: (
                  <Switch
                    checked={doc.data().enabled}
                    onChange={async event => {
                      await doc.ref.set(
                        { enabled: event.target.checked },
                        { merge: true }
                      )
                    }}
                  />
                ),
                id: doc.id,
                name: doc.data().name,
                budget: doc.data().budget,
                spend: adImpressions
                  ? Math.round(
                      ((doc.data().cpm || 0) / 1000) *
                        (adImpressions[doc.id] || 0)
                    )
                  : "loading...",
                impressions: adImpressions
                  ? adImpressions[doc.id] || 0
                  : "loading...",
                startdate: moment(doc.data().startDate.toDate()).format(
                  "YYYY-MM-DD"
                ),
                enddate: moment(doc.data().endDate.toDate()).format(
                  "YYYY-MM-DD"
                ),
                total: files ? files[index] : ""
              }
            })
          }
          title={
            <Link to={"/dashboard/campaign/create"}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "rgb(103, 58, 183)",
                  height: 30,
                  width: 150,
                  fontSize: 10,

                  margin: "20px"
                }}
              >
                New Campaign
              </Button>
            </Link>
          }
        />
      </Paper>
    </div>
    /*  {campaigns &&

                <TableRow key={"tableI" + index}>
                  <TableCell align="left"> {doc.id}</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left"> {doc.data().name}</TableCell>

                  <TableCell align="left">Budget</TableCell>
                  <TableCell align="left">Spend</TableCell>
                  <TableCell align="left">
                    {adImpressions && adImpressions[index]}
                  </TableCell>
                  <TableCell align="left">Start Date</TableCell>
                  <TableCell align="left">Spend Date</TableCell>

                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              ))}*/

    //  </Paper>
  )
}

export default withRouter(Campaigns)

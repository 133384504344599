import React, { useEffect } from "react"

import { withRouter } from "react-router-dom"
import { db } from "../firebase"
import { useDocument } from "react-firebase-hooks/firestore"
import { FixedSizeList as List } from "react-window"
function Episode(props) {
  const showRef = db
    .collection("shows")
    .doc(props.match.params.showName)
    .collection("episodes")
    .doc(props.match.params.episode)

  const { value: episodeData } = useDocument(showRef)

  useEffect(() => {
    if (!episodeData) return
    props.setTitle(`Episode ${episodeData.data().name}`)
  }, [episodeData])

  let podcastUrl = `http://podcast.${process.env.REACT_APP_SERVER}/${props.match.params.showName}/${props.match.params.episode}.mp3`
  return (
    <div>
      {episodeData && !episodeData.data().uploadedFileUrl && (
        <div>processing...</div>
      )}
      {episodeData && episodeData.data().uploadedFileUrl ? (
        <div>
          <a href={podcastUrl}>{podcastUrl} </a>{" "}
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}
export default withRouter(Episode)

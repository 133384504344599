import React from "react"
import { Paper } from "@material-ui/core"
import { CSSGrid, measureItems, makeResponsive } from "react-stonecutter"
const Grid = makeResponsive(measureItems(CSSGrid), {
  maxWidth: 1920,
  minPadding: 100
})
export default () => {
  const paperStyle = {
    width: 200,
    height: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
  return (
    <Grid
      component="ul"
      columns={4}
      columnWidth={200}
      gutterWidth={25}
      gutterHeight={25}
      style={{
        textAlign: "center",
        margin: "0 auto",
        marginTop: 30
      }}
      itemHeight={200}
      springConfig={{ stiffness: 170, damping: 26 }}
    >
      <div class=" col-sm-3 col-md-3 distro">
        <Paper style={paperStyle}>
          <a href="https://podcastsconnect.apple.com" target="blank">
            <img
              alt=""
              src={`http://${process.env.REACT_APP_DOMAIN}/apple_podcasts_icon.svg`} //${process.env.REACT_APP_SERVER}
              class="dis-img"
              style={{ width: 140 }}
            />
          </a>
        </Paper>
      </div>
      <div class="col-sm-3 col-md-3 distro">
        <Paper style={paperStyle}>
          <a
            href="https://play.google.com/music/podcasts/publish"
            target="blank"
          >
            <img
              alt=""
              src={`https://${process.env.REACT_APP_DOMAIN}/google-play-music.svg`}
              class="dis-img"
              style={{ width: 140 }}
            />
          </a>
        </Paper>
      </div>
      <div class="col-sm-3 col-md-3 distro">
        <Paper style={paperStyle}>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScH5z3KSJJdeEUgoq7AkGbe0rap6ncDhkP8Mm2eHP_HkvwYkA/viewform"
            target="blank"
          >
            <img
              alt=""
              src={`https://${process.env.REACT_APP_DOMAIN}/iheart-logo.svg`}
              class="dis-img"
              style={{ width: 140 }}
            />
          </a>
        </Paper>
      </div>
      <div class="col-sm-3 col-md-3 distro">
        <Paper style={paperStyle}>
          <a href="https://www.stitcher.com/content-providers" target="blank">
            <img
              alt=""
              src={`https://${process.env.REACT_APP_DOMAIN}/stitcher-logo.svg`}
              class="dis-img"
              style={{ width: 140 }}
            />
          </a>
        </Paper>
      </div>
      <div class="col-sm-3 col-md-3 distro">
        <Paper style={paperStyle}>
          <a
            href="https://help.tunein.com/contact/add-podcast-S19TR3Sdf"
            target="blank"
          >
            <img
              alt=""
              src={`https://${process.env.REACT_APP_DOMAIN}/tunein-icon.svg`}
              class="dis-img"
              style={{ width: 140 }}
            />
          </a>
        </Paper>
      </div>
      <div class="col-sm-3 col-md-3 distro">
        <Paper style={paperStyle}>
          <a href="https://www.podchaser.com/podcasts" target="blank">
            <img
              alt=""
              src={`https://${process.env.REACT_APP_DOMAIN}/podchaser-logo.jpg`}
              class="dis-img"
              style={{ width: 140 }}
            />
          </a>
        </Paper>
      </div>

      <div class="col-sm-3 col-md-3 distro">
        <Paper style={paperStyle}>
          <a href="https://podcasters.spotify.com/" target="blank">
            <img
              alt=""
              src={`https://${process.env.REACT_APP_DOMAIN}/spotify-logo.png`}
              class="dis-img"
              style={{ width: 140 }}
            />
          </a>
        </Paper>
      </div>

      <div class="col-sm-3 col-md-3 distro">
        <Paper style={paperStyle}>
          <a href="https://www.ampplaybook.com/podcasts/" target="blank">
            <img
              alt=""
              src={`https://${process.env.REACT_APP_DOMAIN}/pandora-logo.png`}
              class="dis-img"
              style={{ width: 140 }}
            />
          </a>
        </Paper>
      </div>
    </Grid>
  )
}

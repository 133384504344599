import React, { useState, useEffect, useRef } from "react"
import clean from "../Shared/clean.js"
import { withRouter } from "react-router-dom"
import firebase, { db, auth, fieldValue } from "../firebase"
import encodeurl from "encodeurl"
import { fieldsToForm, submitButton } from "../App/Layouts/FormRoute"
import ImageUploader from "../Components/imageUploader.js"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import PersonIcon from "@material-ui/icons/Person"
import Avatar from "@material-ui/core/Avatar"
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper
} from "@material-ui/core"
function CreateNetwork(props) {
  const { classes } = props
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [networkUrl, setNetworkUrl] = useState("")
  const [email, setEmail] = useState("")
  const [hasImage, setHasImage] = useState(false)
  const [users, setUsers] = useState([])
  const [usersOrignal, setUsersOringal] = useState([])
  const [croppedCanvas, setCroppedCanvas] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const imageUploaderRef = useRef(null)
  const [open, setOpen] = React.useState(false)
  async function fetchData() {
    let networkRef = db.doc(`networks/${props.match.params.networkName}`)
    let networkDoc = await networkRef.get()
    let networkData = networkDoc.data()
    setIsEditing(true)
    setName(networkData.name)
    setDescription(networkData.description)
    setNetworkUrl(props.match.params.networkName)

    setUsers(networkData.emails || [])
    setUsersOringal(networkData.emails)
    if (networkData.hasImage) {
      setCroppedCanvas({
        toDataURL: () =>
          `https://${process.env.REACT_APP_IMAGE_URL}` +
          props.match.params.networkName +
          "/-network.jpg",
        cantEdit: true
      })
    }
  }
  useEffect(() => {
    if (props.match.params.networkName) {
      fetchData()
    }
  }, [])

  const fields = [
    {
      title: "Name",
      value: name,
      id: "name",
      onChange: e => {
        setName(e.target.value)
        if (!props.match.params.networkName)
          setNetworkUrl(
            encodeurl(e.target.value)
              .toLowerCase()
              .replace(/%20/g, "_")
              .replace(/[^a-z0-9_]/g, "")
          )
      }
    },
    {
      title: "network ID",
      value: networkUrl,
      id: "networkId",
      onChange: e => setNetworkUrl(e.target.value),
      disabled: !isEditing
    },
    {
      title: "Description",
      value: description,
      id: "description",
      onChange: e => setDescription(e.target.value)
    },
    {
      title: "Add Email",
      value: email,
      id: "email",
      onChange: e => setEmail(e.target.value)
    }
  ]

  let imageUploader
  const addEmail = () => {
    setUsers([...users, email])
    setEmail("")
  }
  const deleteEmail = email => {
    setUsers(users.filter(item => item !== email))
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Paper className={props.classes.formPaper} style={{ paddingBottom: 20 }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"send emails"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            These emails will be sent information on how to join your network :
            {users
              .filter(n => !usersOrignal.includes(n))
              .map(user => (
                <ListItem key={user}>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>

                  <ListItemText style={{ marginLeft: 10 }} primary={user} />
                </ListItem>
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => addNetwork()} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <form
        className={classes.form}
        onSubmit={e => e.preventDefault() && false}
      >
        {fieldsToForm(fields)}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          onClick={addEmail}
          className={classes.submit}
        >
          Add Email
        </Button>
        <List>
          {users.map(user => (
            <ListItem key={user}>
              <ListItemText primary={user} />
              <ListItemSecondaryAction>
                <IconButton aria-label="Delete">
                  <DeleteIcon onClick={() => deleteEmail(user)} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <ImageUploader
          ref={imageUploaderRef}
          title="Network Image"
          hasUpload={hasImg => setHasImage(hasImg)}
          uploadFinished={() => props.history.push("/dashboard/networks")}
          onSave={setCroppedCanvas}
          croppedCanvas={croppedCanvas}
          onDelete={() => setCroppedCanvas(null)}
        />

        {submitButton({
          onClick: () =>
            users.filter(n => !usersOrignal.includes(n)).length > 0
              ? setOpen(true)
              : addNetwork(),
          text: props.match.params.networkName ? "Update" : "Create"
        })}
      </form>
    </Paper>
  )

  async function addNetwork() {
    try {
      await db
        .doc(`networks/${props.match.params.networkName || networkUrl}`)
        .set(
          clean({
            hasImage: Boolean(croppedCanvas),
            users: [auth.currentUser.uid],
            emails: users,
            name,
            description
          }),
          { merge: true }
        )

      await firebase.userRef().update({
        networks: fieldValue.arrayUnion(db.doc(`networks/${networkUrl}`))
      })

      if (hasImage)
        imageUploaderRef.current.startUpload(
          (props.match.params.networkName || networkUrl) + "-network"
        )
      else props.history.push("/dashboard/networks")
    } catch (error) {
      alert(error.message)
    }
  }
}

export default withRouter(CreateNetwork)

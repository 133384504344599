import React, { useState } from "react"
import { injectStripe, CardElement } from "react-stripe-elements"
import { Button } from "@material-ui/core"
import { Link } from "react-router-dom"

export default injectStripe(function(props) {
  async function updatePayment(ev) {
    ev.preventDefault()
    try {
      props.stripe
        .createPaymentMethod("card", {
          billing_details: { name: props.name }
        })
        .then(({ error, paymentMethod }) => {
          if (error) {
            alert(error.message)
          } else {
            props.onFinish(paymentMethod)
          }
        })
    } catch (error) {
      alert(error.message)
    }
  }
  const [cardFocused, setCardFocused] = useState(false)
  return (
    <form onSubmit={updatePayment} style={{ width: "100%" }}>
      {!props.noSubmit ? props.children : <br />}

      <div
        style={{
          padding: cardFocused ? "0px" : "1px",
          marginTop: "17px",
          marginBottom: props.noSubmit ? "17px" : "0"
        }}
      >
        <div
          style={{
            paddingBottom: "18.5px",
            paddingLeft: "14px",
            paddingRight: "14px",
            paddingTop: "18px",
            borderColor: cardFocused ? "#3f51b5" : "rgba(0, 0, 0, 0.23)",
            borderStyle: "solid",
            borderWidth: cardFocused ? "2px" : "1px",
            borderRadius: "4px",
            tabindex: "0",
            boxSizing: "border-box"
          }}
        >
          <CardElement
            hidePostalCode={true}
            onFocus={() => setCardFocused(true)}
            onBlur={() => setCardFocused(false)}
            style={{
              base: {
                color: "rgba(0, 0, 0, 0.54)",
                fontSize: "16px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
              }
            }}
          />
        </div>
      </div>
      {props.noSubmit ? props.children : ""}
      {!props.noSubmit ? (
        <React.Fragment>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={props.classes.submit}
            style={{
              backgroundColor: "rgb(103, 58, 183)"
            }}
          >
            Register
          </Button>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to="/dashboard/login"
            className={props.classes.submit}
          >
            Go back to Login
          </Button>
        </React.Fragment>
      ) : (
        ""
      )}
    </form>
  )
})

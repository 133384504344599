import React, { useState } from "react"
import {
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Paper
} from "@material-ui/core"

import { withRouter } from "react-router-dom"
import { fieldValidatorCore } from "react-validation-framework"
import { functions } from "../firebase"
import { fieldsToForm, submitButton } from "../App/Layouts/FormRoute"
import validator from "validator"
function ImportRss(props) {
  const { classes } = props
  const importRssFunc = functions.httpsCallable("importRss")
  const [rssFeed, setRssFeed] = useState("")
  const [importFiles, setImportFiles] = useState(true)
  const [didStartImport, setDidStartImport] = useState(false)
  const fields = [
    {
      validators: [
        {
          validator: val => validator.isURL(val),
          errorPropValue: true,
          errorMessage: "Not Valid Url"
        }
      ],
      placeholder: "http://example-podcast.com/rss",
      title: "Rss Feed",
      value: rssFeed,
      id: "rssFeed",
      onChange: e => setRssFeed(e.target.value)
    }
  ]

  let importSubmit = async () => {
    try {
      let checkFieldTestResult = fieldValidatorCore.checkGroup("formGroup")

      if (!checkFieldTestResult.isValid) {
        window.scrollTo(
          0,
          document.getElementById(
            checkFieldTestResult.inValidComponents[0].props.id.offsetTop
          )
        )
        return
        //  window.scrollTo(0, domc.offsetTop)
      }

      setDidStartImport(true)
      let d = await importRssFunc({ rssFeed, importFiles })
      props.history.replace("/dashboard/show/" + d.data)
    } catch (error) {
      alert("Invalid Rss Feed")
      setDidStartImport(false)
    }
  }
  let importButton = {
    onClick: importSubmit,
    text: " import"
  }

  return (
    <Paper className={props.classes.formPaper}>
      <div style={{ width: "100%" }}>
        <form
          className={classes.form}
          onSubmit={e => e.preventDefault() && false}
          style={{ width: "100%" }}
        >
          <FormControl
            margin="normal"
            style={{ width: "100%" }}
            key={"FeedOnly"}
          >
            <FormControlLabel
              value="Feed Only Import"
              label="Feed Only Import"
              control={
                <Checkbox
                  checked={!importFiles}
                  onChange={event => setImportFiles(!event.target.checked)}
                />
              }
            />
          </FormControl>

          {fieldsToForm(fields)}
          {!didStartImport ? (
            submitButton(importButton)
          ) : (
            <div style={{ width: "100%", textAlign: "center", padding: 20 }}>
              <CircularProgress />
            </div>
          )}
        </form>
      </div>
    </Paper>
  )
}

export default withRouter(ImportRss)

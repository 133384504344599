import React, { useEffect, useState } from "react"
import { auth } from "../firebase"

export default () => {
  let [isTop, setIsTop] = useState(true)
  let [menuOpen, setMenuOpen] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > 50) {
        setIsTop(false)
      } else {
        setIsTop(true)
      }
    })
  }, [])

  return (
    <div>
      {/* start Mixpanel */}
      {/* end Mixpanel */}

      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="author" content="AudioStaq, Inc" />
      <meta
        content="Enterprise podcast hosting solutions for brands and influencers. All the tools you need to host, distribute, and monetize your podcasts."
        name="description"
      />
      <meta
        property="og:url"
        content={`https://${process.env.REACT_APP_DOMAIN}`}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="AudioStaq - A Modern Podcast Platform for Brands and Influencers"
      />
      <meta
        property="og:description"
        content="A modern podcasting platform for brands and influencers that allows podcasters to host, distribute, and monetize content."
      />
      <meta
        property="og:image"
        content={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/images/audiostaq_dashboard.jpg`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@audiostaq" />
      <meta name="twitter:creator" content="@audiostaq" />
      <meta
        name="twitter:title"
        content="A modern podcasting platform that allows podcasters to host, distribute, and monetize content."
      />

      <meta
        name="twitter:description"
        content="Enterprise podcast hosting solutions for brands and influencers. All the tools you need to host, distribute, and monetize your podcasts."
      />
      <meta
        name="twitter:image"
        content={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/images/audiostaq_dashboard.jpg`}
      />
      <title>AudioStaq | A Modern Podcast Platform</title>
      <link rel="icon" type="image/x-icon" href="images/microphone.ico" />
      {/* Bootstrap Core CSS */}
      <link
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/vendor/bootstrap/css/bootstrap.min.css`}
        rel="stylesheet"
      />
      {/* Custom Fonts */}
      <link
        href="https://fonts.googleapis.com/css?family=Lato"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Catamaran:100,200,300,400,500,600,700,800,900"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Muli"
        rel="stylesheet"
      />
      {/* Plugin CSS */}
      <link
        rel="stylesheet"
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/vendor/font-awesome/css/font-awesome.min.css?r=2`}
      />
      <link
        rel="stylesheet"
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/vendor/simple-line-icons/css/simple-line-icons.css?r=2`}
      />
      <link
        rel="stylesheet"
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/vendor/device-mockups/device-mockups.min.css?r=2`}
      />
      {/* Theme CSS */}
      <link
        href={`http${process.env.NODE_ENV == "development" ? "" : "s"}://${
          process.env.REACT_APP_DOMAIN
        }/css/new-age.css`}
        rel="stylesheet"
      />
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n\t\t .red{color:red;}\n\t\t .orange{color:orange;}\n\t\t .green{color:green;}\n\t"
        }}
      />
      {/* HTML5 Shim and Respond.js IE8 support of HTML5 elements and media queries */}
      {/* WARNING: Respond.js doesn't work if you view the page via file:// */}
      {/*[if lt IE 9]>


      <![endif]*/}
      {/* Facebook Pixel Code */}
      <noscript>
        &lt;img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=1289805254481523&amp;ev=PageView&amp;noscript=1"/&gt;
      </noscript>
      {/* DO NOT MODIFY */}
      {/* End Facebook Pixel Code */}
      {/* Google Recaptch */}
      <nav
        id="mainNav"
        className="navbar navbar-default navbar-fixed-top"
        style={{
          backgroundColor: isTop ? "rgb(102, 51, 153)" : "white",
          borderWidth: "initial"
        }}
      >
        <div className="container">
          {/* Brand and toggle get grouped for better mobile display */}
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <span className="sr-only">Toggle navigation</span> Menu{" "}
              <i className="fa fa-bars" />
            </button>
            <a href="/" className="logo">
              <img
                alt=""
                id="logo"
                src="images/logo.png"
                width="150px"
                style={{ marginTop: "10px" }}
              />
            </a>
          </div>
          {/* Collect the nav links, forms, and other content for toggling */}
          <div
            className={`navbar-collapse ${menuOpen ? "" : "collapse"} `}
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a
                  className="page-scroll"
                  href="#download"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  Podcasters
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href="#features"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  Advertisers
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href="/pricing"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  Pricing
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href="http://blog.audiostaq.com/"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  News
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href="/contact"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  Contact
                </a>
              </li>
              <li className="noSelect">
                <a
                  className="noSelect"
                  href="/dashboard/login"
                  data-toggle="modal"
                  data-target="#at-login"
                  id="loginMenu"
                  style={{
                    color: isTop ? "white" : "black"
                  }}
                >
                  {auth && auth.currentUser ? "Dashboard" : "Login"}
                </a>
              </li>
            </ul>
          </div>
          {/* /.navbar-collapse */}
        </div>
        {/* /.container-fluid */}
      </nav>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="header-content">
                <div className="header-content-inner">
                  <h1>Enterprise podcasting for brands and influencers</h1>
                  <p>
                    Distribute, measure, and monetize your podcast business or
                    network with one powerful platform.
                  </p>
                  <a
                    href="/pricing"
                    id="request-invite"
                    className="btn btn-outline btn-xl"
                  >
                    Sign Up Today!
                  </a>
                  <img
                    alt=""
                    id="feature-img"
                    src="images/audiostaq_dashboard.jpg"
                  />
                  {/*<span id="request-invite" class="btn btn-outline btn-xl page-scroll" data-toggle="modal" data-target="#at-request-invite"/>Request Invite</span>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section id="download" className="download bg-primary text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-heading">
                Podcast tools for the modern storyteller
              </h2>
              <p>Eveything you need from a podcast platform.</p>
              <iframe
                style={{
                  marginTop: "50px",
                  marginBottom: "75px",
                  maxWidth: 650
                }}
                frameborder="no"
                preload="none"
                scrolling="no"
                width="100%"
                height="200px"
                src="https://player.audiostaq.com/inthosegenes/scientific_sank"
              ></iframe>
            </div>
          </div>
          <div className="clearfix visible-xs-block" />
          <div className="row">
            <div className="col-md-4">
              <div className="feature-item">
                <i className="icon-layers text-primary" />
                <h3>Podcast Hosting and Storage</h3>
                <p className="text-muted">
                  Our top tier hosting solution powers fast delivery for your
                  listeners.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-item">
                <i className="icon-wallet text-primary" />
                <h3>Monthly Revenue</h3>
                <p className="text-muted">
                  We split revenue with our publishing partners. Every ad played
                  is a dollar made.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-item">
                <i className="icon-rocket text-primary" />
                <h3>Dynamic Ad Insertion</h3>
                <p className="text-muted">
                  Swap ads on the fly, monetize your full catalogue on old and
                  new content.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="feature-item">
                <i className="icon-music-tone-alt text-primary" />
                <h3>Modern Podcast Player</h3>
                <p className="text-muted">
                  Sleek embeddable player that is easy to use and looks nice on
                  your website.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-item">
                <i className="icon-calendar text-primary" />
                <h3>Episode Scheduling</h3>
                <p className="text-muted">
                  You have more shows to make, set them to publish on a
                  consistant day and time.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-item">
                <i className="icon-chart text-primary" />
                <h3>Real-time Stats and Reports</h3>
                <p className="text-muted">
                  Track how listeners are consuming your content with our best
                  in-class analytics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="features
"
        className="features"
        style={{ backgroundColor: "white" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-heading" style={{ marginTop: "60px" }}>
                <h2>Podcast advertising made simple</h2>
                <p className="text-muted">
                  Access our targeted podcast advertising tools and customer
                  insights to reach your audience.
                </p>
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="device-container">
                <div className="device-mockup macbook_pro_2015 grey">
                  <div className="device">
                    <div className="screen">
                      {/* Demo image for screen mockup, you can put an image here, some HTML, an animation, video, or anything else! */}
                      <img
                        src="images/campaigns.jpg"
                        className="img-responsive"
                        alt="audiostaq dashboard"
                        title="AudioStaq Dashboard"
                      />{" "}
                    </div>
                    <div className="button">
                      {/* You can hook the "home button" to some JavaScript events or just remove it */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12" style={{ marginTop: "50px" }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="feature-item">
                      <i className="icon-microphone text-primary" />
                      <h3>Sponsorships</h3>
                      <p className="text-muted">
                        Track ad metrics with traditional coupon codes by
                        intergrating sponsored content.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="feature-item">
                      <i className="icon-speech text-primary" />
                      <h3>Native live reads</h3>
                      <p className="text-muted">
                        Connect with amazing voices to talk about your great
                        products.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="feature-item">
                      <i className="icon-rocket text-primary" />
                      <h3>Programmatic podcast advertising</h3>
                      <p className="text-muted">
                        Buy, schedule, scale, and budget campaigns with ease
                        through our self serve interface.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="feature-item">
                      <i className="icon-globe text-primary" />
                      <h3>Get real-time targeted ads</h3>
                      <p className="text-muted">
                        Let us target your ads by country, state, city, gender,
                        age, and more! All in Real-time
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cta">
        <div className="overlay" />
        <div className="cta-content">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 style={{ color: "white" }}>Listeners are growing.</h3>
                <p style={{ color: "white" }}>
                  67 Million people are listening to podcast monthly. The gold
                  rush for advertisers to capture that audience is here.
                </p>
              </div>
              <div className="col-md-6">
                <h3 style={{ color: "white" }}>
                  The earbuds speak for themselves.
                </h3>
                <p style={{ color: "white" }}>
                  Listeners spend over 4 hours per day consuming content and 53%
                  listen from home.
                </p>
              </div>
              <div className="col-md-6">
                <h3 style={{ color: "white" }}>Unmatched buying power.</h3>
                <p style={{ color: "white" }}>
                  Podcast listeners are well educated and have salaries over
                  $75,000. They are ready to buy and willing to listen.
                </p>
              </div>
              <div className="col-md-8">
                <a
                  href="http://www.edisonresearch.com/wp-content/uploads/2016/05/The-Podcast-Consumer-2016.pdf"
                  className="btn btn-outline btn-xl page-scroll"
                >
                  See the Report
                </a>
                <a href="/contact" className="btn btn-outline btn-xl">
                  Advertise Today!
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="/contact"
        className="contact bg-primary"
        style={{ padding: 0 }}
      >
        <section id="contact" style={{ padding: 60 }}>
          <div className="container">
            <div className="row">
              <div
                className="about_our_company"
                style={{ marginBottom: "20px" }}
              >
                <h3 style={{ color: "#fff" }}>Contact Sales</h3>
                <div className="titleline-icon" />
                <p style={{ color: "#fff" }}>
                  Our team is waiting to work with you!{" "}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p style={{ color: "#fff" }}>
                  <strong>
                    <i className="fa fa-map-marker" /> Location
                  </strong>
                  <br />
                  Baltimore, MD
                </p>
              </div>
              <div className="col-md-4">
                <p style={{ color: "#fff" }}>
                  <strong>
                    <i className="fa fa-phone" /> Phone Number
                  </strong>
                  <br />
                  443.961.2453
                </p>
              </div>
              <div className="col-md-4">
                <p style={{ color: "#fff" }}>
                  <strong>
                    <i className="fa fa-envelope" /> Email
                  </strong>
                  <br />
                  <a href="/contact">Contact Us</a>
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div
                className="about_our_company"
                style={{ marginBottom: "20px" }}
              >
                <a href="/tos">Terms of Service</a>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="at-login-form">
        {/* MODAL LOGIN */}
        <div
          className="modal fade"
          id="at-login"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <input
                      type="username"
                      className="form-control-form "
                      id="uname"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control-form "
                      id="pwd"
                      placeholder="Password"
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-4" style={{ display: "none" }}>
                      <div className="checkbox">
                        <label>
                          <input type="checkbox" /> Remember me
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <p
                        className="frgt-pswd"
                        id="forgot-pwd"
                        style={{ cursor: "pointer" }}
                      >
                        Forgot Password
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <span id="loginReturnMsg">&nbsp;</span>
                    </div>
                  </div>
                  <input
                    type="button"
                    className="btn-lgin"
                    id="loginGo"
                    callback="dMain.loginReturn"
                    postfunc="login"
                    defaultValue="Login"
                  />
                  <div id="forgotDiv" style={{ display: "none" }}>
                    <div className="modal-body">
                      <p>
                        {" "}
                        Enter the email address associated with your account,
                        and we’ll email you a link to reset your password.{" "}
                      </p>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control-form "
                          id="resetEmail"
                          placeholder="Email"
                        />
                      </div>
                      <div id="message" />
                    </div>
                    <div />
                    <div className="modal-footer">
                      <input
                        type="button"
                        id="forgot-pwd-btn"
                        className="btn btn-primary btn-icon"
                        defaultValue="Reset"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL LOGIN ENDS */}
        {/* MODAL SIGNUP */}
        <div
          className="modal fade"
          id="at-signup"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <button
                  type="submit"
                  className="btn-lgin"
                  data-toggle="modal"
                  data-dismiss="modal"
                  data-target="#at-signup-filling"
                >
                  Signup with Email
                </button>{" "}
                <hr />
                <p>
                  By signing up, I agree to AudioStaq's
                  <a href="/tos" target="_blank">
                    Terms of Service
                  </a>
                  .{" "}
                </p>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col-md-6">
                    <p className="ta-l">Already a Member? </p>
                  </div>
                  <div className="col-md-4 col-md-offset-2">
                    <button
                      className="btn-gst"
                      data-toggle="modal"
                      data-dismiss="modal"
                      data-target="#at-login"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL SIGNUP FORM FILLING */}
        <div
          className="modal fade"
          id="at-signup-filling"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control-form "
                      id="signupName"
                      placeholder="First Name"
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control-form "
                      id="signupLName"
                      placeholder="Last Name"
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control-form "
                      id="signupEmail"
                      placeholder="Email"
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control-form "
                      id="signupPwd"
                      placeholder="Password"
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group">
                    <span id="signupPwdMsg" />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control-form"
                      id="inviteCode"
                      placeholder="Invite Code"
                      autoComplete="off"
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        By signing up, I agree to Audiostaq’s{" "}
                        <a href="/tos" target="_blank">
                          Terms of Service
                        </a>
                      </p>
                    </div>
                  </div>
                  <div id="signupMessage" />
                  <button
                    type="button"
                    className="btn-lgin"
                    id="signupGo"
                    callback="dMain.checkNameReturn"
                    postfunc="checkname"
                  >
                    Signup
                  </button>
                  <input
                    type="hidden"
                    id="signupInvite"
                    callback="dMain.checkInviteReturn"
                    postfunc="checkinvite"
                  />
                  <input
                    type="hidden"
                    id="signupCreate"
                    postfunc="saveUser"
                    callback="dMain.newUserReturn"
                  />
                </form>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col-md-6">
                    <p className="ta-l">Already a Member? </p>
                  </div>
                  <div className="col-md-4 col-md-offset-2">
                    <button
                      className="btn-gst"
                      data-toggle="modal"
                      data-dismiss="modal"
                      data-target="#at-login"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL SIGNUP FORM FILLING */}
        {/* MODAL FORGOT PASSWORD */}
        <div
          className="modal fade"
          id="at-reset-pswd"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <p>
                    {" "}
                    Enter the email address associated with your account, and
                    we’ll email you a link to reset your password.{" "}
                  </p>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control-form "
                      id="resetEmail"
                      placeholder="Email"
                    />
                  </div>
                  <div id="message" />
                </form>
              </div>
              <div />
              <div className="modal-footer">
                <button
                  id="forgot-pwd-btn"
                  className="btn btn-primary btn-icon"
                  data-dismiss="modal"
                  aria-label="Submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL HELP */}
        <div
          className="modal fade"
          id="at-helping"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control-form "
                      id="exampleInputEmaillog"
                      placeholder="Enter Your Searches "
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <button
                    className="btn-gst"
                    data-toggle="modal"
                    data-dismiss="modal"
                  >
                    Send a reset Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="at-request-invite"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">x</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control-form"
                    id="requestEmail"
                    placeholder="Email"
                    postfunc="checkname"
                    callback="dMain.inviteCheckNameReturn"
                  />
                  <div id="requestInviteMsg">&nbsp;</div>
                  <br />
                  <fieldset id="group2">
                    <input
                      type="radio"
                      defaultValue="requestAPIMonetizationPlan"
                      name="group2"
                    />
                    &nbsp;&nbsp;
                    <label htmlFor="requestAPIMonetizationPlan">
                      API Monetization Plan
                    </label>
                    <div>
                      You have your own network, station, or large podcast, and
                      you want to take advantage of our dynamic advertising
                      technology. We charge a small fee to use our tech.
                    </div>
                    <br />
                    <input
                      type="radio"
                      id="requestHostedPartnerPlan"
                      defaultValue
                      name="group2"
                    />
                    &nbsp;&nbsp;
                    <label htmlFor="requestHostedPartnerPlan">
                      Hosted Partner Plan
                    </label>
                    <div>
                      You join AudioStaq and bring over all content. This means
                      we host you (you leave your current host), we provide
                      opportunities to monetize(via ads or sponsorships) and
                      market your show, you get to use our sales team,
                      publishing tools, and see your statistics. You remain in
                      full control of your content.
                    </div>
                    <br />
                    <input
                      type="radio"
                      defaultValue="requestPaidStandardHosting"
                      name="group2"
                    />
                    &nbsp;&nbsp;
                    <label htmlFor="requestPaidStandardHosting">
                      Paid Standard Hosting
                    </label>
                    <div>
                      You have your own network, station, or large podcast. We
                      charge a small fee and you get unlimited bandwidth and
                      hosting.
                    </div>
                    <br />
                    <input
                      type="radio"
                      defaultValue
                      name="requestNonhostedPartnerMonetizationPlan"
                    />
                    &nbsp;&nbsp;
                    <label htmlFor="requestNonhostedPartnerMonetizationPlan">
                      Non-hosted Partner Monetization Plan
                    </label>
                    <div>
                      We convert your RSS feed to ours while you stay with your
                      current host provider, we’ll make your show searchable and
                      listenable on our platform. We provide opportunities to
                      monetize(via ads or sponsorships) and market your show,
                      you get to use our sales team, publishing tools, and see
                      your statistics. You remain in full control of your
                      content.
                    </div>
                  </fieldset>
                </div>
                <div className="modal-footer">
                  <div className="row">
                    <button
                      className="btn-gst"
                      id="requestInvite"
                      postfunc="createinvite"
                      callback="dMain.createInviteReturn"
                    >
                      Request Invite
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="container">
          <p>© 2017 AudioStaq. All Rights Reserved.</p>
          <ul className="list-inline" />
        </div>
      </footer>
      {/* jQuery */}
      {/* Bootstrap Core JavaScript */}
      {/* Plugin JavaScript */}
      {/* Theme JavaScript */}
      <input
        type="hidden"
        callback="dMain.tokenReturn"
        postfunc="checkToken"
        id="checkToken"
      />
      <input
        type="hidden"
        callback="dMain.createReturn"
        postfunc="saveUser"
        id="createUser"
      />
      <input
        type="hidden"
        id="pwdReset"
        callback="dMain.pwdResetReturn"
        postfunc="pwdReset"
      />
      {/* Start of HubSpot Embed Code */}
      {/* End of HubSpot Embed Code */}
    </div>
  )
}

import { Slider } from "@material-ui/core"
import withStylesma from "@material-ui/core/styles/withStyles"
export default withStylesma({
  root: {
    color: "#eb3e67",
    height: 8
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -2,
    marginLeft: -10,

    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    marginLeft: 4
  },
  track: {
    background: "linear-gradient(90deg, #f67a34 8.05%, #eb3e67 74.85%)",
    height: 16,
    borderRadius: 8
  },
  rail: {
    height: 16,
    borderRadius: 8,
    backgroundColor: "#343838"
  }
})(Slider)

import React, { useEffect, useState } from "react"
import { Button, CircularProgress } from "@material-ui/core"
import { Link, withRouter } from "react-router-dom"
import { db } from "../firebase"
import EpisodeCard from "../Components/episodeCard"
import { useDocument, useCollection } from "react-firebase-hooks/firestore"
import SearchInput from "react-search-input"

import InfiniteScroll from "react-infinite-scroller"

function Show(props) {
  const { classes } = props

  const showRef = db.doc(`shows/${props.match.params.name}`)
  const [search, setSearch] = useState("")
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(0)

  const { value: showData } = useDocument(showRef)
  const { value: episodesData } = useCollection(
    showRef.collection("episodes").orderBy("publishTime", "desc")
  )

  useEffect(() => {
    if (!showData) return
    props.setTitle(`Show ${showData.data().name}`)
  }, [showData])

  useEffect(() => {}, [episodesData])

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Link to={`/dashboard/show/${props.match.params.name}/episode/create`}>
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "rgb(103, 58, 183)",
              height: 40,
              width: 200
            }}
          >
            {" "}
            Create Episode{" "}
          </Button>{" "}
        </Link>
        <SearchInput
          style={{
            display: "flex",
            height: "100%"
          }}
          className="search-input"
          onChange={setSearch}
        />
      </div>

      {showData && episodesData && (
        <InfiniteScroll
          pageStart={0}
          useWindow={true}
          loadMore={page => {
            setPage(page)
            let newpages = episodesData.docs.slice(25 * (page - 1), 25 * page)
            if (newpages.length < 25) setHasMore(false)
          }}
          hasMore={hasMore}
          loader={
            <div id="loader2" style={{ padding: 30 }}>
              <CircularProgress />
            </div>
          }
        >
          {episodesData.docs.slice(0, 25 * page).map(doc => (
            <div>
              <EpisodeCard
                show={props.match.params.name}
                showData={showData && showData.data()}
                episode={doc}
                classes={classes}
              />
            </div>
          ))}
        </InfiniteScroll>
      )}
    </div>
  )
}

export default withRouter(Show)

import React from "react"
import { Typography } from "@material-ui/core"
export default props => (
  <div
    {...props.getRootProps({ className: "dropzone" })}
    style={{
      backgroundColor: "#c8dadf",
      outline: "2px dashed #92b0b3",
      outlineOffset: "-10px",
      padding: "40px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}
  >
    <input {...props.getInputProps()} />
    <Typography component="h3" variant="h5">
      {props.title}
    </Typography>
    <svg
      className="box__icon"
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="43"
      viewBox="0 0 50 43"
      style={{
        width: "100%",
        height: "80px",
        fill: "#92b0b3",
        display: "block",

        marginBottom: "20px"
      }}
    >
      <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z" />
    </svg>
    <p>
      <b>Choose a file</b> or drag it here.
    </p>
  </div>
)

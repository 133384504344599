import { Button, CircularProgress, Grid } from "@material-ui/core"
import React, { useEffect, useState } from "react"

import firebase, { functions } from "../firebase"
import { withRouter } from "react-router-dom"
import ReactChartkick, { AreaChart, ColumnChart } from "react-chartkick"
import Chart from "chart.js"

import MiniWidget from "../Components/miniWidget"
import Widget from "../Components/widget"
import ListenersTableWidget from "../Components/ListenersTableWidget"
import {
  faHeadphones,
  faCloudDownloadAlt,
  faUsers,
  faDollarSign
} from "@fortawesome/free-solid-svg-icons"

ReactChartkick.addAdapter(Chart)
function Dashboard(props) {
  const { classes } = props
  const [analyticsData, setAnalyticsData] = useState()
  const getAnalytics = functions.httpsCallable("getDashboardAnalytics")
  const [isLoading, setIsLoading] = useState(true)
  let dates = []
  for (var i = 0; i < 7; i++) {
    let newDate = new Date(Date.now() - 86400000 * i)
    dates.push(
      newDate
        .toISOString()
        .split("T")[0]
        .replace(/-/g, "")
    )
  }
  const fetchData = async () => {
    let data = await getAnalytics()

    setIsLoading(false)
    setAnalyticsData(data)
    localStorage.setItem("dashboardAnalytics", JSON.stringify(data))
  }
  useEffect(() => {
    let anlc = JSON.parse(localStorage.getItem("dashboardAnalytics"))

    if (anlc) {
      setAnalyticsData(anlc)
    }

    fetchData()

    /*    setAnalyticsData({
      data: {
        listeners: 1,
        rssHits: 0,
        playerPlays: 0,
        downloads: 2,
        listenersOverTime: { "20190717": 1 },
        yearlyListeners: {
          July: 1,
          June: 0,
          May: 0,
          April: 0,
          March: 0,
          February: 0,
          January: 0,
          December: 0,
          November: 0,
          October: 0,
          September: 0,
          August: 0
        },
        topEpisodes: [
          {
            name:
              "(11) How to Design Your Life While You Empower Others with Xiomara Rosa-Tedla",
            id: "ep_1",
            pagePath: "/DO0t17XpNPtfgpMITu3I/ep_1.mp3",
            users: 1
          },
          {
            name:
              "(11) How to Design Your Life While You Empower Others with Xiomara Rosa-Tedla",
            id: "ep_1",
            pagePath: "/l3e2dFKlKHXif7zBtG4S/ep_1.mp3",
            users: 1
          }
        ]
      }
    })*/
    props.setTitle(`Hello ${firebase.getCurrentUsername()}`)
  }, [])

  if (!analyticsData)
    return (
      <div id="loader2">
        <CircularProgress />
      </div>
    )

  return (
    <div>
      {isLoading ? (
        <div id="loader3">
          <CircularProgress />
        </div>
      ) : (
        ""
      )}

      <Grid
        container
        spacing={5}
        style={{ paddingTop: 10 }}
        style={{
          filter: !analyticsData.data.downloads ? "blur(5px)" : ""
        }}
      >
        <MiniWidget
          title={"Downloads"}
          icon={faCloudDownloadAlt}
          data={analyticsData.data.downloads || 0}
        />

        <MiniWidget
          title={"Listeners"}
          icon={faUsers}
          data={analyticsData.data.listeners || 0}
        />

        <MiniWidget
          title={"Embed Plays"}
          icon={faHeadphones}
          data={analyticsData.data.playerPlays || 0}
        />

        <MiniWidget icon={faDollarSign} title="Revenue" data={"$0.00"} />
        <Widget
          title={"Averege Downloads"}
          classes={props.classes}
          fullWidth={true}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              textAlign: "center",
              flexWrap: "wrap"
            }}
          >
            {analyticsData.data.avgFirstDownloads
              ? analyticsData.data.avgFirstDownloads.map((item, i) => (
                  <div>
                    <div> First {[7, 30, 60, 90][i]} Days </div>
                    <div>
                      <b>
                        {item} Downloads
                        <span
                          style={{
                            color:
                              (analyticsData.data.avgLastDownloads[i] - item) /
                                item >
                              0
                                ? "green"
                                : "red"
                          }}
                        >
                          {(analyticsData.data.avgLastDownloads[i] - item) /
                            item >
                          0
                            ? "▲"
                            : "▼"}
                          {Math.abs(
                            Math.round(
                              ((analyticsData.data.avgLastDownloads[i] - item) /
                                item) *
                                100
                            )
                          )}
                          {"%"}
                        </span>
                      </b>
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </Widget>
        <Widget title="Weekly Recap">
          <AreaChart
            data={dates.reduce((sum, item) => {
              if (!analyticsData.data || !analyticsData.data.listenersOverTime)
                sum[item] = 0
              else sum[item] = analyticsData.data.listenersOverTime[item] || 0
              return sum
            }, {})}
            dataset={{ label: "Downloads", allowDecimals: false }}
          />
        </Widget>

        <Widget
          title={
            "Monthly Recap" +
            (analyticsData.data && analyticsData.data.yearlyListeners
              ? " - " + Object.values(analyticsData.data.yearlyListeners)[11]
              : "")
          }
        >
          <ColumnChart
            data={analyticsData.data.yearlyListeners}
            dataset={{ label: "Downloads" }}
          />
        </Widget>

        <ListenersTableWidget
          title="Trending Episodes"
          topEpisodes={analyticsData.data.topEpisodes || []}
        />
      </Grid>
      {!analyticsData.data.downloads ? (
        <h1
          style={{
            position: "fixed" /* or absolute */,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          No Data Found
        </h1>
      ) : (
        ""
      )}
    </div>
  )

  async function logout() {
    await firebase.logout()
    props.history.push("/")
  }
}

export default withRouter(Dashboard)

import React, { useState, useEffect, useRef } from "react"
import {
  FormControl,
  InputLabel,
  Checkbox,
  MenuItem,
  Hidden,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  TextField,
  Paper,
  Typography
} from "@material-ui/core"
import { Link, withRouter } from "react-router-dom"
import { fieldValidatorCore } from "react-validation-framework"
import clean from "../Shared/clean.js"
import moment from "moment"
import AdSlotEditor from "../Components/adSlotEditor"

import { auth, db, uploads } from "../firebase"
import FileUploader from "../Components/uploader.js"
import encodeurl from "encodeurl"
import { fieldsToForm, submitButton } from "../App/Layouts/FormRoute"
import ImageUploader from "../Components/imageUploader.js"

function CreateEpisode(props) {
  const theme = { spacing: { unit: 4 } }
  const classes = {
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: `40px`,
      paddingRight: `40px`,
      paddingTop: `10px`,
      paddingBottom: `10px`
    },
    avatar: {
      margin: theme.spacing.unit
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing.unit
    },
    submit: {
      marginTop: theme.spacing.unit * 3
    }
  }
  //  const { classes } = props

  const [name, setName] = useState("")
  const [upload, setUpload] = useState("")
  const [description, setDescription] = useState("")
  const [episodeUrl, setEpisodeUrl] = useState("")
  const [hasImage, setHasImage] = useState(false)
  const [explicit, setExplicit] = useState(false)
  const [episodeType, setEpisodeType] = useState("full")
  const [season, setSeason] = useState(1)
  const [episodeNumber, setEpisodeNumber] = useState(0)
  const [publishLater, setPublishLater] = useState(false)
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"))
  const [hide, setHide] = useState(false)
  const [slots, setSlots] = useState([])
  const [showSlots, setShowSlots] = useState([])
  const [episodeNames, setEpisodeNames] = useState([])
  const [file, setFile] = useState("")
  let allepnames = []
  let curepname = ""
  async function fetchAllEpisodes() {
    let episodes = await db
      .collection(`shows/${props.match.params.showName}/episodes/`)
      .get()

    allepnames = episodes.docs.map(doc => doc.id)
    setEpisodeNames(allepnames)
    if (!props.match.params.episodeName && curepname) {
      let ma = getEpisodeName(curepname)

      setEpisodeUrl(ma)
    }
  }

  function getEpisodeName(mid) {
    let midt = mid
      .toLowerCase()
      .replace(/%20/g, "_")
      .replace(/[^a-z0-9_]/g, "")
      .substr(0, 15)
    mid = midt
    let id = 1
    if (allepnames.length > 0) {
      while (allepnames.indexOf(mid) != -1) {
        mid = midt + "_" + id
        id++
      }
    }

    curepname = mid
    return mid
  }

  async function fetchData() {
    let episodeRef = db.doc(
      `shows/${props.match.params.showName}/episodes/${props.match.params.episodeName}`
    )
    let episodeDoc = await episodeRef.get()
    let episodeData = episodeDoc.data()

    setIsEditing(true)
    setName(episodeData.name)

    setDescription(episodeData.description)
    setEpisodeUrl(props.match.params.episodeName)
    setUpload(episodeData.upload)
    setExplicit(episodeData.explicit)
    setDescription(episodeData.description)

    setEpisodeType(episodeData.episodeType)
    setSeason(episodeData.season)
    setEpisodeNumber(episodeData.episodeNumber)
    setPublishLater(episodeData.publishLater)
    setPublishLater(episodeData.publishLater)
    setDate(moment(episodeData.publishTime.toDate()).format("YYYY-MM-DDTHH:mm"))
    setHide(episodeData.hide)
    setSlots(episodeData.slots)

    setUploadedFileUrl(episodeData.uploadedFileUrl)
    setFile(episodeData.uploadedFileUrl)
    setCreateDisabled(false)

    if (episodeData.hasImage)
      setCroppedCanvas({
        toDataURL: () =>
          `https://${process.env.REACT_APP_IMAGE_URL}/` +
          props.match.params.showName +
          "/" +
          props.match.params.episodeName +
          ".jpg",
        cantEdit: true
      })
    //  setDate(episodeData.date)
    //publishTime

    //setCategories(props.match.params.categories)
  }

  const [show] = useState(
    db.collection("shows").doc(props.match.params.showName)
  )
  const [createDisabled, setCreateDisabled] = useState(true)
  const [croppedCanvas, setCroppedCanvas] = useState(false)
  const imageUploaderRef = useRef(null)
  useEffect(() => {
    if (props.match.params.episodeName) {
      db.doc(`shows/${props.match.params.showName}`)
        .get()
        .then(showDoc => {
          setSlots(showDoc.data().slots)
          setShowSlots(showDoc.data().slots)
          fetchData()
        })
    } else {
      db.doc(`shows/${props.match.params.showName}`)
        .get()
        .then(showDoc => {
          setSlots(showDoc.data().slots)
          setShowSlots(showDoc.data().slots)
          fetchAllEpisodes()
        })
    }
  }, [show])
  useEffect(() => {
    props.setTitle(
      <React.Fragment>
        <Link to={"/dashboard/show/" + props.match.params.showName}>
          {props.match.params.showName}
        </Link>
        {" - "}
        {props.match.params.episodeName ? "Edit" : "Create"} Episode
      </React.Fragment>
    )
  }, [])

  let fields = [
    {
      validators: [
        {
          validator: val => !val === false,
          errorPropValue: true,
          errorMessage: "Please enter a value"
        }
      ],
      required: true,
      title: "Name",
      value: name,
      id: "name",
      onChange: e => {
        setName(e.target.value)
        if (!props.match.params.episodeName) {
          setEpisodeUrl(getEpisodeName(encodeurl(e.target.value)))
        }
      }
    },
    {
      validators: [
        {
          validator: val => !val === false,
          errorPropValue: true,
          errorMessage: "Please enter a value"
        },
        {
          validator: val => new RegExp(/^[a-z0-9_]*$/g).test(val),
          errorMessage: "only lowercase letters and underscores"
        },

        {
          validator: val => val.length > 5,
          errorPropValue: true,
          errorMessage: "Must be Over 5 characters"
        },
        {
          validator: val => val.length < 30,
          errorPropValue: true,
          errorMessage: "Must be under 30 characters"
        },
        {
          validator: val => {
            return (
              props.match.params.episodeName || episodeNames.indexOf(val) == -1
            )
          },
          errorPropValue: true,
          errorMessage: "ID already exists, please choose another"
        }
      ],
      title: "episode ID",
      value: episodeUrl,
      id: "episodeUrl",
      onChange: e => setEpisodeUrl(e.target.value),
      disabled: props.match.params.episodeName
    },

    {
      title: "Description",
      value: description,
      id: "description",
      onChange: e => setDescription(e.target.value),
      multiline: true
    },
    {
      validators: [
        {
          validator: val => (val ? val >= 0 : true),
          errorPropValue: true,
          errorMessage: "Can't be below 0"
        }
      ],
      type: "number",
      title: "Episode Number",
      value: episodeNumber,
      id: "episodeNumber",
      onChange: e => {
        setEpisodeNumber(e.target.value)
      }
    }
  ]
  const submitButtonParams = {
    onClick: addEpisode,
    disabled: createDisabled,
    text: props.match.params.episodeName ? "Update" : "Create"
  }

  return (
    <form
      className={classes.form}
      onSubmit={e => e.preventDefault() && false}
      style={{
        paddingLeft: "30px",
        paddingBottom: "80px",
        paddingRight: "30px",
        overflow: "hidden",
        flexGrow: 1
      }}
    >
      <Grid
        container
        spacing={5}
        style={{ paddingTop: 10, justify: "space-between" }}
      >
        <Grid item sm={6} xs={12}>
          <Paper style={classes.paper}>
            {fieldsToForm(fields)}
            <hr />
            <FormControl
              margin="normal"
              style={{ width: "100%" }}
              key={"explicit"}
              variant="outlined"
            >
              <FormControlLabel
                value="Explicit"
                label="Explicit"
                control={
                  <Checkbox
                    checked={explicit}
                    onChange={event => setExplicit(event.target.checked)}
                  />
                }
              />
            </FormControl>

            <TextField
              select
              variant="outlined"
              fullWidth
              label="Season"
              margin="normal"
              onChange={event => {
                setSeason(event.target.value)
              }}
              value={season}
            >
              {Array(10)
                .fill()
                .map((_, i) => (
                  <MenuItem value={i + 1} key={"menuitem " + i}>
                    {i + 1}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              select
              variant="outlined"
              fullWidth
              margin="normal"
              label="Episode Type"
              onChange={event => {
                setEpisodeType(event.target.value)
              }}
              value={episodeType}
            >
              <MenuItem value={"full"}>Full</MenuItem>
              <MenuItem value={"trailer"}>Trailer</MenuItem>
              <MenuItem value={"bonus"}>Bonus</MenuItem>
            </TextField>
            <div style={{ padding: 20 }}>
              <FileUploader
                style={{ paddingTop: 30, paddingBottom: 10 }}
                transcode
                accept="audio/*"
                show={show}
                storageRef={uploads.ref()}
                onUploadStart={(file, task, filenameToUse) => {
                  setFile(window.URL.createObjectURL(file))
                  setUpload(db.doc(`transcodeJobs/${filenameToUse}`))
                  setUploadedFileUrl(null)
                }}
                onUploadSuccess={() => setCreateDisabled(false)}
                onUploadError={p => alert(JSON.stringify(p))}
              />
            </div>
            <Hidden smUp>
              <ImageUploader
                ref={imageUploaderRef}
                title="Episode Image"
                hasUpload={hasImg => setHasImage(hasImg)}
                uploadFinished={() =>
                  props.history.push(
                    `/dashboard/show/${props.match.params.showName}/episode/${episodeUrl}`
                  )
                }
                onSave={setCroppedCanvas}
                croppedCanvas={croppedCanvas}
                onDelete={() => setCroppedCanvas(null)}
              />
            </Hidden>
            <FormControl margin="normal" fullWidth key={"Publish Later"}>
              <FormLabel component="legend">Publish Time</FormLabel>
              <RadioGroup
                aria-label="Publish Later"
                name="publishLater"
                className={classes.group}
                value={publishLater ? "later" : "now"}
                onChange={event => {
                  setPublishLater(event.target.value === "later")
                }}
              >
                <FormControlLabel value="now" control={<Radio />} label="now" />
                <FormControlLabel
                  value="later"
                  control={<Radio />}
                  label="later"
                />
              </RadioGroup>
            </FormControl>

            <TextField
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              id="datetime-local"
              label="Publish Time"
              type="datetime-local"
              disabled={!publishLater}
              value={date}
              required
              onChange={e => {
                setDate(e.target.value)
              }}
            />

            <div style={{ width: "100%", marginTop: 10 }}>
              <AdSlotEditor
                midRoll
                value={slots}
                previewFile={file}
                onChange={value => setSlots(value)}
                style={{ textAlign: "center" }}
              />
            </div>
            <FormControl margin="normal" style={{ width: "100%" }} key={"hide"}>
              <FormControlLabel
                value="Hide"
                label="Hide"
                control={
                  <Checkbox
                    checked={hide}
                    onChange={event => setHide(event.target.checked)}
                  />
                }
              />
            </FormControl>
            {submitButton(submitButtonParams)}
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12} style={{ width: "100%", marginTop: 4 * 8 }}>
          <Hidden xsDown>
            <ImageUploader
              ref={imageUploaderRef}
              hasUpload={hasImg => setHasImage(hasImg)}
              uploadFinished={() =>
                props.history.push(
                  `/dashboard/show/${props.match.params.showName}/episodes/${episodeUrl}`
                )
              }
              title="Episode Image"
              onSave={setCroppedCanvas}
              croppedCanvas={croppedCanvas}
              onDelete={() => setCroppedCanvas(null)}
            />
          </Hidden>
        </Grid>
      </Grid>
    </form>
  )

  async function addEpisode() {
    try {
      let checkFieldTestResult = fieldValidatorCore.checkGroup("formGroup")

      if (!checkFieldTestResult.isValid) {
        window.scrollTo(
          0,
          document.getElementById(
            checkFieldTestResult.inValidComponents[0].props.id.offsetTop
          )
        )
        return
        //  window.scrollTo(0, domc.offsetTop)
      }
      let episode = db
        .collection("shows")
        .doc(props.match.params.showName)
        .collection("episodes")
        .doc(episodeUrl)
      let newUploadUrl = null
      let curep = await episode.get()
      if (upload.get) {
        let transcodeDoc = await upload.get()

        if (
          !props.match.params.episodeName &&
          transcodeDoc.data().processing_state == "TRANSCODE_FINISHED"
        ) {
          newUploadUrl =
            "https://audiostaq.b-cdn.net/" + transcodeDoc.id + ".mp3"
        }
      }
      if (curep.exists && !props.match.params.episodeName) {
        alert("ID already exists, please choose a new ID.")
        return
      }
      await episode.set(
        clean({
          name,
          description,
          upload,
          hasImage: Boolean(croppedCanvas),
          episodeType,
          explicit,
          hide,
          season,
          episodeNumber,
          uploadedFileUrl:
            uploadedFileUrl == null && newUploadUrl
              ? newUploadUrl
              : uploadedFileUrl,
          slots:
            JSON.stringify(showSlots) != JSON.stringify(slots) ? slots : null,
          creator: auth.currentUser.uid,
          publishTime: moment(date, "YYYY-MM-DDThh:mm z").toDate(),

          publishLater
        }),
        { merge: true }
      )

      if (hasImage) {
        imageUploaderRef.current.startUpload(
          `${props.match.params.showName}/${episodeUrl}`
        )
      } else {
        props.history.push(
          `/dashboard/show/${props.match.params.showName}/episodes/${episodeUrl}`
        )
      }
    } catch (error) {
      alert(error.message)
    }
  }
}

export default withRouter(CreateEpisode)

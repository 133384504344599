import { Button } from "@material-ui/core"
import React from "react"

import { withRouter, Link } from "react-router-dom"
import firebase, { db } from "../firebase"
import NetworkCard from "../Components/networkCard"
import { useCollection } from "react-firebase-hooks/firestore"
import { CSSGrid, makeResponsive, measureItems } from "react-stonecutter"
const Grid = makeResponsive(measureItems(CSSGrid), {
  maxWidth: 1920,
  minPadding: 100
})
function Networks(props) {
  const { classes } = props

  const { value: networks } = useCollection(
    db
      .collection("networks")
      .where("users", "array-contains", firebase.userRef().id)
  )
  /*
const deleteNetwork = async id => {
  await db.doc(`networks/${id}`).delete()
}*/

  props.setTitle(`Networks`)

  return (
    <div>
      <main className={classes.main}>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <Link to={"/dashboard/networks/create"}>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "rgb(103, 58, 183)",
                height: 50,
                width: 350,
                fontSize: 20,
                margin: "0 10px 30px 0"
              }}
            >
              Create Network
            </Button>
          </Link>
        </div>
        {networks && (
          <Grid
            component="ul"
            columns={5}
            columnWidth={300}
            gutterWidth={20}
            gutterHeight={20}
            itemHeight={300}
            springConfig={{ stiffness: 170, damping: 26 }}
            style={{ margin: "0 auto" }}
          >
            {networks.docs.map(doc => (
              <li key={doc.id}>
                <NetworkCard network={doc} classes={classes} />
              </li>
            ))}
          </Grid>
        )}
      </main>
    </div>
  )
}

export default withRouter(Networks)

import React, { useEffect, useState } from "react"
import generateRandomID from "uuid/v4"
import { db } from "../firebase"

import { useDropzone } from "react-dropzone"

import Drop from "./drop.js"
import { LinearProgress } from "@material-ui/core"
export default props => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "audio/*"
  })
  const [hasUpload, setHasUpload] = useState(false)
  const [progress, setProgress] = useState(0)
  let uploadTask
  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      startUpload(acceptedFiles[0])
    }
  }, [acceptedFiles])

  let startUpload = async file => {
    const {
      onUploadStart,
      onProgress,
      onUploadError,
      onUploadSuccess,
      storageRef,
      metadata,
      randomizeFilename,
      filename,
      transcode,
      show
    } = props
    setHasUpload(false)
    if (uploadTask) uploadTask.cancel()
    const generateRandomFilename = () => generateRandomID()

    function extractExtension(filename) {
      let ext = /(?:\.([^.]+))?$/.exec(filename)
      if (ext != null && ext[0] != null) {
        return ext[0]
      } else {
        return ""
      }
    }
    let filenameToUse
    if (filename) {
      filenameToUse = typeof filename === "function" ? filename(file) : filename
    } else {
      filenameToUse = randomizeFilename ? generateRandomFilename() : file.name
    }
    if (!extractExtension(filenameToUse)) {
      filenameToUse += extractExtension(file.name)
    }

    if (transcode) {
      let transcodeJob = await db.collection("transcodeJobs").add({
        processing_state: "AWAITING_UPLOAD",
        passToCallback: {
          show: show
        }
      })
      filenameToUse = transcodeJob.id
    }

    uploadTask = storageRef.child(filenameToUse).put(file, metadata)

    if (onUploadStart) {
      onUploadStart(file, uploadTask, filenameToUse)
    }

    uploadTask.on(
      "state_changed",
      snapshot => {
        var progressValue =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        onProgress && onProgress(progressValue, uploadTask)
        setProgress(progressValue)
      },
      error => onUploadError && onUploadError(error, uploadTask),
      () => {
        setHasUpload(true)
        return (
          onUploadSuccess &&
          onUploadSuccess(uploadTask.snapshot.metadata.name, uploadTask)
        )
      }
    )
  }

  return (
    <div>
      <Drop
        title={props.title || "Episode Audio"}
        acceptedFiles={acceptedFiles}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
      />
      <LinearProgress variant="determinate" value={progress} />
      {hasUpload ? (
        <div
          style={{
            backgroundColor: "#c8dadf",
            outline: "2px solid #92b0b3",
            outlineOffset: "-10px",
            padding: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          Completed Upload{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

import {
  Typography,
  Button,
  Paper,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import {
  faHeadphones,
  faCloudDownloadAlt,
  faRss,
  faUsers
} from "@fortawesome/free-solid-svg-icons"
import { functions } from "../firebase"
import { withRouter } from "react-router-dom"
import Widget from "../Components/widget"
import MiniWidget from "../Components/miniWidget"
import ListenersTableWidget from "../Components/ListenersTableWidget"
import ReactChartkick, {
  ColumnChart,
  PieChart,
  AreaChart
} from "react-chartkick"
import Chart from "chart.js"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { DateRangePicker } from "react-date-range"
import moment from "moment"
import { useCollection } from "react-firebase-hooks/firestore"
import { auth, db } from "../firebase"
import { useDocument } from "react-firebase-hooks/firestore"

ReactChartkick.addAdapter(Chart)
function Stats(props) {
  const { classes } = props
  const [analyticsData, setAnalyticsData] = useState()
  const campaignRef = db.doc(`campaigns/${props.match.params.campaignId}`)

  const { value: campaignData } = useDocument(campaignRef)

  const [isLoading, setIsLoading] = useState(true)
  const [startDate, setStartDate] = useState(
    moment()
      .subtract("6", "days")
      .toDate()
  )
  const [endDate, setEndDate] = useState(new Date())

  const getAnalytics = functions.httpsCallable("getCampaignStats")
  let refresh = () => {
    setIsLoading(false)

    getAnalytics({
      startDate: moment("1996").format("YYYY-MM-DD"),
      endDate: moment("2090").format("YYYY-MM-DD"),

      campaign: props.match.params.campaignId
    }).then(data => {
      setAnalyticsData(data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    props.setTitle(`Stats`)
    refresh()
  }, [])

  if (!analyticsData || !analyticsData.data)
    return (
      <div id="loader2">
        <CircularProgress />
      </div>
    )
  if (
    analyticsData &&
    analyticsData.data &&
    analyticsData.data.impressions <= 0
  )
    return <div id="loader2">No Data Found</div>

  return (
    <div>
      {isLoading ? (
        <div id="loader3">
          <CircularProgress />
        </div>
      ) : (
        ""
      )}
      <Grid container spacing={5} style={{ paddingTop: 10 }}>
        <MiniWidget
          title={"Impressions"}
          icon={faCloudDownloadAlt}
          data={analyticsData.data.impressions}
        />
        <MiniWidget
          title={"Reach"}
          icon={faUsers}
          data={analyticsData.data.reach}
        />
        <MiniWidget
          title={"Target"}
          icon={faHeadphones}
          data={campaignData && campaignData.data().impressionGoal}
        />
        <MiniWidget
          icon={faRss}
          title="Budget"
          data={campaignData && campaignData.data().budget}
        />
        <MiniWidget
          icon={faRss}
          title="Spend"
          data={
            campaignData &&
            (
              (campaignData.data().cpm / 1000) *
              analyticsData.data.impressions
            ).toFixed(2)
          }
        />
        <MiniWidget
          icon={faRss}
          title="Avg Daily Spend"
          data={
            analyticsData &&
            (
              (campaignData.data().cpm / 1000) *
              (Object.values(analyticsData.data.impressionsOverTime).reduce(
                (sum, impression) => sum + impression,
                0
              ) /
                Object.values(analyticsData.data.impressionsOverTime).length)
            ).toFixed(2)
          }
        />
        {console.log(campaignData.data().startDate)}
        <MiniWidget
          icon={faRss}
          title="Start Spend Date"
          data={moment(campaignData.data().startDate.toDate()).format("ll")}
        />
        <MiniWidget
          icon={faRss}
          title="Forcasted Spend Date"
          data={moment()
            .add(
              campaignData.data().budget /
                (((campaignData.data().cpm / 1000) *
                  Object.values(analyticsData.data.impressionsOverTime).reduce(
                    (sum, impression) => sum + impression,
                    0
                  )) /
                  Object.values(analyticsData.data.impressionsOverTime).length),

              "days"
            )
            .format("ll")}
        />

        <Paper
          title="Daily Requests"
          style={{
            width: "100%",
            marginTop: 4 * 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: `${4 * 2}px ${4 * 3}px ${4 * 3}px`
          }}
        >
          <Typography component="h1" variant="h5">
            Daily Requests
          </Typography>

          <ColumnChart
            data={[
              {
                name: "impressions",
                data: analyticsData.data.impressionsOverTime
              },
              {
                name: "spend",
                data: Object.entries(
                  analyticsData.data.impressionsOverTime
                ).reduce((allData, data) => {
                  allData[data[0]] = (
                    (campaignData.data().cpm / 1000) *
                    data[1]
                  ).toFixed(2)
                  return allData
                }, {})
              }
              /*  Object.keys(myObject).map(function(key, index) {
                myObject[key] *= 2
              })*/
            ]}
            dataset={[{ label: "Impressions" }, { label: "Spend" }]}
          />
        </Paper>

        <Widget
          title={"Show Distribution Breakdown"}
          classes={props.classes}
          fullWidth={true}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Publish Date</TableCell>
                <TableCell align="left">Episode Name</TableCell>
                <TableCell align="right">Impressions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {analyticsData.data.topEpisodes.map((episode, index) => (
                <TableRow key={"tableI" + index}>
                  <TableCell component="th" scope="row" width="9%">
                    {moment(episode.pubDate).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell align="left">{episode.name}</TableCell>
                  <TableCell align="right">{episode.totalEvents}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Widget>
      </Grid>
      <Grid container spacing={5}>
        <Widget class={classes} title="Top Cities">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">City</TableCell>
                <TableCell align="right">Impressions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {analyticsData.data.cities.map((city, index) => (
                <TableRow key={"tableI" + index}>
                  <TableCell component="th" scope="row">
                    {city.city}
                  </TableCell>
                  <TableCell align="right">{city.totalEvents}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Widget>
        <Widget class={classes} title="Top Apps">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Browser</TableCell>
                <TableCell align="right">Impressions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {analyticsData.data.browsers.map((browser, index) => (
                <TableRow key={"tableI" + index}>
                  <TableCell component="th" scope="row">
                    {browser.browser}
                  </TableCell>
                  <TableCell align="right">{browser.totalEvents}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Widget>
      </Grid>
    </div>
  )
}

export default withRouter(Stats)

import React from "react"

import { Typography, Paper, Grid } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const useStyles = {
  paper: {
    borderRadius: "8px"
  },
  iconLeftWidget: {
    padding: "20px",
    backgroundColor: "#512e90",
    color: "white"
  },
  iconRightWidget: {
    padding: "20px",
    backgroundColor: "rgb(103, 58, 183)",
    color: "white"
  },
  icon: {
    width: "inherit",
    height: "50px"
  }
}
export default props => {
  const classes = useStyles
  return (
    <Grid item sm={!props.gridSize ? 6 : 12} xs={12} md={props.gridSize || 3}>
      <Paper style={classes.paper}>
        <Typography component="h3" variant="body1">
          <Grid container spacing={0}>
            <Grid item xs={5} style={classes.iconLeftWidget}>
              <FontAwesomeIcon style={classes.icon} icon={props.icon} />
            </Grid>
            <Grid item container xs={7} style={classes.iconRightWidget}>
              <Grid item xs container direction="column" spacing={0}>
                <div style={{ whiteSpace: "nowrap" }}>{props.title}</div>
                <div>
                  <b>{props.data}</b>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Typography>
      </Paper>
    </Grid>
  )
}

import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/functions"
import "firebase/storage"

import { useDocument } from "react-firebase-hooks/firestore"

let config
if (
  process.env.REACT_APP_SERVER == "audiostaqdev.xyz" ||
  process.env.REACT_APP_SERVER == "audiostaq.com"
) {
  config = require("../firebase_config_prod.json")
} else {
  config = require("../firebase_config_develop.json")
}

export const firebaseApp = firebase.initializeApp(config)
export const auth = firebaseApp.auth()
export const db = firebaseApp.firestore()
export const functions = firebaseApp.functions()
export const fieldValue = firebase.firestore.FieldValue

if (process.env.NODE_ENV === "development")
  functions.useFunctionsEmulator("http://localhost:5001")
export const uploads = firebaseApp.storage(
  "gs://" + process.env.REACT_APP_UPLOAD_BUCKET
)
let adUploadBucket = "gs://audiostaq_transcoded_ads"
if (process.env.REACT_APP_SERVER == "audiostaqdev2.xyz") {
  adUploadBucket = "gs://audiostaq_transcoded_ads2"
}
export const adUploads = firebaseApp.storage(adUploadBucket)
export const images = firebaseApp.storage(process.env.REACT_APP_IMAGE_URL)
class Firebase {
  static login(email, password) {
    localStorage.clear()
    return auth.signInWithEmailAndPassword(email, password)
  }

  static logout() {
    localStorage.clear()
    return auth.signOut()
  }

  static async register(name, email, password, paymentMethod) {
    localStorage.clear()
    await auth.createUserWithEmailAndPassword(email, password)
    await db.doc(`users/${auth.currentUser.uid}`).set({
      displayName: name,
      email,
      stripeCardToken: paymentMethod.id
    })
    return auth.currentUser.updateProfile({
      displayName: name
    })
  }

  static isInitialized() {
    return new Promise(resolve => {
      auth.onAuthStateChanged(resolve)
    })
  }

  static getCurrentUsername() {
    return auth.currentUser && auth.currentUser.displayName
  }
  static userRef() {
    return db.doc(`users/${auth.currentUser.uid}`)
  }
  static userData() {
    return useDocument(this.userRef())
  }
}

export default Firebase

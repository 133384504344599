import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect
} from "react"
import { images } from "../firebase"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import Slider from "@material-ui/core/Slider"
import { Button, Paper } from "@material-ui/core"
import Drop from "./drop.js"
import Modal from "@material-ui/core/Modal"
import { useDropzone } from "react-dropzone"
const ImageUploader = forwardRef((props, ref) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone()

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      if (props.hasUpload) props.hasUpload(true)
      const reader = new FileReader()
      reader.addEventListener("load", () => setFileUpload(reader.result))
      reader.readAsDataURL(acceptedFiles[0])
      setZoom(50)
      setModalOpen(true)
    }
  }, [acceptedFiles])

  useEffect(() => {
    if (props.croppedCanvas && props.croppedCanvas.toDataURL) {
      setCroppedCanvas(props.croppedCanvas)
      setDataUrl(props.croppedCanvas.toDataURL())
    }
  }, [props.croppedCanvas])

  const [fileUpload, setFileUpload] = useState(null)
  const [zoom, setZoom] = useState(50)
  const [modalOpen, setModalOpen] = useState(false)
  const { uploadFinished } = props
  const [dataUrl, setDataUrl] = useState(null)
  const [croppedCanvas, setCroppedCanvas] = useState(null)

  /*
   */

  let cropper
  useImperativeHandle(ref, () => ({
    async startUpload(uploadTo) {
      if (croppedCanvas) {
        croppedCanvas.toBlob(async blob => {
          try {
            await images
              .ref()
              .child(uploadTo + ".jpg")
              .put(blob)
          } catch (e) {
            alert(e)
          }
          if (uploadFinished) uploadFinished()
        }, "image/jpeg")
      }
    }
  }))
  return (
    <Paper>
      {dataUrl ? (
        <div>
          <img
            alt=""
            src={dataUrl}
            style={{
              width: "100%",
              height: "100%"
            }}
          />

          {!croppedCanvas ||
            (!croppedCanvas.cantEdit && (
              <Button
                onClick={() => {
                  setModalOpen(true)
                }}
              >
                Edit
              </Button>
            ))}
          <Button
            onClick={() => {
              setCroppedCanvas(null)
              setDataUrl(null)
              if (props.onDelete) props.onDelete()
            }}
          >
            {"Delete Image"}
          </Button>
        </div>
      ) : (
        <Drop
          title={props.title}
          acceptedFiles={acceptedFiles}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
        />
      )}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalOpen}
      >
        <Paper
          style={{
            width: "500px",
            marginTop: "100px",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "40px"
          }}
        >
          <Cropper
            src={fileUpload}
            style={{ height: 400, width: "100%" }}
            // Cropper.js options
            ref={cropperref => {
              cropper = cropperref
            }}
            dragMode="move"
            autoCrop
            autoZoom
            rotatable
            checkOrientation
            cropBoxMovable={false}
            aspectRatio={1}
            guides={false}
            viewMode={1}
            zoomTo={zoom / 50}
          />
          <br />
          <Slider
            value={zoom}
            aria-labelledby="label"
            onChange={(event, value) => setZoom(value)}
          />
          <div style={{ paddingTop: "20px" }}>
            <Button
              onClick={() => {
                setFileUpload(null)
                if (props.hasUpload) props.hasUpload(false)
                setModalOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (cropper) {
                  let croppedC = cropper.getCroppedCanvas({
                    width: 1400,
                    height: 1400
                  })
                  setDataUrl(croppedC.toDataURL())
                  setCroppedCanvas(croppedC)

                  if (props.onSave) props.onSave(croppedC)
                }
                setModalOpen(false)
              }}
            >
              Save
            </Button>
          </div>
        </Paper>
      </Modal>
    </Paper>
  )
})

export default ImageUploader

import React from "react"
import firebase from "../../firebase"
import withStyles from "@material-ui/core/styles/withStyles"
import { Validation } from "react-validation-framework"

import { Paper, Button, TextField } from "@material-ui/core"

import { Route, Redirect } from "react-router-dom"
import { fieldValidatorCore } from "react-validation-framework"

fieldValidatorCore.addSupport(
  "TextField",
  args => {
    return args[0].target.value
  },
  (callback, args) => {
    callback(args[0])
  },
  "error",
  "helperText",
  "focused"
)

const formRouteStyles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  HW_visible: {
    top: "6px",
    left: "-5px"
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
})

const isPrivate = props => !props.public && !firebase.getCurrentUsername()
export default withStyles(formRouteStyles)(
  ({ component: Component, ...rest }) => {
    if (isPrivate(rest)) return <Redirect to="/dashboard/login" />

    return (
      <main className={rest.classes.main}>
        <Paper className={rest.classes.paper}>
          <Route
            {...rest}
            render={props => <Component {...rest} {...props} />}
          />
        </Paper>
      </main>
    )
  }
)
export const submitButton = buttonParams => (
  <Button
    type="submit"
    fullWidth
    variant="contained"
    onClick={buttonParams.onClick}
    style={{
      marginTop: "20px",
      backgroundColor: !buttonParams.color ? "rgb(103, 58, 183)" : "",
      color: buttonParams.color || "white"
    }} // todo use style classes here?
    disabled={buttonParams.disabled || false}
    color={buttonParams.color || "white"}
  >
    {buttonParams.text || "Create"}
  </Button>
)

export const fieldsToForm = fields =>
  fields.map(field => (
    <div style={{ width: "100%" }} key={field.id}>
      <Validation
        tagName="TextField"
        group="formGroup"
        validators={field.validators || []}
        componentTag="TextField"
      >
        <TextField
          label={field.title + (field.required ? " *" : "")}
          fullWidth
          key={field.id}
          disabled={field.disabled}
          margin="normal"
          id={field.id}
          name={field.id}
          autoComplete="off"
          placeholder={field.placeholder}
          variant="outlined"
          value={field.value}
          onChange={field.onChange}
          type={field.type || "text"}
          rowsMax={Infinity}
          rows={field.multiline ? 3 : null}
          multiline={field.multiline}
          InputLabelProps={
            field.type === "number"
              ? {
                  shrink: true
                }
              : null
          }
        />
      </Validation>
    </div>
  ))

import React, { useEffect } from "react"
import { Typography, Button, Card, CardContent } from "@material-ui/core"
import { Link, withRouter } from "react-router-dom"
import { db } from "../firebase"
import { useDocument, useCollection } from "react-firebase-hooks/firestore"
import { SpringGrid } from "react-stonecutter"
function Network(props) {
  const { classes } = props

  const networkUrlRef = db.doc(`networks/${props.match.params.name}`)

  const { value: networkData } = useDocument(networkUrlRef)

  const { value: shows } = useCollection(
    db
      .collection("shows")
      .where("networks", "array-contains", props.match.params.name)
  )

  useEffect(() => {
    if (!networkData || !shows) return

    props.setTitle(`Network ${networkData.data().name}`)
  }, [networkData, shows])

  return (
    <div>
      <main className={classes.main}>
        <Link to={"/dashboard/show/create"}>
          <Button> Create Show </Button>{" "}
        </Link>
        {shows && (
          <SpringGrid
            component="ul"
            columns={5}
            columnWidth={150}
            gutterWidth={5}
            gutterHeight={5}
            itemHeight={200}
            springConfig={{ stiffness: 170, damping: 26 }}
          >
            {shows.docs.map(doc => (
              <li key={doc.id}>
                <Link to={`/dashboard/show/${doc.id}`}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="h3">
                        {doc.data().name}
                      </Typography>
                      <Typography component="p">{doc.data().name}</Typography>
                    </CardContent>
                  </Card>
                </Link>
              </li>
            ))}
          </SpringGrid>
        )}
      </main>
    </div>
  )
}

export default withRouter(Network)
